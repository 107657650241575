import {
  AvatarInfor,
  PopupLockPostNormal,
  PopupSharePost,
  LayoutCreatorRight,
  ImgCustom,
  PopupConfirmFan,
  PopupLoading,
} from 'components';
import {
  ChatIcon,
  FlagIcon,
  FlowUnFlowIcon,
  HeartActiveIcon,
  HeartIcon,
  IconBookmark,
  ShareIcon,
  ThreePointIcon,
  TrashIcon,
  UnFlowIcon,
  QuotedIcon,
  CancelRetweetIcon,
  RePostIcon,
  CancelSussRetweetIcon,
  RePostIconV2,
  EyeV2Icon,
  HiddenV2Icon,
  DownloadIcon,
} from 'images';
import { getEnv } from 'configs/env';
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Blur_img from 'images/Blur_Right.png';

import {
  AVATAR_DEFAULT,
  STORAGE,
  convertToCurrentTime,
  decryptPath,
  isAdmin,
  isAgency,
  isFirefox,
  linkS3Video,
  renderTimeAfter24h,
  setOverflowTablet,
} from 'utils';
import { StyledTimeline } from './elements/styled';
import './index.scss';
import { newPathUser } from 'constants/layout/constant';
import ImgEmptyData from 'images/img-empty-data.png';
import {
  postBookMark,
  creatorDeleteArticle,
  fanFollowCreator,
  deleteCommentDetail,
  creatorGetPostDetail,
  postFollowCommentDetail,
  postFollowReplyCommentDetail,
  deleteReplyCommentDetail,
  resetDetailPost,
  resetCommentDetail,
  updateLikeCommentDetail,
  likePost,
  updatePostLikeDetail,
  postRetweet,
  postNormalPaymentBuyPackageSuccess,
  hiddenPostMypage,
  resetIDHomePage,
  resetDataInfoOther,
  getIDCMTTimeline,
  getIDCMTHomepage,
  getIDCMTBookmark,
} from 'store/actions/usersNew';
import Avatar from 'images/Avatar.png';
import PopupViewFullMess from './comment/popupCommemt';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import PopupViewFullTimeLine from './elements/popup/PopupViewFullTimeLine';
import EditPostIcon from 'images/EditPostIcon';
import PopupViewFullMedia from './elements/popup/PopupViewFullMedia';
import { useRef, useMemo } from 'react';
import { isMobile, isSafari } from 'react-device-detect';
import { useClickOutSize } from 'hooks/v2/clickOutSide';
import { hideCommentPost, logUserProfile } from 'store/actions/myPage';
import Storage from 'utils/storage';
import MarkDownRead from 'components/MarkDownRead';
import QuotePostMyPage from 'components/Popup/quotePostMyPage';
import { usePopupPaymentSub } from 'hooks/v2/usePopupPaymentSub';
import { getDetailPostMeta } from 'store/actions/postMeta';
import PopupConfirm from '../post-content-sale/PopupConfirm';
import CommentForm from './elements/commentForm';
import { CommentPostTimeLineShowAll } from 'components/comment/commentListShowAll';
import { ContextTimeline, ProviderTimeline } from 'pages/timeline/contextTimeline';
import { useContext } from 'react';
import { setDataItemRankingFromId } from 'store/actions/ranking';
import { setDataItemBookmarkFromId, updateListLikeFromPost } from 'store/actions/users';
import HideCommentIcon from 'images/HideCommentIcon';
import { clearIdAndDataHideComment } from 'store/actions/commentHide';
import { useFixedPopup } from 'hooks/v2/useFixedPopup';
import moment from 'moment';
import AvatarInforDetail from 'components/Account/AvatarInforDetail';
import axios from 'axios';

const CountComment = ({ idFromRanking, dispatch, id, idFavorite }) => {
  const { countNumberView } = useContext(ContextTimeline);
  useEffect(() => {
    if (idFromRanking) {
      dispatch(setDataItemRankingFromId(id, 'comment', countNumberView?.countNumberComment));
    }
    if (idFavorite) {
      dispatch(setDataItemBookmarkFromId(id, 'comment', countNumberView?.countNumberComment));
    }
  }, [countNumberView?.countNumberComment, idFromRanking, dispatch, id, idFavorite]);
  return <span className='number'>{countNumberView.countNumberComment}</span>;
};
const RenderPostItem = ({
  item,
  index,
  refOutSideThree,
  setIsVisiblePopup,
  isVisiblePopup,
  isAuth,
  setIsShowPopupConfirm,
  setSubTititeConfirmPost,
  detailPost,
  setIsVisiblePopDeletePost,
  checkDisabledAction,
  bookmarkFunc,
  setShowPopup,
  isShowPopup,
  setShowPopupMess,
  isShowPopupMess,
  setIsShowPopupDeletePost,
  toggleValuePost,
  handleFollowCreator,
  navigateByLink,
  openIndex,
  setOpenIndex,
  point,
  setIsOpen,
  setIdPackage,
  handleOnPackageLimit,
  setIsVisiblePopupGhim,
  isVisiblePopupGhim,
  id,
  handleRetweet,
  setItemPostPopup,
  setIsVisibleQuote,
  contentRef,
  handleLikePost,
  handleSharePost,
  bookmarkChange,
  handleByPackageSuccess,
  dataUser,
  idFavorite,
  setLoaddingDown,
  widthScreen,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [hoverId, setHoverId] = useState(false);
  const idFromRanking = useMemo(() => {
    return location?.state?.idFromRanking;
  }, [location?.state?.idFromRanking]);

  const idPostFromListLike = useMemo(() => {
    return location?.state?.idPostFromListLike;
  }, [location?.state?.idPostFromListLike]);

  const isCreator = useMemo(() => {
    return +item?.user_id === +dataUser?.id;
  }, [dataUser?.id, item?.user_id]);

  const conditionFollow = (item) => {
    return (
      item?.is_followed === 0 && item?.follow_flag === 1 && item?.is_my_post === 0 && !isAdmin
    );
  };

  const conditionMediaDisplay = (item) => {
    return (
      item?.follow_flag === 0 && item?.is_media_display === 0 && item?.type === 1 && !isAdmin
    );
  };

  const conditionMediaSharePost = (item) => {
    return (
      item?.share_post?.follow_flag === 0 &&
      item?.share_post?.is_media_display === 0 &&
      item?.share_post?.type === 1 &&
      !isAdmin
    );
  };

  const handleLikePostFromListLike = (value) => {
    const data =
      value === 1
        ? {
            account_id: detailPost?.account_id,
            account_name: detailPost?.account_name,
            avatar: detailPost?.avatar,
            content: detailPost?.content,
            like_created_at: moment().format('YYYY-MM-DD'),
            post_id: idPostFromListLike,
            title: detailPost?.title,
            type: detailPost?.type,
            user_id: detailPost?.user_id,
          }
        : null;
    dispatch(updateListLikeFromPost(idPostFromListLike, data));
  };

  const handleDownload = (item) => {
    let urlTmp = item?.medias?.filter((i) => i.type === 2)[0].url;
    setLoaddingDown(true);
    const url = `${linkS3Video}${decryptPath(urlTmp)}#t=0.001`;
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {},
    })
      .then((response) => {
        const timestamp = Date.now();
        const dateObj = new Date(timestamp);
        const year = dateObj.getFullYear().toString();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObj.getDate().toString().padStart(2, '0');
        const hours = dateObj.getHours().toString().padStart(2, '0');
        const minutes = dateObj.getMinutes().toString().padStart(2, '0');
        const seconds = dateObj.getSeconds().toString().padStart(2, '0');
        const result = year + month + day + hours + minutes + seconds;

        setLoaddingDown(false);
        const blobUrl = isSafari
          ? window.URL.createObjectURL(new Blob([response.data]))
          : window.URL.createObjectURL(response.data);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = `sharegram_video_${item?.id}_${result}.mp4`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((err) => {
        window.location.href = `/not-found`;
        throw Error(err);
      })
      .finally(() => {
        document.querySelector('#root').style.overflow = 'auto';
      });
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        {/* {detailPost?.is_active === 0 && !isAdmin && (
          <div className='wrapper-approve-post'>
            <WaitApprovePostIcon />
            <p className='text'>
              {t('Popup.AdminManagementPost.post_is_awaiting_approval_from_admin')}
            </p>
          </div>
        )} */}
        {detailPost?.post_status &&
          detailPost?.post_status !== 'public' &&
          !isAdmin &&
          !isAgency && (
            <div
              className='wrapper-approve-post'
              style={{ paddingLeft: '16px', paddingTop: '16px' }}>
              <label
                className={`text ${detailPost?.post_status}-status`}
                htmlFor={`comment-item-${detailPost?.id}`}>
                {t(`DetailCard.${detailPost?.post_status}`)}
              </label>
            </div>
          )}
        <div className='d-flex justify-content-between'>
          {/* <AvatarInforDetail
            images={item?.avatar}
            name={item?.account_name}
            notDate={true}
            content1={item}
            onClick={
              !isAdmin
                ? () => {
                    dispatch(resetIDHomePage());
                    dispatch(resetDataInfoOther());
                    navigate(`${newPathUser}${item?.account_id}`);
                  }
                : null
            }
          /> */}
          <div className={`avatar-infor `}>
            <div className='content-left'>
              <ImgCustom
                isPlaceholderAvatar
                screen='avatar_44_44'
                src={item?.avatar ? `${item?.avatar}` : AVATAR_DEFAULT}
                alt='avatar'
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(resetIDHomePage());
                  dispatch(resetDataInfoOther());
                  navigate(`${newPathUser}${item?.account_id}`, {
                    state: { name: 'homepage' },
                  });
                }}
              />
              <div style={{ display: 'flex' }}>
                <div
                  className='wrap--text'
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(resetIDHomePage());
                    dispatch(resetDataInfoOther());
                    navigate(`${newPathUser}${item?.account_id}`, {
                      state: { name: 'homepage' },
                    });
                  }}>
                  <span style={{ marginTop: '-5px' }} className='name'>
                    {item?.account_name}
                  </span>
                </div>
                {item?.user_id !== dataUser?.id && (
                  <div
                    style={{
                      border: '1px solid #DC143C',
                      borderRadius: '20px',
                      alignSelf: 'flex-start',
                      padding: '0 5px',
                      cursor: 'pointer',
                      fontSize: '12px',
                      fontWeight: '500',
                    }}
                    className={`btn-followOrUnFollow ${
                      item?.is_followed === 1 ? 'following' : 'no-follow'
                    } ${item?.is_followed === 1 && hoverId === item?.id ? 'hover' : ''}`}
                    onMouseOver={() => {
                      if (item?.is_followed === 1 && item?.id) {
                        !isMobile && setHoverId(item.id);
                      }
                    }}
                    onMouseLeave={() => {
                      if (item?.is_followed === 1) {
                        setHoverId(false);
                      }
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFollowCreator(item);
                    }}>
                    {widthScreen > 991
                      ? item?.is_followed === 1
                        ? hoverId === item?.id
                          ? t('Creator.FollowList.unfollow')
                          : t('Creator.FollowList.following')
                        : t('Creator.FollowList.followUs')
                      : item?.is_followed === 1
                      ? t('Creator.FollowList.following')
                      : t('Creator.FollowList.followUs')}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={`box-popup ${(isAgency || isAdmin) && 'disabled'}`}
            style={{ padding: '16px' }}>
            <span
              className='btn-three-point'
              ref={refOutSideThree}
              onClick={() => {
                !isAgency && setIsVisiblePopup(isVisiblePopup === item?.id ? -1 : item?.id);
              }}>
              {!isAdmin && (
                <ThreePointIcon
                  style={{ color: '#666565', opacity: isAgency || isAdmin ? '0.5' : '1' }}
                  className='three-point-icon'
                  onBlur={() => setIsVisiblePopup(-1)}
                />
              )}
            </span>
            {isVisiblePopup === item?.id && (
              <div className='popup-edit-comment'>
                {item?.is_active !== 0 &&
                  item?.user_id === dataUser?.id &&
                  item?.is_active !== 2 && (
                    <div
                      className='item'
                      onMouseDown={() => {
                        if (!isAuth) {
                          setIsVisiblePopup(-1);
                          dispatch(logUserProfile(item));
                        } else {
                          dispatch(hiddenPostMypage(item.id, () => {}));
                          setIsVisiblePopup(-1);
                        }
                      }}>
                      {item?.is_hided === 0 ? <EyeV2Icon /> : <HiddenV2Icon />}
                      <span className='text'>
                        {item?.is_hided === 0 ? t('Common.hiddenPost') : t('Common.openPost')}
                      </span>
                    </div>
                  )}
                {isCreator && item?.medias?.filter((i) => i.type === 2)?.length > 0 && (
                  <div
                    className='item'
                    onMouseDown={() => {
                      if (!isAuth) {
                        setIsVisiblePopup(-1);
                        dispatch(logUserProfile(item));
                      } else {
                        handleDownload(item);
                        setIsVisiblePopup(-1);
                      }
                    }}>
                    <DownloadIcon style={{ marginRight: '3px' }} />
                    <span className='text'>{t('Affiliator.AF005.download')}</span>
                  </div>
                )}
                {item?.is_my_post === 1 && (
                  <div
                    className='item'
                    onMouseDown={() => {
                      if (!isAuth) {
                        setIsVisiblePopup(-1);
                        dispatch(logUserProfile());
                      } else {
                        navigateByLink(item?.id, item?.type);
                        setIsVisiblePopup(-1);
                      }
                    }}>
                    <EditPostIcon />
                    <span className='text'>{t('comment.listComment.iconEdit')}</span>
                  </div>
                )}
                {item?.is_my_post === 1 && (
                  <div
                    className='item'
                    onMouseDown={() => {
                      if (!isAuth) {
                        setIsVisiblePopup(-1);
                        dispatch(logUserProfile());
                      } else {
                        setIsVisiblePopDeletePost({ id: item.id });
                        setIsVisiblePopup(-1);
                      }
                    }}>
                    <TrashIcon />
                    <span className='text'>{t('Common.buttonDelete')}</span>
                  </div>
                )}
                <div
                  className='item'
                  onMouseDown={() => {
                    if (!isAuth) {
                      setIsVisiblePopup(-1);
                      dispatch(logUserProfile(item));
                    } else {
                      setIsVisiblePopup(-1);
                      navigate(`${newPathUser}post-report`, {
                        state: {
                          fan_id: item?.user_id,
                          fan_name: item?.account_name || item?.full_name,
                          post_id: item?.id,
                        },
                      });
                    }
                  }}>
                  <FlagIcon />
                  <span className='text'>{t('comment.listComment.iconFlag')}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {item?.medias?.length > 0 ? (
          <>
            {item?.type === 1 && (
              <>
                {item?.medias?.[0].type === 1 && (
                  <>
                    {(() => {
                      const data = item?.medias
                        ?.filter((item) => item?.type === 1)
                        .filter((item) => item.public_flag === 1);
                      return (
                        data.length === 0 &&
                        bookmarkFunc(item, !item?.is_media_display && item?.type === 1)
                      );
                    })()}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          bookmarkFunc(item, !item?.is_media_display && item?.type === 1)
        )}
      </div>
      {item?.medias?.length > 0 && (
        <div
          onClick={() => {
            if (item?.medias?.length > 0) {
              checkDisabledAction(item) &&
                setShowPopup({
                  ...isShowPopup,
                  status: true,
                  dataItem: item,
                  idx: 0,
                });
            } else {
              checkDisabledAction(item) &&
                setShowPopupMess({
                  ...isShowPopupMess,
                  status: true,
                  dataItemMess: item,
                });
            }
          }}
          style={{
            position: 'relative',
            // cursor: 'pointer',
            padding: '0',
            //minHeight: !item?.is_media_display && item?.type === 1 ? 360 : 'unset',
            margin: `${conditionFollow(item) && !isMobile ? '0 16px' : '0'}`,
          }}
          className='mobile-timeline'>
          {conditionFollow(item) ? (
            <div style={item?.medias?.length === 0 ? { paddingBottom: '35px' } : {}}>
              <StyledTimeline.LockScreen
                style={{
                  backgroundImage: `${isFirefox ? `url(${Blur_img})` : 'transparent'}`,
                }}>
                <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
                <button
                  disabled={isAgency}
                  onClick={(e) => {
                    if (!isAuth) {
                      dispatch(logUserProfile());
                    } else {
                      e.stopPropagation();
                      toggleValuePost(index, 'is_followed', item?.is_followed === 1 ? 0 : 1);
                      handleFollowCreator(item);
                    }
                  }}>
                  {t('Common.follow')}
                </button>
              </StyledTimeline.LockScreen>
            </div>
          ) : (
            ''
          )}
          {conditionMediaDisplay(item) && (
            <PopupLockPostNormal
              post_id={item.id}
              detailPost={item}
              in_my_page
              point={point}
              setIsOpen={setIsOpen}
              setIdPackage={setIdPackage}
              handleOnPackageLimit={handleOnPackageLimit}
            />
          )}

          {(!!openIndex || openIndex === 0) && (
            <PopupViewFullMedia
              className='popup-post-detail-show-media'
              item={item}
              idx={openIndex}
              close={() => {
                setOpenIndex(null);
              }}
            />
          )}
          <>
            <PopupViewFullTimeLine
              className='list-media-item popup-post-detail post-parent'
              item={item}
              idx={0}
              openIndex={openIndex}
              setOpenIndex={setOpenIndex}
              close={() => {
                setShowPopup({
                  ...isShowPopup,
                  status: false,
                  dataItem: {},
                });
                dispatch(creatorGetPostDetail(id, false));
              }}
            />
          </>
        </div>
      )}
      {item?.content && (
        <div className={`content 2`} style={{ margin: '0 16px' }}>
          <div className='text'>
            <MarkDownRead isMeta charLimit={150} content={item?.content} />
          </div>
        </div>
      )}
      <div className='d-flex content1 al-center mt-2' style={{ marginLeft: '16px' }}>
        <p
          className='cls-title-2nd cls-date me-1'
          style={{
            color: '#666565',
            marginTop: '0px',
            marginBottom: '0px',
            fontSize: '12px',
          }}>
          {convertToCurrentTime(item?.public_time || item?.created_at, 'YYYY/MM/DD HH:mm')}
        </p>
        {item?.unpublic_time && (
          <>
            <p
              className='cls-title-2nd cls-date ms-1 mb-left'
              style={{
                color: '#DC143C',
                marginTop: '0px',
                border: '1px solid',
                padding: '0px 5px',
                marginBottom: '0',
                marginRight: '3px',
                width: 'fit-content',
                fontSize: '12px',
              }}>
              <span className='me-1'>{t('Common.publicDay')}</span>
              {convertToCurrentTime(item?.unpublic_time, 'YYYY/MM/DD HH:mm')}
            </p>
          </>
        )}
        {item?.in_24h_flag === 1 && (
          <>
            <p
              className='cls-title-2nd cls-date ms-1 mb-left'
              style={{
                color: '#DC143C',
                marginTop: '0px',
                border: '1px solid',
                padding: '0px 5px',
                marginBottom: '0',
                marginRight: '3px',
                width: 'fit-content',
                fontSize: '12px',
              }}>
              <span className='me-1'>{t('Common.publicDay')}</span>
              {convertToCurrentTime(renderTimeAfter24h(item?.public_time), 'YYYY/MM/DD HH:mm')}
            </p>
          </>
        )}
      </div>
      {item?.share_type === 2 && item?.share_post && item?.medias?.length === 0 && (
        <>
          <div
            style={{ cursor: 'pointer', margin: '16px 16px 0 16px' }}
            className={`block-repost 43`}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`${newPathUser}post/${item?.share_post?.id}`);
            }}>
            <div
              className='d-flex'
              style={{
                gap: '12px',
                marginBottom: `${item?.share_post?.medias?.length > 0 ? '16px' : '0px'}`,
              }}>
              {item?.share_post?.avatar ? (
                <ImgCustom
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`${newPathUser}${item?.share_post?.account_id}`);
                  }}
                  style={{
                    minWidth: 44,
                    maxWidth: 44,
                    minHeight: 44,
                    maxHeight: 44,
                    borderRadius: '50%',
                    objectFit: 'cover',
                    cursor: 'pointer',
                  }}
                  className='cls-img'
                  screen='avatar_44_44'
                  src={item?.share_post?.avatar}
                />
              ) : (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`${newPathUser}${item?.share_post?.account_id}`);
                  }}
                  width={44}
                  height={44}
                  src={AVATAR_DEFAULT}
                  alt='AVATAR_DEFAULT'
                />
              )}
              <div
                className='wrap--text'
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`${newPathUser}${item?.share_post?.account_id}`);
                }}>
                <p className='mb-0 cls-title fw-bold'>
                  {item?.share_post?.account_name || 'no account name'}
                </p>
              </div>
            </div>
            {!(
              item?.medias?.length === 0 &&
              item?.share_type === 2 &&
              item?.share_post?.medias?.length === 0
            ) && (
              <>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`${newPathUser}post/${item?.share_post?.id}`);
                  }}
                  style={{
                    position: 'relative',
                    margin: `${
                      !isMobile &&
                      item?.share_post?.is_followed === 0 &&
                      item?.share_post?.follow_flag === 1 &&
                      item?.share_post?.is_my_post === 0
                        ? '0 -16px'
                        : isMobile &&
                          item?.share_post?.is_followed === 1 &&
                          item?.share_post?.follow_flag === 0 &&
                          item?.share_post?.is_my_post === 0
                        ? '0 -16px'
                        : '0 -32px'
                    }`,
                  }}>
                  {item?.share_post?.is_followed === 0 &&
                    item?.share_post?.follow_flag === 1 &&
                    item?.share_post?.is_my_post === 0 &&
                    !isAdmin && (
                      <div>
                        <StyledTimeline.LockScreen>
                          <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
                          <button
                            onClick={(e) => {
                              if (!isAuth) {
                                dispatch(logUserProfile());
                              } else {
                                e.stopPropagation();
                                toggleValuePost(
                                  index,
                                  'is_followed',
                                  item?.share_post?.is_followed === 1 ? 0 : 1
                                );
                                dispatch(
                                  fanFollowCreator(item?.share_post?.user_id, () => {
                                    dispatch(creatorGetPostDetail(id, 1, false));
                                  })
                                );
                              }
                            }}>
                            {t('Common.follow')}
                          </button>
                        </StyledTimeline.LockScreen>
                      </div>
                    )}
                  {conditionMediaSharePost(item) && (
                    <PopupLockPostNormal
                      idDetail={item?.id}
                      post_id={item.share_post?.id}
                      detailPost={item?.share_post}
                      in_my_page
                      point={point}
                      setIsOpen={setIsOpen}
                      setIdPackage={setIdPackage}
                      handleOnPackageLimit={handleOnPackageLimit}
                      handleByPackageSuccess={handleByPackageSuccess}
                    />
                  )}
                  {(!!openIndex || openIndex === 0) && (
                    <PopupViewFullMedia
                      className='popup-post-detail-show-media'
                      item={item?.share_post}
                      idx={openIndex}
                      close={() => {
                        setOpenIndex(null);
                      }}
                    />
                  )}
                  <PopupViewFullTimeLine
                    isQuote={true}
                    className='list-media-item popup-post-detail'
                    item={item?.share_post}
                    idx={0}
                    openIndex={openIndex}
                    setOpenIndex={setOpenIndex}
                    close={() => {
                      setShowPopup({
                        ...isShowPopup,
                        status: false,
                        dataItem: {},
                      });
                      dispatch(creatorGetPostDetail(id, false));
                    }}
                  />
                </div>
              </>
            )}
            <div
              className='mt-3 block-img1'
              style={{
                whiteSpace: 'pre-wrap',
                margin: '0',
                fontWeight: '400',
              }}>
              <MarkDownRead
                content={item?.share_post?.content || ''}
                charLimit={isMobile ? 90 : 160}
              />
            </div>
            <div className='d-flex align-items-center mt-2' style={{ fontSize: '12px' }}>
              <p
                className='cls-title-2nd cls-date'
                style={{ color: '#666565', marginTop: '0px', marginBottom: '0' }}>
                {convertToCurrentTime(
                  item?.share_post?.public_time || item?.share_post?.created_at,
                  'YYYY/MM/DD HH:mm'
                )}
              </p>
              &nbsp;
              {item?.share_post?.unpublic_time && (
                <>
                  <p
                    className='cls-title-2nd cls-date ms-1'
                    style={{
                      color: '#DC143C',
                      marginTop: '0px',
                      border: '1px solid',
                      padding: '0px 5px',
                      fontSize: '12px',
                    }}>
                    <span className='me-1'>{t('Common.publicDay')}</span>
                    {convertToCurrentTime(item?.share_post?.unpublic_time, 'YYYY/MM/DD HH:mm')}
                  </p>
                </>
              )}
              {item?.share_post?.in_24h_flag === 1 && (
                <>
                  <p
                    className='cls-title-2nd cls-date ms-1'
                    style={{
                      color: '#DC143C',
                      marginTop: '0px',
                      border: '1px solid',
                      padding: '0px 5px',
                      fontSize: '12px',
                    }}>
                    <span className='me-1'>{t('Common.publicDay')}</span>
                    {convertToCurrentTime(
                      renderTimeAfter24h(item?.share_post?.public_time),
                      'YYYY/MM/DD HH:mm'
                    )}
                  </p>
                </>
              )}
            </div>
            {item?.share_post?.share_post && ( // item?.share_post FANSITE-6276
              <div
                className='mt-3 block-img1 3'
                style={{
                  whiteSpace: 'pre-wrap',
                  margin: '0',
                  fontWeight: '500',
                }}>
                {`${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${item?.share_post?.id}`}
              </div>
            )}
          </div>
        </>
      )}
      {(item?.share_type === 1 || item?.share_type === 2) && !item?.share_post && (
        <div
          className={`block-repost-no-img 4`}
          style={{ margin: '16px', borderRadius: '8px' }}>
          {t('Common.unavailable')}
        </div>
      )}
      {item?.share_type === 2 && item?.share_post && item?.medias?.length > 0 && (
        <div
          style={{ cursor: 'pointer', margin: '0px 16px 16px 16px' }}
          className={`block-repost-no-img 2 mt-2`}
          onClick={() => navigate(`${newPathUser}post/${item?.share_post?.id}`)}>
          <div className='d-flex' style={{ gap: '12px' }}>
            {item?.share_post?.avatar ? (
              <ImgCustom
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`${newPathUser}${item?.share_post?.account_id}`);
                }}
                style={{
                  minWidth: 44,
                  maxWidth: 44,
                  minHeight: 44,
                  maxHeight: 44,
                  borderRadius: '50%',
                  objectFit: 'cover',
                  cursor: 'pointer',
                }}
                className='cls-img'
                screen='avatar_44_44'
                src={item?.share_post?.avatar}
              />
            ) : (
              <img
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`${newPathUser}${item?.share_post?.account_id}`);
                }}
                width={44}
                height={44}
                src={AVATAR_DEFAULT}
                alt='AVATAR_DEFAULT'
              />
            )}
            <div
              className='wrap--text'
              onClick={(e) => {
                e.stopPropagation();
                navigate(`${newPathUser}${item?.share_post?.account_id}`);
              }}>
              <p className='mb-0 cls-title fw-bold'>
                {item?.share_post?.account_name || 'no account name'}
              </p>
            </div>
          </div>
          <div
            className='mt-3 block-img1'
            style={{
              whiteSpace: 'pre-wrap',
              margin: '0',
              fontWeight: '400',
            }}>
            <MarkDownRead
              content={item?.share_post?.content || ''}
              charLimit={isMobile ? 90 : 160}
            />
          </div>
          <div className='d-flex align-items-center mt-2' style={{ fontSize: '12px' }}>
            <p
              className='cls-title-2nd cls-date'
              style={{ color: '#666565', marginTop: '0px', marginBottom: '0' }}>
              {convertToCurrentTime(
                item?.share_post?.public_time || item?.share_post?.created_at,
                'YYYY/MM/DD HH:mm'
              )}
            </p>
            &nbsp;
            {item?.share_post?.unpublic_time && (
              <>
                <p
                  className='cls-title-2nd cls-date ms-1'
                  style={{
                    color: '#DC143C',
                    marginTop: '0px',
                    border: '1px solid',
                    padding: '0px 5px',
                    fontSize: '12px',
                  }}>
                  <span className='me-1'>{t('Common.publicDay')}</span>
                  {convertToCurrentTime(item?.share_post?.unpublic_time, 'YYYY/MM/DD HH:mm')}
                </p>
              </>
            )}
            {item?.share_post?.in_24h_flag === 1 && (
              <>
                <p
                  className='cls-title-2nd cls-date ms-1'
                  style={{
                    color: '#DC143C',
                    marginTop: '0px',
                    border: '1px solid',
                    padding: '0px 5px',
                    fontSize: '12px',
                  }}>
                  <span className='me-1'>{t('Common.publicDay')}</span>
                  {convertToCurrentTime(
                    renderTimeAfter24h(item?.share_post?.public_time),
                    'YYYY/MM/DD HH:mm'
                  )}
                </p>
              </>
            )}
          </div>
          {item?.share_post && (
            <div
              className='mt-3 block-img1 4'
              style={{
                whiteSpace: 'pre-wrap',
                margin: '0',
                fontWeight: '500',
              }}>
              {`${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${item?.share_post?.id}`}
            </div>
          )}
        </div>
      )}
      <div
        className={`interactive ${!item?.is_media_display && item?.type === 1 && 'disabled'} ${
          (isAdmin || isAgency) && 'disabled'
        }`}
        ref={contentRef}
        style={{ background: '#FAFAFA', opacity: (isAdmin || isAgency) && 0.4 }}>
        <div className='left'>
          <div
            className={`item ${
              (checkDisabledAction(item) ||
                detailPost?.is_active === 0 ||
                detailPost?.is_active === 2) &&
              'disabled'
            }`}>
            {item?.is_liked === 1 ? (
              <>
                {/* đã like */}
                <HeartActiveIcon
                  className={`like-icon active`}
                  onClick={() => {
                    if (!isAuth) {
                      dispatch(logUserProfile(item));
                    } else {
                      if (idFromRanking) {
                        dispatch(
                          setDataItemRankingFromId(
                            item?.id,
                            'like',
                            item?.is_liked === 1 ? 0 : 1
                          )
                        );
                      }
                      if (idFavorite) {
                        dispatch(
                          setDataItemBookmarkFromId(
                            item?.id,
                            'like',
                            item?.is_liked === 1 ? 0 : 1
                          )
                        );
                      }
                      if (idPostFromListLike) {
                        handleLikePostFromListLike(item?.is_liked === 1 ? 0 : 1);
                      }
                      !checkDisabledAction(item) &&
                        toggleValuePost(index, 'is_liked', item?.is_liked === 1 ? 0 : 1);
                      !checkDisabledAction(item) && handleLikePost(item);
                    }
                  }}
                  disabled={checkDisabledAction(item)}
                />
              </>
            ) : (
              <>
                {/* chưa like */}
                <HeartIcon
                  className={`like-icon`}
                  onClick={() => {
                    if (!isAuth) {
                      dispatch(logUserProfile(item));
                    } else {
                      if (idFromRanking) {
                        dispatch(
                          setDataItemRankingFromId(
                            item?.id,
                            'like',
                            item?.is_liked === 1 ? 0 : 1
                          )
                        );
                      }
                      if (idFavorite) {
                        dispatch(
                          setDataItemBookmarkFromId(
                            item?.id,
                            'like',
                            item?.is_liked === 1 ? 0 : 1
                          )
                        );
                      }
                      if (idPostFromListLike) {
                        handleLikePostFromListLike(item?.is_liked === 1 ? 0 : 1);
                      }
                      !checkDisabledAction(item) &&
                        toggleValuePost(index, 'is_liked', item?.is_liked === 1 ? 0 : 1);
                      !checkDisabledAction(item) && handleLikePost(item);
                    }
                  }}
                  disabled={
                    checkDisabledAction(item) ||
                    detailPost?.is_active === 0 ||
                    detailPost?.is_active === 2
                  }
                />
              </>
            )}
            <span className='number'>{item?.number_like}</span>
          </div>
          <div
            className={`item ${
              (checkDisabledAction(item) ||
                item?.enable_comment !== 1 ||
                detailPost?.is_active === 0 ||
                detailPost?.is_active === 2) &&
              'disabled'
            }`}>
            <ChatIcon className='chat-icon' disabled={checkDisabledAction(item)} />
            <CountComment
              id={item?.id}
              idFromRanking={idFromRanking}
              dispatch={dispatch}
              idFavorite={idFavorite}
            />
          </div>
          <div
            className={`item d-middle div-ghim1`}
            style={{
              pointerEvents: `${item?.pin_share_enable === 0 && 'none'}`,
              color: `${item?.pin_share_enable === 0 && '#BEBEBE'}`,
            }}>
            {checkDisabledAction(item) ||
            detailPost?.is_active === 0 ||
            detailPost?.is_active === 2 ? (
              <RePostIconV2 />
            ) : (
              <>
                {item?.is_share === 1 ? (
                  <CancelSussRetweetIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (!isAuth) {
                        dispatch(logUserProfile(item));
                      } else {
                        setIsVisiblePopupGhim(isVisiblePopupGhim === item?.id ? -1 : item?.id);
                      }
                    }}
                    onBlur={() => setIsVisiblePopupGhim(-1)}
                  />
                ) : (
                  <RePostIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (!isAuth) {
                        dispatch(logUserProfile(item));
                      } else {
                        setIsVisiblePopupGhim(isVisiblePopupGhim === item?.id ? -1 : item?.id);
                      }
                    }}
                    onBlur={() => setIsVisiblePopupGhim(-1)}
                  />
                )}
                {isVisiblePopupGhim === item?.id && (
                  <div className='popup-ghim1'>
                    <div
                      className='item1'
                      onMouseDown={() => {
                        handleRetweet(item?.id, {
                          share_type: 1,
                        });
                        setIsVisiblePopupGhim(-1);
                      }}>
                      <CancelRetweetIcon />
                      <span className='text'>
                        {item?.is_share === 0 ? t('Common.repost') : t('Common.Unrepost')}
                      </span>
                    </div>
                    <div
                      className='item1'
                      onMouseDown={() => {
                        setItemPostPopup(item);
                        setIsVisibleQuote(true);
                        setIsVisiblePopupGhim(-1);
                      }}>
                      <QuotedIcon />
                      <span className='text'>{t('Common.QuotePost')}</span>
                    </div>
                  </div>
                )}
              </>
            )}
            <span className={`number ms-1 ${checkDisabledAction(item) && 'disabled'}`}>
              {item?.number_share ?? 0}
            </span>
          </div>
          <div
            className={`item ${
              (checkDisabledAction(item) ||
                detailPost?.is_active === 0 ||
                detailPost?.is_active === 2) &&
              'disabled'
            }`}>
            <ShareIcon
              className={`share-icon`}
              onClick={() => {
                if (!isAuth) {
                  dispatch(logUserProfile(item));
                } else {
                  handleSharePost({
                    link: `${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${item?.id}`,
                    title: item.title,
                  });
                }
              }}
              disabled={checkDisabledAction(item)}
            />
          </div>
        </div>
        <div
          className={`item ${
            (checkDisabledAction(item) ||
              detailPost?.is_active === 0 ||
              detailPost?.is_active === 2) &&
            'disabled'
          }`}>
          {isCreator && (
            <HideCommentIcon
              className='icon-hide-comment-post-normal'
              onClick={() => {
                dispatch(clearIdAndDataHideComment());
                navigate(`/comment-hide/${item?.id}`);
              }}
            />
          )}
          <IconBookmark
            style={{
              color: item?.is_bookmarked === 1 ? '#dc143c' : 'inherit',
            }}
            fill={item?.is_bookmarked === 1 ? '#dc143c' : 'none'}
            onClick={() => {
              if (!isAuth) {
                dispatch(logUserProfile(item));
              } else {
                // !checkDisabledAction(item) &&
                // toggleValuePost(index, 'is_bookmarked', item?.is_bookmarked === 1 ? 0 : 1);
                !checkDisabledAction(item) && bookmarkChange(item);
              }
            }}
            className='cls-icon'
          />
        </div>
      </div>
    </>
  );
};
const PostContentDetail = ({ setLoaddingDown, setListTimeLine, listTimeLine }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const contentRef = useRef(null);
  const [tab, setTab] = useState(1);
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const widthScreen = window.innerWidth;
  const {
    listPostTimeLine,
    detailPost,
    loading,
    isLoadingFollowCreate,
    isCheckResetDetailPost,
  } = useSelector((state) => state.usersNew);
  const [isShowPopup, setShowPopup] = useState({
    status: false,
    dataItem: {},
  });
  const [isShowPopupMess, setShowPopupMess] = useState({
    status: false,
    dataItemMess: {},
  });
  const [valueComments, setValueComments] = useState(() =>
    Array.from({ length: 0 }, () => '')
  );

  const { postDetail: postDetailMeta } = useSelector((state) => state.postMeta);
  const [checkSendChat, setCheckSendChat] = useState(null);
  const { data: userProfile, dataUser, idFavorite } = useSelector((state) => state.users);
  const {
    renderLikeCMTTimeline,
    idTimeline,
    idHomePage,
    idFavoriteTimline,
    renderLikeCMTHomepage,
    renderLikeCMTBookmark,
  } = useSelector((state) => state.usersNew);
  const [isVisiblePopup, setIsVisiblePopup] = useState(-1);
  const [isVisiblePopUpDeletePost, setIsVisiblePopDeletePost] = useState(null);
  const [isVisiblePopUpDeletePostComplete, setIsVisiblePopDeletePostComplete] =
    useState(false);
  const [popupDeleteComment, setPopupDeleteComment] = useState(null);
  const [popupHideComment, setPopupHideComment] = useState(null);

  const currentPoints = dataUser?.points || 0;
  const [point, setPoint] = useState(currentPoints);

  const [valueComment, setValueComment] = useState('');
  const [isVisiblePopupGhim, setIsVisiblePopupGhim] = useState(-1);
  const [isVisibleQuote, setIsVisibleQuote] = useState(false);
  const [itemPostPopup, setItemPostPopup] = useState();
  const refArea = useRef(null);

  const [isShowPopupConfirm, setIsShowPopupConfirm] = useState(false);
  const [isShowPopupDeletePost, setIsShowPopupDeletePost] = useState(false);
  const [subTititeConfirmPost, setSubTititeConfirmPost] = useState(false);
  const [isShowPopupDeactiveSuccess, setIsShowPopupDeactiveSuccess] = useState(false);
  const [isShowPopupDeleteSuccess, setIsShowPopupDeleteSuccess] = useState(false);

  const [openIndex, setOpenIndex] = useState(null);
  const [idPostMeta, setIdPostMeta] = useState(null);
  const [trickReloadComment, setTrickReloadComment] = useState(null);
  const [renderFolowCMT, setRenderFolowCMT] = useState({ id: null, flg: false });
  const { id } = useParams();
  useFixedPopup(isVisibleQuote);

  const handleOnPackageLimit = () => {
    dispatch(
      creatorGetPostDetail(id, false, (data) => {
        setIdPackage(data?.packages[0] && data?.packages[0]?.id);
      })
    );
  };
  const { idPackage, setIsOpen, setIdPackage, PopupPaymentBySub } = usePopupPaymentSub({
    urlRedirect: '',
    onClickSuccess: () => {
      dispatch(postNormalPaymentBuyPackageSuccess(idPackage));
    },
    handleOnPackageLimit,
  });

  // const isHidePost = useMemo(() => {
  //   return !isAdmin && detailPost?.is_hided === 1 && detailPost?.is_my_post === 0;
  // }, [detailPost]);

  // const isHidePostByAdmin = useMemo(() => {
  //   return !isAdmin && detailPost?.is_active === 0 && detailPost?.is_my_post === 0;
  // }, [detailPost]);

  // useEffect(() => {
  //   if (isHidePost) {
  //     navigate('/not-found');
  //   }
  //   if (isHidePostByAdmin) {
  //     navigate('/not-found');
  //   }
  // }, [isHidePost, navigate]);

  useEffect(() => {
    if (detailPost?.type && !loading && !isCheckResetDetailPost) {
      if (detailPost?.type && ![1, 4].includes(Number(detailPost?.type))) {
        navigate('/not-found');
      }
    }
  }, [detailPost?.type, isCheckResetDetailPost, loading, navigate]);

  useEffect(() => {
    return () => {
      dispatch(resetDetailPost());
      dispatch(resetCommentDetail());
    };
  }, []);

  useEffect(() => {
    if (detailPost) {
      if (detailPost?.type === 4) {
        const videoMedia = detailPost?.medias?.find((item) => item?.type === 2);

        if (videoMedia) {
          const tempDetail = { ...detailPost };
          tempDetail.medias = tempDetail?.medias?.filter((item) => item.type === 2);
          setListTimeLine([tempDetail]);
        } else {
          const tempDetail = { ...detailPost };
          tempDetail.medias = tempDetail?.medias?.filter((item) => item.type !== 4);
          setListTimeLine([tempDetail]);
        }
      } else {
        setListTimeLine([detailPost]);
      }
    } else {
      setListTimeLine && setListTimeLine([]);
    }
  }, [detailPost]);

  useEffect(() => {
    if (!!openIndex || openIndex === 0) {
      document.getElementById('root').style.overflow = 'hidden';
      document.getElementById('root').style.position = 'fixed';
      document.getElementById('root').style.width = '100%';
    } else {
      // document.getElementById('root').style.overflow = 'auto';
      setOverflowTablet();
      document.getElementById('root').style.position = 'revert';
    }
  }, [openIndex]);

  const { search } = useLocation();

  const token = search?.split('&');
  const tokenAgency = localStorage.getItem(STORAGE.AGENCY_TOKEN)?.replace(/"/g, '') || false;

  const adminToken = isAgency
    ? tokenAgency
    : token[1]?.split('=')[1] ?? token[0]?.split('=')[1];

  useEffect(() => {
    const top = document.getElementById('app_cocofans_0');
    top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
  }, []);

  // useEffect(() => {
  //   setListTimeLine([]);
  //   const agencyToken = Storage.get(STORAGE.AGENCY_TOKEN);
  //   const adminTokenAccess = Storage.get(STORAGE.USER_ACCESS_TOKEN);
  //   if (!search?.includes('adminToken') && !search?.includes('agencyToken')) {
  //     dispatch(
  //       creatorGetPostDetail(id, 1, false, isAgency ? agencyToken : adminTokenAccess, () => {
  //         const top = document.getElementById('app_cocofans_0');
  //         top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
  //       })
  //     );
  //   }
  // }, [id]);

  useEffect(() => {
    if (listPostTimeLine.length > 0) {
      setValueComments(() => Array.from({ length: listPostTimeLine?.length }, () => ''));
    }
  }, [listPostTimeLine]);

  const handleFollowCreator = (dt) => {
    dispatch(
      fanFollowCreator(dt.user_id, () => {
        setRenderFolowCMT({ id: dt.user_id, flg: !renderFolowCMT?.flg });
      })
    );
  };

  const bookmarkFunc = (post, hidden = false) => <></>;

  const bookmarkChange = (post) => {
    dispatch(postBookMark(post));
  };

  const handleSharePost = (data) => {
    setPopupCopyLink(data);
  };

  const handleShowPopup = (e) => {
    setPopupCopyLink(e);
  };

  const checkDisabledAction = (item) => {
    if (item?.is_active === 0) return true;
    if (item?.post_status === 'reject' || item?.post_status === 'reviewing') return true;
    if (
      (item?.is_followed === 0 && item?.follow_flag === 1 && item?.is_my_post === 0) ||
      (!item?.is_media_display && item?.type === 1 && item?.is_my_post === 0)
    ) {
      return true;
    }
    return false;
  };

  const handleLikePost = (post) => {
    dispatch(updatePostLikeDetail(post, true));
  };

  const toggleValuePost = useCallback(
    (index, name = '', value) => {
      const listClone = JSON.parse(JSON.stringify(listTimeLine)) || [];
      if (listClone?.length > 0) {
        listClone[index][name] = value || !listClone[index][name];
        if (name === 'is_liked') {
          if (value === 1) {
            listClone[index]['number_like'] = listClone[index]['number_like'] + 1;
          } else {
            listClone[index]['number_like'] =
              listClone[index]['number_like'] > 0 ? listClone[index]['number_like'] - 1 : 0;
          }
          dispatch(likePost(id));
        }
      }
      setListTimeLine(listClone);
    },
    [listTimeLine]
  );

  const deletePostTimeLine = () => {
    setIsVisiblePopDeletePost(null);
    const { id } = isVisiblePopUpDeletePost;
    dispatch(
      creatorDeleteArticle(id, () => {
        setIsVisiblePopDeletePostComplete(true);
        dispatch(resetIDHomePage());
        dispatch(resetDataInfoOther());
        navigate(`${newPathUser}`);
      })
    );
  };

  const navigateByLink = (postId, type) => {
    navigate(
      `${newPathUser}post${
        Boolean(type === 2) ? '' : Boolean(type === 3 || type === 4) ? '' : ''
      }/update/${postId}`,
      {
        state: {
          isEditHomePage: location.state?.isEditHomePage,
          account_id: location?.state?.account_id,
        },
      }
    );
    // navigate(`${newPathUser}post/update/${postId}`, {
    //   state: {
    //     isEditHomePage: location.state?.isEditHomePage,
    //     account_id: location?.state?.account_id,
    //   },
    // });
  };

  const handleLikeComment = (data, cb = () => {}) => {
    const { commentId, type, dataPost } = data;
    cb();

    dispatch(
      updateLikeCommentDetail(commentId, dataPost, () => {
        if (idTimeline) {
          dispatch(getIDCMTTimeline({ id: commentId, flg: !renderLikeCMTTimeline?.flg }));
        }
        if (idHomePage) {
          dispatch(getIDCMTHomepage({ id: commentId, flg: !renderLikeCMTHomepage?.flg }));
        }
        if (idFavoriteTimline) {
          dispatch(getIDCMTBookmark({ id: commentId, flg: !renderLikeCMTBookmark?.flg }));
        }
      })
    );
    // type === 'comment'
    //   ? dispatch(updateLikeCommentDetail(commentId, dataPost))
    //   : dispatch(updateLikeReplyComment(commentId, dataPost));
  };

  const handleByPackageSuccess = (id) => {
    dispatch(creatorGetPostDetail(id, 1, false));
  };

  // DELETE COMMENT
  const handleDeleteComment = () => {
    const { id, type, idParent, cb = () => {}, postId } = popupDeleteComment;
    type === 'comment'
      ? dispatch(
          deleteCommentDetail(id, postId || null, cb, () => {
            if (idTimeline) {
              dispatch(
                getIDCMTTimeline({ id: id, flg: !renderLikeCMTTimeline?.flg, flgDel: true })
              );
            }
            if (idHomePage) {
              dispatch(
                getIDCMTHomepage({ id: id, flg: !renderLikeCMTHomepage?.flg, flgDel: true })
              );
            }
            if (idFavoriteTimline) {
              dispatch(
                getIDCMTBookmark({ id: id, flg: !renderLikeCMTBookmark?.flg, flgDel: true })
              );
            }
          })
        )
      : dispatch(
          deleteReplyCommentDetail(id, postId || null, cb, () => {
            if (idTimeline) {
              dispatch(
                getIDCMTTimeline({ id: id, flg: !renderLikeCMTTimeline?.flg, flgDel: true })
              );
            }
            if (idHomePage) {
              dispatch(
                getIDCMTHomepage({ id: id, flg: !renderLikeCMTHomepage?.flg, flgDel: true })
              );
            }
            if (idFavoriteTimline) {
              dispatch(
                getIDCMTBookmark({ id: id, flg: !renderLikeCMTBookmark?.flg, flgDel: true })
              );
            }
          })
        );
    setPopupDeleteComment(false);
  };
  // HIDE COMMENT
  const handleHideComment = () => {
    const { id, cb = () => {} } = popupHideComment;
    dispatch(hideCommentPost(id, cb));
    setPopupHideComment(false);
  };

  // FOLLOW USER IN COMMENT
  const handleFollowUserComment = (data, onRequest = () => {}, onFinally = () => {}) => {
    const { id, type } = data;
    type === 'comment'
      ? dispatch(postFollowCommentDetail(id, onRequest, onFinally))
      : dispatch(postFollowReplyCommentDetail(id, onRequest, onFinally));
  };

  const handleRetweet = (id, data) => dispatch(postRetweet(id, data));

  const handleToViewHead = () => {
    contentRef.current.scrollIntoView();
  };

  const [refOutSideThree] = useClickOutSize(() => {
    setIsVisiblePopup(-1);
  });

  useEffect(() => {
    if (idPostMeta) {
      dispatch(getDetailPostMeta(idPostMeta));
    }
  }, [idPostMeta, dispatch]);

  const callbackSuccessActionPost = () => {
    dispatch(creatorGetPostDetail(id, 1));
  };

  const canGoBack = useMemo(() => {
    return window.history.state && window.history.state.idx > 0;
  }, []);

  return (
    <>
      {isShowPopupConfirm && (
        <PopupConfirm
          title={t('PostDetail.Popup.reason')}
          subTitle={subTititeConfirmPost}
          handleClosePopup={() => setIsShowPopupConfirm(false)}
          handleSuccess={() => setIsShowPopupDeactiveSuccess(true)}
          type={detailPost?.is_active === 0 ? 'no-reason' : ''}
        />
      )}

      {isShowPopupDeactiveSuccess && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsShowPopupDeactiveSuccess(e)}
          iconNote={true}
          text={
            detailPost?.is_active === 0
              ? t('PostDetail.Popup.post_has_been_activated')
              : t('PostDetail.Popup.post_has_been_deactivated')
          }>
          <div
            className='ok btn'
            onClick={() => {
              setIsShowPopupDeactiveSuccess(false);
              callbackSuccessActionPost();
            }}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}

      {isShowPopupDeletePost && (
        <PopupConfirm
          title={t('PostDetail.Popup.reason')}
          subTitle={subTititeConfirmPost}
          handleClosePopup={() => setIsShowPopupDeletePost(false)}
          type='delete-post'
          handleSuccess={() => setIsShowPopupDeleteSuccess(true)}
        />
      )}

      {isShowPopupDeleteSuccess && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsShowPopupDeleteSuccess(e)}
          iconNote={true}
          text={t('PostDetail.Popup.post_has_been_deleted')}>
          <div
            className='ok btn'
            onClick={() => {
              setIsShowPopupDeleteSuccess(false);
              callbackSuccessActionPost();
            }}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}

      <PopupPaymentBySub />
      {popupCopyLink && (
        <PopupSharePost isVisiblePopup={handleShowPopup} data={popupCopyLink || {}} />
      )}
      {isShowPopupMess?.status && (
        <PopupViewFullMess
          item={isShowPopupMess?.dataItemMess}
          close={() => {
            setShowPopupMess({
              ...isShowPopupMess,
              status: false,
              dataItem: {},
            });
            dispatch(creatorGetPostDetail(id, false));
          }}
        />
      )}
      {isVisiblePopUpDeletePost && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePost(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          className='popup-delete-post-details'
          text={t('Popup.confirmDeletePost')}>
          <div className='ok btn' onClick={deletePostTimeLine}>
            {t('Common.buttonYes')}
          </div>
          <div className='btn cancel' onClick={() => setIsVisiblePopDeletePost(null)}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpDeletePostComplete && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePostComplete(e)}
          iconNote={true}
          text={t('Popup.deletePostSucess')}>
          <div className='ok btn' onClick={() => setIsVisiblePopDeletePostComplete(false)}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisibleQuote && (
        <QuotePostMyPage
          closeModal={() => setIsVisibleQuote(false)}
          isVisibleQuote={isVisibleQuote}
          item={itemPostPopup}
        />
      )}
      {popupDeleteComment && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupDeleteComment(false)}
          colorSvg='#FF9F43'
          iconNote
          text={t('Popup.confirmDeleteComment')}
          className='popup-delete'>
          <div className='ok btn' onClick={handleDeleteComment}>
            {t('Common.buttonDelete')}
          </div>
          <div className='btn cancel' onClick={() => setPopupDeleteComment(false)}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}
      {popupHideComment && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupHideComment(false)}
          colorSvg='#FF9F43'
          iconNote
          text={t('Popup.confirmHideComment')}
          className='popup-delete'>
          <div className='ok btn' onClick={handleHideComment}>
            {t('Common.buttonYes')}
          </div>
          <div className='btn cancel' onClick={() => setPopupHideComment(false)}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}
      <>
        {listTimeLine?.length > 0 && detailPost ? (
          <>
            {listTimeLine?.length > 0 &&
              listTimeLine.map((item, index) => (
                <div
                  style={{
                    borderBottom: 'none',
                    background: '#FAFAFA',
                    marginBottom: '20px',
                    borderRadius: '8px',
                  }}
                  key={item?.id}
                  className={`my-page-item detail-post-popup ${
                    item?.unlock_media === 0 && item?.type === 1 && 'popup_lock_post'
                  }`}>
                  {/* {renderPostItem(item, index)} */}
                  <RenderPostItem
                    item={item}
                    index={index}
                    refOutSideThree={refOutSideThree}
                    setIsVisiblePopup={setIsVisiblePopup}
                    isVisiblePopup={isVisiblePopup}
                    isAuth={isAuth}
                    setIsShowPopupConfirm={setIsShowPopupConfirm}
                    setSubTititeConfirmPost={setSubTititeConfirmPost}
                    detailPost={detailPost}
                    setIsVisiblePopDeletePost={setIsVisiblePopDeletePost}
                    checkDisabledAction={checkDisabledAction}
                    bookmarkFunc={bookmarkFunc}
                    setShowPopup={setShowPopup}
                    isShowPopup={isShowPopup}
                    setShowPopupMess={setShowPopupMess}
                    isShowPopupMess={isShowPopupMess}
                    setIsShowPopupDeletePost={setIsShowPopupDeletePost}
                    toggleValuePost={toggleValuePost}
                    handleFollowCreator={handleFollowCreator}
                    navigateByLink={navigateByLink}
                    openIndex={openIndex}
                    setOpenIndex={setOpenIndex}
                    point={point}
                    setIsOpen={setIsOpen}
                    setIdPackage={setIdPackage}
                    handleOnPackageLimit={handleOnPackageLimit}
                    setIsVisiblePopupGhim={setIsVisiblePopupGhim}
                    isVisiblePopupGhim={isVisiblePopupGhim}
                    id={id}
                    handleRetweet={handleRetweet}
                    setItemPostPopup={setItemPostPopup}
                    setIsVisibleQuote={setIsVisibleQuote}
                    contentRef={contentRef}
                    handleLikePost={handleLikePost}
                    handleSharePost={handleSharePost}
                    bookmarkChange={bookmarkChange}
                    handleByPackageSuccess={handleByPackageSuccess}
                    dataUser={dataUser}
                    idFavorite={idFavorite}
                    setRenderFolowCMT={setRenderFolowCMT}
                    setLoaddingDown={setLoaddingDown}
                    widthScreen={widthScreen}
                  />
                  <>
                    <div
                      // className={`sroll-list ${!item?.number_comment ? 'no-list' : ''}`}
                      className='comment-wrap'
                      style={{ padding: '0 16px' }}
                      // id='sroll-list-comment-post-detail'
                    >
                      <CommentPostTimeLineShowAll
                        defaultCount={item?.number_comment}
                        pages='detail'
                        dataPost={item}
                        handleDeleteComment={(e) => {
                          setPopupDeleteComment(e);
                        }}
                        handleHideComment={(e) => {
                          setPopupHideComment(e);
                        }}
                        handleLikeComment={handleLikeComment}
                        handleFollowUserComment={handleFollowUserComment}
                        setTrickReloadComment={setTrickReloadComment}
                        trickReloadComment={trickReloadComment}
                        id={item?.id}
                        dataProfile={dataUser}
                        renderFolowCMT={renderFolowCMT}
                        // defaultFetch={10}
                        typeFollow='postDetail'
                      />
                    </div>
                    <div className='div-list-cmt'>
                      <div
                        className={`comment block ${
                          (isAdmin ||
                            isAgency ||
                            detailPost?.is_active === 0 ||
                            detailPost?.is_active === 2) &&
                          'disabled'
                        }`}>
                        {!dataUser?.avatar || decryptPath(dataUser?.avatar) === 'NULL' ? (
                          <img src={AVATAR_DEFAULT} alt='avt'></img>
                        ) : (
                          <ImgCustom
                            screen='avatar_44_44'
                            isPlaceholderAvatar
                            // src={dataUser?.avatar ? `${dataUser.avatar}` : Avatar}
                            src={
                              isAdmin || isAgency
                                ? Avatar
                                : dataUser?.avatar
                                ? `${dataUser?.avatar}`
                                : Avatar
                            }
                            // src={`${dataUser?.avatar}`}
                          />
                        )}
                        <CommentForm
                          item={item}
                          handleToViewHead={handleToViewHead}
                          setTrickReloadComment={setTrickReloadComment}
                        />
                      </div>
                    </div>
                  </>
                </div>
              ))}
          </>
        ) : (
          !loading &&
          !detailPost &&
          listTimeLine?.length > 0 && (
            <div className='empty-box'>
              <ImgCustom
                src={ImgEmptyData}
                alt='empty-data'
                style={{ backgroundColor: 'transparent', paddingBottom: '10px' }}
              />
              <div>{tab === 1 ? t('MyPage.ListPostNull4') : t('MyPage.ListPostNull1')}</div>
              <div>{tab === 1 ? t('MyPage.ListPostNull5') : t('MyPage.ListPostNull2')}</div>
              <div>{tab === 1 && t('MyPage.ListPostNull6')}</div>
            </div>
          )
        )}
      </>
    </>
  );
};

export default PostContentDetail;

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Checkbox,
  CustomSelectTime,
  ImgCustom,
  LayoutCreatorRight,
  PopupConfirmFan,
  ReactCalendar,
  ToggleButton,
  VideoDetail,
} from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import { pushNotify } from 'components/Notification';
import { newPathUser } from 'constants/layout/constant';
import withCreatorRole from 'hocs/withCreatorRole';
import useCalendar from 'hooks/useCalendar';
import moment from 'moment';
import Textarea from 'rc-textarea';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { creatorEditArticle, creatorGetPostDetailNew } from 'store/actions/myPage';
import { creatorGetListPackage } from 'store/actions/users';
import {
  acceptedImageTypes,
  acceptedImgVideoTypes,
  acceptIntegerOfInputText,
  AVATAR_DEFAULT,
  checkLinkPostSale,
  convertFullToHalf,
  convertToCurrentTime,
  currentTimeToUTC,
  decryptPath,
  failFileThan10Gb,
  failImgInvalid,
  failImgThan10,
  failImgThan10Mb,
  failMoreThan1Video,
  failShortVideo,
  failVideoInvalid,
  failVideoLonger3Minutes,
  failVideoThan1,
  formatCoin,
  formatDateBE,
  formatHourMinuteBE,
  getDurationVideo,
  getPresignedUrlS3,
  IMG_NO_WATER_MARK,
  isValidDate,
  linkS3Video,
  patternKatakanaHiraganaFullWidth,
  patternNoFirstSpacing,
  patternOnlyNumber,
  patternRequiredStringAndNumber,
  patterTextJp,
  renderTimeAfter24h,
  setFileNameS3,
  sortListPackagePointAndDate,
  TEN_GB,
  TEN_MB,
  textValidateRequired2,
  TYPE_VIDEOS,
  uploadS3ByPresigned,
} from 'utils';
import 'video-react/dist/video-react.css';
import '../content.scss';
import { handleTimeEdit } from './utils';
import CalendarIconV2 from 'images/CalendarIconV2';
import { useMemo } from 'react';
import LoadingIconV2 from 'images/LoadingIconV2';
import { isMobile } from 'react-device-detect';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { useFixedPopup } from 'hooks/v2/useFixedPopup';
import PerformerPopup from '../post-content/performerPopup';
import PerformerIcon from 'images/PerformerIcon';
import { getInfoMetaPost } from 'store/actions/postMeta';
import MetaTagViewer from 'components/MetaTagViewer/MetaTagViewer';
import ProcessingStatusBar from '../components/ProcessingStatusBar';
import { CHUNK_FILE, uploadVideoWithMulti } from '../help';
import { regexInteger } from 'utils/regex';
import { CloseIcon } from 'images';
import { updatePostMyPageWhenUpdate } from 'store/actions/usersNew';
import ListImgVideoZip from './listImgVideoZip';
import { getEnv } from 'configs/env';
import CloseIconV2 from 'images/CloseIconV2';
import MarkDownRead from 'components/MarkDownRead';
import { TYPE_NORMAL, TYPE_SHORT } from '../post-normal';
import {
  ControlBar,
  CurrentTimeDisplay,
  Player,
  TimeDivider,
  VolumeMenuButton,
} from 'video-react';
import UploadImageIconV2 from 'images/UploadImageIconV2';
import { postDurationVideo } from 'store/actions/getDurationVideo';
import { getInfo } from 'react-mediainfo';

const EditPostContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { detailPost, loading } = useSelector((state) => state.myPage);
  const dataProfile = useSelector((state) => state.users.data);
  const { loading: loadingListPackage, dataUser } = useSelector((state) => state.users);
  const [valueCoin, setValueCoin] = useState();
  const [valueCoinConvert, setValueCoinConvert] = useState();
  const [valueChange, setValueChange] = useState();
  const [handleOnBlurPrice, setHandleOnBlurPrice] = useState(true);
  const [title, setTitle] = useState();
  const [content, setContent] = useState();
  const { t } = useTranslation();
  const [isCheckPackagePaid, setIsCheckPackagePaid] = useState(false);
  const [isCheckOnlyCreator, setCheckIsOnlyCreator] = useState(false);
  const [isOnlyCreatorBeforeEdit, setIsOnlyCreatorBeforeEdit] = useState(false);
  const [isPostHaveBy, setIsPostHaveBy] = useState(false);
  const [creatorListPackage, setCreatorListPackage] = useState([]);
  const [listChecked, setListChecked] = useState([]);
  const [dataCheckBox, setDataCheckBox] = useState([]);
  const [valueListCheck, setValueListCheck] = useState(false);
  const [isChangeDateTime, setIsChangeDateTime] = useState(true);
  const [isChangeDateTimePublic, setIsChangeDateTimePublic] = useState(true);

  const [isChoicePrice, setIsChoicePrice] = useState(false);
  const [isChoicePremium, setIsChoicePremium] = useState(false);

  const [isCheckCondition, setIsCheckCondition] = useState(false);
  const [valuePremiumTicket, setValuePremiumTicket] = useState(false);

  const [timeStartDiscount, setTimeStartDiscount] = useState(null);
  const [timeEndDiscount, setTimeEndDiscount] = useState(null);
  const [isTimeDiscountValid, setIsTimeDiscountValid] = useState(true);

  const [isCheckTimeSell, setIsCheckTimeSell] = useState(false);
  const [isFailTime, setIsFailTime] = useState(false);
  const [isFailTimeNormal, setIsFailTimeNormal] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [innitPublicTime, setInitPublicTime] = useState();
  const [timePost, setTimePost] = useState('00:00');
  const [timeRelease, setTimeRelease] = useState('00:00');
  const [timePostInit, setTimePostInit] = useState('00:00');
  const [timeReleaseInit, setTimeReleaseInit] = useState('00:00');
  const today = Date.now();
  const [disableStartTime, setDisableStartTime] = useState(false);
  //loading
  const [popupLoading, setPopupLoading] = useState(false);

  const [isVisiblePopUpConfirmChangeCreator, setIsVisiblePopUpConfirmChangeCreator] =
    useState(false);

  const [isVisiblePopUpConfirm1, setIsVisiblePopUpConfirm1] = useState(false);
  const [isVisiblePopUpConfirm2, setIsVisiblePopUpConfirm2] = useState(false);

  const [idPostMeta, setIdPostMeta] = useState(null);
  const [postDetailMeta, setPostDetailMeta] = useState(null);

  const [isPostApprovedByAdmin, setIsPostApprovedByAdmin] = useState(false);
  const [isPostRejectByAdmin, setIsPostRejectByAdmin] = useState(false);
  const [isPostWaitApprove, setIsPostWaitApprove] = useState(false);

  // STATE VIDEO - IMG
  const [videoUpload, setVideoUpload] = useState({ img: [], files: null });
  const [trailerUpload, setTrailerUpload] = useState();
  const [thumbnailUpload, setThumbnailUpload] = useState();
  const [listImgUpload, setListImgUpload] = useState();
  const [fileZipUpload, setFileZipUpload] = useState();
  const [durationVideoTrailer, setDurationVideoTrailer] = useState(0);
  const [errorTimeDurationInput, setErrorTimeDurationInput] = useState(null);
  const [isShowPerformerPopup, setIsShowPerformerPopup] = useState(false);

  const [listPerformer, setListPerformer] = useState([]);

  const [percentUpload, setPercentUpload] = useState([]);
  const [percentImg, setPercentImg] = useState([]);
  const [percentTrailer, setPercentTrailer] = useState([]);
  const [percentThumbnail, setPercentThumbnail] = useState([]);
  const [openProgress, setOpenProgress] = useState(false);

  const [productContent1, setProductContent1] = useState(false);
  const [productContent2, setProductContent2] = useState(false);
  const [productContent3, setProductContent3] = useState(false);
  const [productContent4, setProductContent4] = useState(false);
  const [productContent5, setProductContent5] = useState(false);
  const [productContent6, setProductContent6] = useState(false);
  const [productContent7, setProductContent7] = useState(false);
  const [hiddenQuote, setHiddenQuote] = useState(false);
  const [isShowPopupConfirm, setIsShowPopupConfirm] = useState(false);
  const [isLoadingFirst, setIsLoadingFirst] = useState(true);
  const [isChangeTime1, setIsChangeTime1] = useState(false);
  const [typePost, setTypePost] = useState(TYPE_NORMAL);
  const [isChangeDataImage, setIsChangeDataImage] = useState(false);
  const [videoUploadPublicFlag0, setVideoUploadPublicFlag0] = useState({
    img: [],
    files: null,
  });

  const [checkListImgPublicFlag0, setCheckListImgPublicFlag0] = useState({
    img: [],
    files: [],setTimeReleaseInit
  });

  const [checkListImg, setCheckListImg] = useState({ img: [], files: [] });
  const [imgUpload, setImgUpload] = useState({ img: [] });
  const [durationVideo, setDurationVideo] = useState(0);
  const [timePostCheck, setTimePostCheck] = useState(null);
  const [timeReleaseCheck, setTimeReleaseCheck] = useState(null);
  useFixedPopup(isShowPerformerPopup);

  const isErrorDurationInput = useMemo(() => {
    if (!trailerUpload && errorTimeDurationInput) {
      return true;
    }
    return false;
  }, [trailerUpload, errorTimeDurationInput]);
  const [datagetPresignedUrlS3, setDatagetPresignedUrlS3] = useState({
    thumbnail: '',
    main_video: '',
    trailer: '',
    images: [],
    zip: '',
  });
  const isApproved = useMemo(() => {
    return dataUser?.status === 1;
  }, [dataUser]);
  const [checkValueNumberPart, setCheckValueNumberPart] = useState(false);

  const datePickerRefStartDiscount = useRef();
  const datePickerRefEndDiscount = useRef();

  const {
    date: date1,
    toggleCalendar: toggleCalendar1,
    onChangeDate: onChangeDate1,
    isVisibleCalendar: isVisibleCalendar1,
  } = useCalendar();
  const {
    date: date2,
    toggleCalendar: toggleCalendar2,
    onChangeDate: onChangeDate2,
    isVisibleCalendar: isVisibleCalendar2,
  } = useCalendar();
  const {
    date: dateStartDiscount,
    toggleCalendar: toggleCalendarStartDiscount,
    onChangeDate: onChangeDateStartDiscount,
    isVisibleCalendar: isVisibleCalendarStartDiscount,
  } = useCalendar();
  const {
    date: dateEndDiscount,
    toggleCalendar: toggleCalendarEndDiscount,
    onChangeDate: onChangeDateEndDiscount,
    isVisibleCalendar: isVisibleCalendarEndDiscount,
  } = useCalendar();
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm({
    mode: 'all',
  });

  useEffect(() => {
    dispatch(
      creatorGetPostDetailNew(id, {
        filter_words: false,
      })
    );
  }, [id]);

  useEffect(() => {
    if (isApproved) {
      console.log('aaa', setDataCheckBox);
      dispatch(
        creatorGetListPackage('', '', ({ data }) => {
          const dataSort = sortListPackagePointAndDate(data);
          setCreatorListPackage(dataSort);
        })
      );
    }
  }, [isApproved]);

  useEffect(() => {
    const top = document.getElementById('app_cocofans_0');
    top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
  }, []);

  useEffect(() => {
    if (creatorListPackage && detailPost) {
      const {
        price,
        number_premium_ticket,
        unpublic_time,
        public_time,
        content,
        title,
        packages,
        created_at,
        all_package,
        type,
        post_contracts,
        is_active,
        discount_start,
        discount_end,
        discount,
        creator_only,
        is_post_purchased,
        follow_flag,
        enable_comment,
        in_24h_flag,
        medias,
      } = detailPost;
      if (number_premium_ticket) {
        setIsChoicePremium(true);
      }
      setCheckIsOnlyCreator(creator_only === 1);
      setProductContent4(type === 2);
      setProductContent5(follow_flag === 1);
      setProductContent6(enable_comment === 1);
      setProductContent7(in_24h_flag === 1);
      setIsOnlyCreatorBeforeEdit(creator_only === 1);
      setIsPostHaveBy(is_post_purchased === 1);

      if (medias.length > 0) {
        const cloneListImgInit = medias.slice();
        const listType1 = [];
        const listTypeFlag0 = [];
        cloneListImgInit?.map((item) => {
          if (item.public_flag === 1 && item.type === 1) {
            listType1.push(item.url);
          }
          if (item.public_flag !== 1 && item.type === 1) {
            listTypeFlag0.push(item.url);
          }
          if (item.type === 2) {
            setDurationVideo(item?.duration);
            setDatagetPresignedUrlS3((prev) => ({ ...prev, main_video: item?.url }));
            if (item.public_flag === 1) {
              setVideoUpload((prev) => ({
                ...prev,
                img: decryptPath(item?.url),
                files: decryptPath(item?.url),
              }));
            } else {
              setVideoUpload({
                img: 0,
                files: 0,
              });
              setVideoUploadPublicFlag0({
                img: decryptPath(item?.url),
                files: decryptPath(item?.url),
              });
            }
          }
        });

        setCheckListImg({
          files: listType1,
          img: listType1,
        });
        setCheckListImgPublicFlag0({
          files: listTypeFlag0,
          img: listTypeFlag0,
        });
      }

      if (price !== null) {
        setIsChoicePrice(true);
      }
      if (discount && discount_start && discount_end) {
        setValue('discount', discount);
        const startDiscount = convertToCurrentTime(discount_start);
        setValue('start_time_discount', formatDateBE(startDiscount));
        setTimeStartDiscount(formatHourMinuteBE(startDiscount));

        const endDiscount = convertToCurrentTime(discount_end);
        setValue('end_time_discount', formatDateBE(endDiscount));
        setTimeEndDiscount(formatHourMinuteBE(endDiscount));
      }
      if (is_active === 1) {
        setIsPostApprovedByAdmin(true);
      } else if (is_active === 2) {
        setIsPostRejectByAdmin(true);
      } else {
        setIsPostWaitApprove(true);
      }
      setListPerformer(
        post_contracts?.map((item) => ({
          id: item?.contract_id,
          first_name_romaji: item?.first_name_romaji,
          last_name_romaji: item?.last_name_romaji,
        }))
      );
      if (type === 4) {
        setTypePost(TYPE_SHORT);
      }
      setValue('content', content);
      setTitle(title);
      setValue('follow_flag', { value: follow_flag });
      setContent(content);
      setValueCoinConvert(price && formatCoin(price));
      setValueCoin(price);
      setValue('price', price);
      detailPost?.number_part && setCheckValueNumberPart(true);
      setValue('number_part', detailPost?.number_part);
      setValue('content', content);
      setValue('title', title);
      const publicTimeUtc = convertToCurrentTime(public_time ? public_time : created_at);
      const unPublicTimeUtc = convertToCurrentTime(unpublic_time ? unpublic_time : '');
      setInitPublicTime(moment(public_time ? public_time : created_at).toDate());
      // setStartDate(
      //   `${
      //     (public_time?.split(' ')[0] || created_at?.split(' ')[0]) +
      //     ' ' +
      //     formatHourMinuteBE(public_time)
      //   }`
      // );
      setStartDate(
        public_time &&
          convertToCurrentTime(
            `${(public_time?.split(' ')[0] || created_at?.split(' ')[0]) + ' ' + formatHourMinuteBE(public_time)}`
          )
      );

      setTimePost(formatHourMinuteBE(publicTimeUtc));
      setTimePostInit(formatHourMinuteBE(public_time ? public_time : created_at));
      setEndDate(
        unpublic_time &&
          convertToCurrentTime(
            `${unpublic_time?.split(' ')[0] + ' ' + formatHourMinuteBE(unpublic_time)}`
          )
      );
      setValue('public_time', formatDateBE(publicTimeUtc || ''));
      setValue('unpublic_time', formatDateBE(unPublicTimeUtc || ''));
      if (type !== 2 && (public_time || unpublic_time)) {
        setProductContent3(true);
        setIsCheckTimeSell(true);
      }
      if (public_time && unpublic_time) {
        setIsCheckTimeSell(true);
        setProductContent3(true);
        setValue('time_sell_radio', 1);
        const publicTimeUtc = convertToCurrentTime(public_time);
        setValue('public_time', formatDateBE(publicTimeUtc));
        setTimePost(formatHourMinuteBE(publicTimeUtc));
        setTimePostInit(formatHourMinuteBE(public_time));
        const unPublicTimeUtc = convertToCurrentTime(unpublic_time);
        setValue('unpublic_time', formatDateBE(unPublicTimeUtc));
        setTimeRelease(formatHourMinuteBE(unPublicTimeUtc));
        setTimeReleaseInit(formatHourMinuteBE(unpublic_time));
      } else {
        setValue('time_sell_radio', 2);
      }
      if (number_premium_ticket) {
        setIsCheckCondition(true);
        setValue('number_premium_ticket', number_premium_ticket);
        setValue('condition_radio', 1);
        setValuePremiumTicket(true);
      } else {
        setValue('condition_radio', 2);
      }
      if (creatorListPackage.length > 0) {
        if (all_package === 1) {
          setDataCheckBox(
            creatorListPackage?.map((item) => ({
              ...item,
              checked: true,
            }))
          );
          setListChecked(creatorListPackage);
          setIsCheckPackagePaid(true);
          setProductContent2(true);
          setValue('paid_package_radio', 1);
        } else {
          if (packages?.length > 0) {
            const newListChecked = creatorListPackage.map((item) => {
              packages.map((pack) => {
                if (pack?.id === item?.id) {
                  item = { ...item, checked: true };
                }
              });
              return item;
            });
            setDataCheckBox(newListChecked);
            setListChecked(
              packages.map((item) => {
                return { ...item };
              })
            );
            console.log('========================');
            setIsCheckPackagePaid(true);
            setProductContent2(true);
            setValue('paid_package_radio', 1);
          } else {
            setDataCheckBox(creatorListPackage);
            setValue('paid_package_radio', 2);
          }
        }
      } else {
        setValue('paid_package_radio', 2);
      }
    }
    if (detailPost?.public_time === null) {
      onChangeDate1(null);
      setValue('public_time', null);
      setTimePost(null);
    }
    if (detailPost?.unpublic_time === null) {
      onChangeDate2(null);
      setValue('unpublic_time', null);
      setTimeRelease(null);
    }
  }, [creatorListPackage, dataProfile, detailPost]);

  const refUploadVideo = useRef(null);
  const refUploadImage = useRef(null);

  const watchContent = watch('content', '');
  const watchTitle = watch('title', '');
  const watchDiscount = watch('discount', '');
  const watchNumberPart = watch('number_part', '');
  const watchNumberTicket = watch('number_premium_ticket', '');
  const watchNumberTicketBoth = watch('number_premium_ticket_both', '');
  const watchStartDiscount = watch('start_time_discount', '');
  const watchEndDiscount = watch('end_time_discount', '');
  const watchPublickTime = watch('public_time', '');
  const watchUnPublickTime = watch('unpublic_time', '');
  const watchFollowFlag = watch('follow_flag', '');

  const checkPackage = useMemo(() => {
    return !productContent5 && (imgUpload?.img?.length > 0 || videoUpload);
  }, [productContent5, imgUpload, videoUpload]);

  const isHaveImgOrVideo = useMemo(() => {
    return imgUpload?.img?.length > 0 > 0 || videoUpload.img?.length > 0 ? true : false;
  }, [imgUpload, videoUpload]);

  const isShowErrorPackages = useMemo(() => {
    return productContent5 && !(imgUpload?.img?.length > 0 > 0 || videoUpload);
  }, [productContent5, imgUpload, videoUpload]);

  const isShowErrorPackagesNormal = useMemo(() => {
    return (
      (Number(watchFollowFlag?.value) === 1 || listChecked?.length > 0) &&
      !(
        imgUpload?.img?.length > 0 ||
        videoUpload?.files ||
        videoUploadPublicFlag0?.files ||
        checkListImg?.img?.length > 0
      )
    );
  }, [
    watchFollowFlag?.value,
    listChecked?.length,
    imgUpload?.img?.length,
    videoUpload?.files,
    videoUploadPublicFlag0?.files,
    checkListImg?.img?.length,
  ]);

  const checkNumberPart = !watchNumberPart
    ? false
    : !patternRequiredStringAndNumber.test(watchNumberPart) ||
      !patternNoFirstSpacing.test(watchNumberPart) ||
      watchNumberPart?.length > 10;

  const checkNumberTicket = isCheckCondition
    ? !watchNumberTicket
      ? true
      : !patternNoFirstSpacing.test(watchNumberTicket) ||
        watchNumberTicket?.length > 10 ||
        +watchNumberTicket < 0
    : false;

  const showErrorDiscount = (itemCheck) => {
    if (
      isChoicePrice &&
      (watchStartDiscount ||
        timeStartDiscount ||
        timeEndDiscount ||
        watchEndDiscount ||
        watchDiscount)
    ) {
      if (itemCheck) {
        return false;
      } else {
        if (
          !watchStartDiscount ||
          !timeStartDiscount ||
          !timeEndDiscount ||
          !watchEndDiscount ||
          !watchDiscount
        ) {
          return true;
        }
        return false;
      }
    }
    return false;
  };

  const checkValidIntegerJP = (value) => {
    if (value) {
      return /^[^.,]+$/.test(+value);
    }
    return true;
  };

  const checkValidInteger = (value) => {
    if (value) {
      return regexInteger.test(+value);
    }
    return true;
  };

  const disabledButtonPostNormal = useMemo(() => {
    if (isApproved) {
      // if (isLoadingFirst) {
      //   return (
      //     (!isHaveImgOrVideo && !watchContent?.trim()) ||
      //     (isHaveImgOrVideo && !listPerformer?.length) ||
      //     //(isCheckTimeRelease && date2 && !timeReleaseCheck && !detailPost?.unpublic_time) ||
      //     //(isCheckTimePost && date1 && !timePostCheck && !detailPost?.public_time) ||
      //     //(isCheckTimePost && !date1 && !detailPost?.public_time) ||
      //     //(isCheckTimeRelease && !date2 && !detailPost?.unpublic_time) ||
      //     popupLoading ||
      //     //statusPost ||
      //     isShowErrorPackages
      //   );
      // }
      return (
        // (!isHaveImgOrVideo && !watchContent?.trim()) ||
        (isHaveImgOrVideo && !listPerformer?.length) ||
        //(isCheckTimeRelease && date2 && !timeReleaseCheck && !detailPost?.unpublic_time) ||
        //(isCheckTimePost && date1 && !timePostCheck && !detailPost?.public_time) ||
        //(isCheckTimePost && !date1 && !detailPost?.public_time) ||
        //(isCheckTimeRelease && !date2 && !detailPost?.unpublic_time) ||
        !isFailTimeNormal ||
        popupLoading ||
        loading ||
        (isShowErrorPackagesNormal && detailPost?.type === 1) ||
        (isCheckTimeSell && date2 && !timeRelease && detailPost?.type === 4) ||
        (isCheckPackagePaid && !listChecked.length > 0)
      );
    } else {
      // if (isLoadingFirst) {
      //   return (
      //     !watchContent?.trim() || popupLoading || loading || statusPost || isShowErrorPackages
      //   );
      // }
      return (
        !watchContent?.trim() ||
        !isFailTimeNormal ||
        popupLoading ||
        loading ||
        //statusPost ||
        (isCheckPackagePaid && !listChecked.length > 0)
      );
    }
  }, [
    isApproved,
    isHaveImgOrVideo,
    watchContent,
    listPerformer?.length,
    isFailTimeNormal,
    popupLoading,
    loading,
    isShowErrorPackagesNormal,
    date2,
    timeReleaseCheck,
    detailPost?.unpublic_time,
    detailPost?.public_time,
    isCheckPackagePaid,
    date1,
    timePostCheck,
    listChecked,
    productContent3,
    detailPost?.type
  ]);

  const isDisabledButton =
    (!videoUpload && !fileZipUpload) ||
    isErrorDurationInput ||
    !listPerformer?.length ||
    !watchContent ||
    // (+isProdTrialMode !== 1 && isChoicePrice && !valueCoin) ||
    (isChoicePremium && !watchNumberTicket) ||
    (isChoicePremium && watchNumberTicket && watchNumberTicket == 0) ||
    (!isChoicePrice && !isChoicePremium) ||
    !isFailTime ||
    !valueListCheck ||
    // !thumbnailUpload ||
    popupLoading ||
    loading ||
    isVisiblePopUpConfirm1 ||
    isVisiblePopUpConfirm2 ||
    checkNumberPart ||
    checkNumberTicket ||
    errors?.discount ||
    (isChoicePrice && (valueCoin === null || valueCoin === undefined)) ||
    errors?.number_premium_ticket ||
    !isTimeDiscountValid ||
    !checkValidInteger(valueCoin) ||
    !checkValidIntegerJP(valueCoin) ||
    showErrorDiscount();

  useEffect(() => {
    const isValidContent = watchContent?.trim() !== '';
    if (isValidContent) {
      const idPost = checkLinkPostSale(watchContent);
      if (idPost) {
        setIdPostMeta(idPost);
      } else {
        setIdPostMeta(null);
        setPostDetailMeta(null);
      }
    } else {
      setPostDetailMeta(null);
      setIdPostMeta(null);
    }
  }, [watchContent]);

  useEffect(() => {
    if (idPostMeta) {
      dispatch(
        getInfoMetaPost(idPostMeta, (dataMeta) => {
          setPostDetailMeta(dataMeta);
        })
      );
    } else {
      setPostDetailMeta(null);
    }
  }, [idPostMeta, dispatch]);

  useEffect(() => {
    //check package
    if (isCheckPackagePaid) {
      listChecked.length > 0 ? setValueListCheck(true) : setValueListCheck(false);
    }
    !isCheckPackagePaid && setValueListCheck(true);

    // check premium ticket
    isCheckCondition && watchNumberTicket
      ? setValuePremiumTicket(true)
      : setValuePremiumTicket(false);
    !isCheckCondition && setValuePremiumTicket(false);
  }, [isCheckCondition, isCheckPackagePaid, listChecked.length, watchNumberTicket]);

  useEffect(() => {
    const publicTime = new Date(`${date1 || getValues('public_time')} ${timePost}`);
    const unPublicTime = new Date(`${getValues('unpublic_time') || date2} ${timeRelease}`);
    if (!productContent4) {
      if (!getValues('public_time')) {
        setDisableStartTime(true);
      } else {
        setDisableStartTime(false);
      }
      if (
        productContent3 &&
        isValidDate(new Date(`${date1 || getValues('public_time')} ${timePost}`)) === false &&
        isValidDate(new Date(`${date2 || getValues('unpublic_time')} ${timeRelease}`)) ===
          false
      ) {
        console.log('setIsFailTimeNormal12222222', isFailTimeNormal);
        setIsFailTimeNormal(false);
      } else {
        console.log('222222222222222222222');
        if (isValidDate(publicTime) === false && isValidDate(unPublicTime) === false) {
          setIsFailTimeNormal(true);
          console.log('555555555555555', publicTime, unPublicTime);
        } else if (isValidDate(publicTime) === true) {
          const publicTime = new Date(`${date1 || getValues('public_time')} ${timePost}`);
          const publicTimeOld = new Date(`${getValues('public_time')} ${timePost}`);
          console.log('324234234234234');
          if (isValidDate(publicTime) === true) {
            console.log('=================0', date2);
            if (date2 || getValues('unpublic_time')) {
              console.log('=================1');
              const unPublicTime = new Date(
                `${date2 || getValues('unpublic_time')} ${timeRelease || '00:00'}`
              );
              console.log('date2', date2, timeRelease, unPublicTime);
              if (publicTimeOld < Date.now()) {
                console.log('=================3');
                setIsFailTimeNormal(true);
                setDisableStartTime(true);
              }
              if (publicTime < Date.now() && isChangeTime1) {
                setIsFailTimeNormal(false);
                if (!isLoadingFirst) {
                  pushNotify('error', t('ValidateMsg.END_DAY_AFTER_DATE_NOW'));
                }
              }
              console.log('publicTime', publicTime);
              console.log('unPublicTime', unPublicTime);
              if (publicTime > unPublicTime) {
                console.log('setIsFailTimeNormal2222222222222221', isFailTimeNormal);
                setIsFailTimeNormal(false);
                if (!isLoadingFirst) {
                  pushNotify('error', t('ValidateMsg.END_DAY_AFTER_OR_EQUAL_NOW'));
                }
              } else if (isValidDate(unPublicTime) === true) {
                if (unPublicTime <= Date.now()) {
                  console.log('45334534534534534');
                  setIsFailTimeNormal(false);
                  if (!isLoadingFirst) {
                    pushNotify('error', t('ValidateMsg.END_DAY_AFTER_DATE_NOW'));
                  }
                } else {
                  setIsFailTimeNormal(true);
                }
              } else {
                console.log('3453453453');
                setIsFailTimeNormal(false);
              }
            } else {
              setIsFailTimeNormal(true);
              console.log('publicTime1111', publicTime);
              if (publicTime <= Date.now()) {
                if (!isLoadingFirst) {
                  setIsFailTimeNormal(false);
                  pushNotify('error', t('ValidateMsg.END_DAY_AFTER_DATE_NOW'));
                }
                console.log('22222222222');
                publicTimeOld < Date.now()
                  ? setDisableStartTime(true)
                  : setDisableStartTime(false);
              } else {
                setIsFailTimeNormal(true);
              }
            }
          } else {
            console.log('setIsFailTimeNormal1', isFailTimeNormal);
            setIsFailTimeNormal(false);
          }
        } else if (isValidDate(publicTime) === false && isValidDate(unPublicTime) === true) {
          const unPublicTime = new Date(
            `${date2 || getValues('unpublic_time')} ${timeRelease}`
          );
          if (isValidDate(unPublicTime) === true) {
            if (unPublicTime < Date.now()) {
              setIsFailTimeNormal(false);
              console.log('setIsFailTimeNormal1', isFailTimeNormal);
              if (!isLoadingFirst) {
                pushNotify('error', t('ValidateMsg.END_DAY_AFTER_DATE_NOW'));
              }
            } else {
              setIsFailTimeNormal(true);
            }
          } else {
            console.log('setIsFailTimeNormal1', isFailTimeNormal);
            setIsFailTimeNormal(false);
          }
        } else {
          setIsFailTimeNormal(true);
        }
      }
    }
  }, [
    date1,
    date2,
    timePost,
    timeRelease,
    productContent3,
    productContent4,
  ]);

  useEffect(() => {
    if (productContent4) {
      if (isCheckTimeSell) {
        const publicTime = moment(`${date1 || getValues('public_time')} ${timePost}`).toDate();
        const unPublicTime = moment(
          `${date2 || getValues('unpublic_time')} ${timeRelease}`
        ).toDate();
        // setValue('public_time', date1 || getValues('public_time'));
        setValue('unpublic_time', date2 || getValues('unpublic_time'));
        setDisableStartTime(false);
        if (!isChangeDateTimePublic) {
          if (publicTime <= today) {
            pushNotify('error', t('ValidateMsg.END_DAY_AFTER_DATE_NOW'));
          }
        }

        if (!isChangeDateTime) {
          if (publicTime > unPublicTime) {
            setIsFailTime(false);
            pushNotify('error', t('ValidateMsg.END_DAY_AFTER_OR_EQUAL_NOW'));
          }
          // if (unPublicTime <= today) {
          //   pushNotify('error', t('ValidateMsg.END_DAY_AFTER_DATE_NOW'));
          // }
        }

        if (isValidDate(publicTime) === true) {
          if (isValidDate(unPublicTime) === true) {
            if (innitPublicTime <= today) {
              setIsFailTime(true);
              setDisableStartTime(true);
            } else if (publicTime <= today) {
              setIsFailTime(false);
              pushNotify('error', t('ValidateMsg.END_DAY_AFTER_DATE_NOW'));
            } else if (publicTime > unPublicTime) {
              setIsFailTime(false);
              pushNotify('error', t('ValidateMsg.END_DAY_AFTER_OR_EQUAL_NOW'));
            } else if (unPublicTime < today) {
              setIsFailTime(false);
              pushNotify('error', t('ValidateMsg.END_DAY_AFTER_DATE_NOW'));
            } else setIsFailTime(true);
          } else {
            setIsFailTime(false);
          }
        } else {
          setIsFailTime(true);
          setDisableStartTime(true);
          if (isValidDate(unPublicTime) === true) {
            if (unPublicTime < today) {
              setIsFailTime(false);
              pushNotify('error', t('ValidateMsg.END_DAY_AFTER_DATE_NOW'));
              setDisableStartTime(true);
            } else setIsFailTime(true);
          } else {
            setIsFailTime(false);
          }
        }
        if (!detailPost?.unpublic_time && !detailPost?.public_time) {
          setDisableStartTime(true);
        }
      } else {
        setIsFailTime(true);
        !detailPost?.unpublic_time && !detailPost?.public_time && setDisableStartTime(true);
      }
    }
  }, [date1, date2, isCheckTimeSell, timePost, timeRelease, detailPost, productContent4]);

  useEffect(() => {
    if (timeStartDiscount && timeEndDiscount && dateStartDiscount && dateEndDiscount) {
      const start = new Date(`${dateStartDiscount} ${timeStartDiscount}`);
      const end = new Date(`${dateEndDiscount} ${timeEndDiscount}`);
      if (start > end) {
        setIsTimeDiscountValid(false);
        pushNotify('error', t('ValidateMsg.DISCOUNT_END_AFTER_OR_EQUAL_START_DAY'));
      } else {
        setIsTimeDiscountValid(true);
      }
    }
  }, [timeStartDiscount, timeEndDiscount, dateStartDiscount, dateEndDiscount]);

  const handleOnchangePlanPost = (e, pack) => {
    const { checked } = e.target;
    if (checked) {
      setListChecked(
        listChecked.concat({
          id: pack.id,
          title: pack.title,
          checked: true,
        })
      );
      const newData = dataCheckBox.map((item) => {
        if (item.id === pack.id) {
          return { ...item, checked: true };
        } else return item;
      });
      setDataCheckBox(newData);
    } else {
      const newData = dataCheckBox.map((item) => {
        if (item.id === pack.id) {
          return { ...item, checked: false };
        } else return item;
      });
      setDataCheckBox(newData);
      setListChecked(listChecked.filter((item) => item.id !== pack.id));
    }
  };
  const handleAllPlanPost = (e) => {
    const { checked } = e.target;
    if (checked) {
      const newData = dataCheckBox.map((item) => {
        return { ...item, checked: true };
      });
      setDataCheckBox(newData);
      setListChecked(newData);
    } else {
      const newData = dataCheckBox.map((item) => {
        return { ...item, checked: false };
      });
      setDataCheckBox(newData);
      setListChecked([]);
    }
  };

  // START IMG VIDEO
  useEffect(() => {
    if (checkListImg?.img?.length > 0) {
      const newArrImg = [];
      for (let i = 0; i < checkListImg?.img.length; i++) {
        if (typeof checkListImg?.img[i] !== 'string' && checkListImg?.img[i] !== undefined) {
          newArrImg.push(URL.createObjectURL(checkListImg?.img[i]));
        } else {
          newArrImg.push(checkListImg.img[i]);
        }
      }
      setCheckListImg((prev) => ({ ...prev, files: newArrImg }));
      const newList = [...checkListImg?.img, ...checkListImgPublicFlag0?.img];
      setImgUpload({ img: newList });
    }

    if (videoUpload?.img?.length) {
      const newArrVideo = [];
      if (typeof videoUpload?.img[0] !== 'string' && videoUpload?.img[0] !== undefined) {
        newArrVideo.push(URL.createObjectURL(videoUpload?.img[0]));
        setVideoUpload({ img: videoUpload?.img, files: newArrVideo });
      } else {
        setVideoUpload({ img: videoUpload?.img, files: videoUpload?.img });
      }
    }
  }, [videoUpload?.img, checkListImg?.img]);

  const handleInputTrailer = (e) => setTrailerUpload(e);
  const handleInputThumbnail = (e) => setThumbnailUpload(e);

  const handleRemoveImg = (i, item) => {
    const { img } = checkListImg;
    const removeEle = img.splice(i, 1);
    if (img.length === 0) {
      setCheckListImg({ img: [], files: [] });
      setImgUpload({ img: [] });
    } else {
      setCheckListImg((prev) => ({
        ...prev,
        img: img.filter((item) => item !== removeEle[0]),
      }));
    }
  };

  const pcVideo = useMemo(() => {
    let percent = 0;
    if (videoUpload && typeof videoUpload.img?.[0] === 'object') {
      if (percentUpload?.length) {
        percentUpload.forEach((element) => {
          percent += Number(element);
        });
        percent /= percentUpload?.length;
      }
    }
    return Math.floor(percent);
  }, [percentUpload, videoUpload]);

  const pcImg = useMemo(() => {
    let percent = 0;
    const findItemUpload = imgUpload.img?.find((item) => typeof item === 'object');

    if (imgUpload?.img?.length && findItemUpload) {
      if (percentImg?.length) {
        percentImg.forEach((element) => {
          percent += Number(element);
        });
        percent /= percentImg?.length;
      }
    }
    return Math.floor(percent);
  }, [imgUpload, percentImg]);

  const pcTrailer = useMemo(() => {
    let percent = 0;
    if (trailerUpload && typeof trailerUpload === 'object') {
      if (percentTrailer?.length) {
        percentTrailer.forEach((element) => {
          percent += Number(element);
        });
        percent /= percentTrailer?.length;
      }
    }
    return Math.floor(percent);
  }, [percentTrailer, trailerUpload]);

  const pcThumbnail = useMemo(() => {
    let percent = 0;
    if (thumbnailUpload && typeof thumbnailUpload === 'object') {
      if (percentThumbnail?.length) {
        percentThumbnail.forEach((element) => {
          percent += Number(element);
        });
        percent /= percentThumbnail?.length;
      }
    }
    return Math.floor(percent);
  }, [percentThumbnail, thumbnailUpload]);

  const countItemUpload = useMemo(() => {
    const findItemUpload =
      imgUpload.img?.length && imgUpload.img?.find((item) => typeof item === 'object');

    const countImg = imgUpload.img?.length && findItemUpload ? 1 : 0;
    const countVideo = videoUpload && typeof videoUpload.img?.[0] === 'object' ? 1 : 0;
    const countTrailer = trailerUpload && typeof trailerUpload === 'object' ? 1 : 0;
    const countThumbnail = thumbnailUpload && typeof thumbnailUpload === 'object' ? 1 : 0;

    return countImg + countTrailer + countVideo + countThumbnail;
  }, [trailerUpload, imgUpload, videoUpload, thumbnailUpload]);

  const pcUpload = useMemo(() => {
    return Math.floor((pcVideo + pcImg + pcTrailer + pcThumbnail) / countItemUpload);
  }, [pcVideo, pcImg, pcTrailer, pcThumbnail, countItemUpload]);

  const onSubmitPostNormal = (data) => {
    if (countItemUpload > 0) {
      setOpenProgress(true);
    } else {
      setPopupLoading(true);
    }
    let publicTime;
    let unPublicTime;
    if (!productContent3) {
      publicTime = undefined;
      unPublicTime = undefined;
    } else {
      if (
        (date1 === undefined && detailPost?.public_time === null) ||
        watchPublickTime === null
      ) {
        publicTime = undefined;
      } else {
        if (!!date1) {
          publicTime = currentTimeToUTC(new Date(`${date1 + ' ' + timePost}`));
        } else {
          publicTime = currentTimeToUTC(
            new Date(
              `${
                moment
                  .utc(
                    detailPost?.public_time || detailPost?.created_at,
                    'YYYY-MM-DD HH:mm:ss'
                  )
                  .local()
                  .format('YYYY-MM-DD') +
                ' ' +
                timePost
              }`
            )
          );
        }
      }
      if (
        (date2 === undefined && detailPost?.unpublic_time === null) ||
        watchUnPublickTime === null
      ) {
        unPublicTime = undefined;
      } else {
        if (!!date2) {
          unPublicTime = currentTimeToUTC(new Date(`${date2 + ' ' + timeRelease}`));
        } else {
          unPublicTime = currentTimeToUTC(
            new Date(
              `${
                moment
                  .utc(detailPost?.unpublic_time, 'YYYY-MM-DD HH:mm:ss')
                  .local()
                  .format('YYYY-MM-DD') +
                ' ' +
                timeRelease
              }`
            )
          );
        }
      }
    }

    let newData = {
      ...data,
      post_share_id: hiddenQuote ? null : detailPost?.post_share_id,
      enable_comment: productContent6 ? 1 : 0,
      follow_flag: productContent5 ? 1 : 0,
      in_24h_flag: productContent7 ? '1' : '0',
      public_time: publicTime,
      unpublic_time: unPublicTime,
    };
    if (checkPackage) {
      newData = {
        ...newData,
        package_ids: productContent2 && listChecked,
        all_package:
          creatorListPackage.length > 0
            ? dataCheckBox.length === listChecked.length
              ? 1
              : 0
            : 0,
      };
    }
    if (detailPost?.type === 4) {
      newData = {
        content: newData?.content,
        enable_comment: newData?.enable_comment,
        follow_flag: newData?.follow_flag,
        in_24h_flag: newData?.in_24h_flag,
        public_time: publicTime,
        unpublic_time: unPublicTime,
      };
    }
    setDatagetPresignedUrlS3((prev) => ({ ...prev, ...newData }));
    const dataEdit = {
      ...datagetPresignedUrlS3,
      ...newData,
      contract_ids: isHaveImgOrVideo ? listPerformer?.map((e) => e.id) : [],
    };
    handleConfirmSubmitNormal(dataEdit);
  };

  const handleConfirmSubmitNormal = async (dataEdit) => {
    let main_video_pre_signed;
    let main_video;
    let checkNewVideo = typeof videoUpload.img?.[0] === 'object';
    const checkNewImg = imgUpload.img?.filter((item) => {
      return typeof item === 'object';
    });
    const checkOld = imgUpload.img?.filter((item) => {
      return typeof item !== 'object';
    });
    let listLinkS3Img = checkOld || [];
    let listLinkPreSignedImg = [];

    if (videoUpload?.files) {
      if (checkNewVideo) {
        if (videoUpload.img[0]?.size > CHUNK_FILE) {
          try {
            const resUrl = await uploadVideoWithMulti(videoUpload.img[0], setPercentUpload);
            main_video = resUrl.data.file_path;
            uploadMainVideo(dataEdit, main_video);
          } catch (error) {
            setPopupLoading(false);
            setOpenProgress(false);
          }
        } else {
          await getPresignedUrlS3(
            'video',
            `uploads/output/videos`,
            setFileNameS3(videoUpload.img[0].name),
            'post'
          )
            .then((resUrl) => {
              main_video_pre_signed = resUrl.data.pre_signed;
              main_video = resUrl.data.file_path;
            })
            .then(() => {
              uploadS3ByPresigned(
                main_video_pre_signed,
                videoUpload.img[0],
                setPercentUpload,
                percentUpload,
                0
              )
                .then(() => {
                  uploadMainVideo(dataEdit, main_video);
                })
                .catch((e) => {
                  console.log(e);
                  setPopupLoading(false);
                  setOpenProgress(false);
                });
            })
            .catch((_error) => {
              console.log(_error);
              setPopupLoading(false);
              setOpenProgress(false);
            });
        }
      } else if (!checkNewVideo) {
        main_video = videoUpload.files;
        if (typePost === TYPE_SHORT) {
          const newData = {
            ...dataEdit,
            duration: +durationVideo <= 60 ? +durationVideo * 1000 : +durationVideo,
            short_video: videoUpload.files,
          };
          delete newData['main_video'];
          dispatch(
            creatorEditArticle(
              detailPost.id,
              newData,
              detailPost?.type,
              handleShowPopupComfirmSuccess
            )
          );
        } else if (!imgUpload?.img?.length || imgUpload?.img?.length <= 0) {
          let newData = {
            ...dataEdit,
            duration: +durationVideo <= 60 ? +durationVideo * 1000 : +durationVideo,
            main_video,
          };
          dispatch(
            creatorEditArticle(
              detailPost.id,
              newData,
              detailPost?.type,
              handleShowPopupComfirmSuccess
            )
          );
        }
      }
    } else if (videoUploadPublicFlag0?.files) {
      main_video = videoUploadPublicFlag0?.files;
    }

    if (checkNewImg.length > 0) {
      const promiseList = checkNewImg.map((item) => {
        return getPresignedUrlS3(
          'image',
          `uploads/output/images`,
          setFileNameS3(item.name),
          'post'
        );
      });
      Promise.all(promiseList)
        .then((responses) => {
          responses.forEach((element) => {
            if (element.success) {
              listLinkS3Img.push(element.data.file_path);
              listLinkPreSignedImg.push(element.data.pre_signed);
            }
          });
          const promiseGenerate = listLinkPreSignedImg.map((m, i) => {
            return uploadS3ByPresigned(m, checkNewImg[i], setPercentImg, percentImg, i);
          });
          Promise.all(promiseGenerate).then(() => {
            let newData = {
              ...dataEdit,
              duration: +durationVideo <= 60 ? +durationVideo * 1000 : +durationVideo,
              main_video,
            };
            delete newData['trailer'];
            delete newData['thumbnail'];
            if (typePost === TYPE_SHORT) {
              newData = { ...newData, short_images: listLinkS3Img };
              delete newData['main_video'];
            } else {
              newData = { ...newData, images: listLinkS3Img };
              delete newData['trailer'];
              delete newData['thumbnail'];
            }
            dispatch(
              creatorEditArticle(
                detailPost.id,
                newData,
                detailPost?.type,
                handleShowPopupComfirmSuccess
              )
            );
            setPopupLoading(false);
            setOpenProgress(false);
          });
        })
        .catch((_errors) => {
          console.log(_errors);
          setPopupLoading(false);
          setOpenProgress(false);
        });
    } else if (!checkNewImg.length > 0 && checkOld.length > 0) {
      let newData = {
        ...dataEdit,
        main_video,
        images: listLinkS3Img,
      };
      delete newData['trailer'];
      delete newData['thumbnail'];
      if (detailPost?.type === 4) {
        newData = {
          ...newData,
          short_video: newData?.main_video,
          short_images: newData?.images,
        };
        delete newData['images'];
        delete newData['main_video'];

        !newData?.short_video && delete newData['short_video'];
        !newData?.short_images && delete newData['short_images'];
      }
      dispatch(
        creatorEditArticle(
          detailPost.id,
          newData,
          detailPost?.type,
          handleShowPopupComfirmSuccess
        )
      );
    }
    if (
      !checkNewImg.length > 0 &&
      !checkOld.length > 0 &&
      !videoUpload.files &&
      !checkNewVideo &&
      videoUpload?.files !== 0 &&
      videoUpload?.img !== 0
    ) {
      let newData = {
        ...dataEdit,
        images: null,
        main_video: null,
      };
      delete newData['trailer'];
      delete newData['thumbnail'];
      if (detailPost?.type === 4) {
        delete newData['images'];
        delete newData['main_video'];
      }
      dispatch(
        creatorEditArticle(
          detailPost.id,
          newData,
          detailPost?.type,
          handleShowPopupComfirmSuccess
        )
      );
    }
  };

  const uploadMainVideo = (dataEdit, main_video) => {
    if (typePost === TYPE_SHORT) {
      let newData = {
        ...dataEdit,
        duration: +durationVideo <= 60 ? +durationVideo * 1000 : +durationVideo,
        short_video: main_video,
      };
      delete newData['main_video'];
      dispatch(
        creatorEditArticle(
          detailPost.id,
          newData,
          detailPost?.type,
          handleShowPopupComfirmSuccess
        )
      );
    } else if (!imgUpload?.img?.length || imgUpload?.img?.length <= 0) {
      let newData = {
        ...dataEdit,
        duration: +durationVideo <= 60 ? +durationVideo * 1000 : +durationVideo,
        main_video,
      };
      dispatch(
        creatorEditArticle(
          detailPost.id,
          newData,
          detailPost?.type,
          handleShowPopupComfirmSuccess
        )
      );
    }
  };

  const onSubmit = (data) => {
    if (countItemUpload > 0) {
      setOpenProgress(true);
    } else {
      setPopupLoading(true);
    }
    const { publicTime, unPublicTime } = handleTimeEdit(
      isCheckTimeSell,
      date1,
      date2,
      timePost,
      timePostInit,
      timeRelease,
      timeReleaseInit,
      detailPost
    );
    let useDateDiscount = false;
    if (isChoicePrice && watchStartDiscount) {
      useDateDiscount = true;
    }
    const newData = {
      ...data,
      creator_only: isCheckOnlyCreator ? 1 : 0,
      discount: useDateDiscount ? data?.discount : null,
      discount_start: useDateDiscount
        ? currentTimeToUTC(new Date(`${watchStartDiscount} ${timeStartDiscount}`))
        : null,
      discount_end: useDateDiscount
        ? currentTimeToUTC(new Date(`${watchEndDiscount} ${timeEndDiscount}`))
        : null,
      package_ids: listChecked,
      public_time: publicTime,
      unpublic_time: unPublicTime,
      price: isChoicePrice ? valueCoin : null,
      number_premium_ticket: isChoicePremium ? data?.number_premium_ticket : null,
      all_package:
        creatorListPackage.length > 0
          ? dataCheckBox.length === listChecked.length
            ? 1
            : 0
          : 0,
      images: listImgUpload,
    };
    setDatagetPresignedUrlS3((prev) => ({ ...prev, ...newData }));
    const newDataUpload = {
      contract_ids: listPerformer?.map((e) => e.id),
      duration_trailer: +durationVideoTrailer,
      ...datagetPresignedUrlS3,
      ...newData,
    };
    handleConfirmSubmit(newDataUpload);
  };

  const handleConfirmSubmit = async (newDataUpload) => {
    let newData = newDataUpload;
    let main_video_pre_signed;
    let thumbnail_pre_signed;
    let trailer_pre_signed;
    let listLinkS3Img = [];
    let listLinkPreSignedImg = [];

    let checkPostVideoPromise = null;
    if (typeof videoUpload.img?.[0] === 'object') {
      if (videoUpload?.size > CHUNK_FILE) {
        checkPostVideoPromise = new Promise((resolve, reject) => {
          uploadVideoWithMulti(videoUpload, setPercentUpload)
            .then((resUrl) => {
              newData = {
                ...newData,
                main_video: resUrl.data.file_path,
              };
              resolve();
            })
            .catch(() => {
              reject();
            });
        });
      } else {
        checkPostVideoPromise = new Promise((resolve, reject) => {
          getPresignedUrlS3(
            'video',
            `uploads/output/videos`,
            setFileNameS3(videoUpload.img[0].name),
            'post'
          )
            .then((resUrl) => {
              main_video_pre_signed = resUrl.data.pre_signed;
              newData = {
                ...newData,
                main_video: resUrl.data.file_path,
              };
            })
            .then(() => {
              uploadS3ByPresigned(
                main_video_pre_signed,
                videoUpload.img[0],
                setPercentUpload,
                percentUpload,
                0
              )
                .then(() => {
                  resolve();
                })
                .catch((e) => {
                  console.log(e);
                });
            })
            .catch((_error) => {
              reject();
              setPopupLoading(false);
              setOpenProgress(false);
              console.log(_error);
            });
        });
      }
    } else {
      newData = { ...newData, main_video: videoUpload.img || videoUpload };
    }

    const checkPostTrailerPromise =
      typeof trailerUpload === 'object'
        ? new Promise((resolve, reject) => {
            getPresignedUrlS3(
              'video',
              `uploads/output/videos`,
              setFileNameS3(trailerUpload.name),
              'post'
            )
              .then((resUrl) => {
                trailer_pre_signed = resUrl.data.pre_signed;
                newData = {
                  ...newData,
                  trailer: resUrl.data.file_path,
                };
              })
              .then(() => {
                uploadS3ByPresigned(
                  trailer_pre_signed,
                  trailerUpload,
                  setPercentTrailer,
                  percentTrailer,
                  0
                )
                  .then(() => {
                    resolve();
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              })
              .catch((_error) => {
                setPopupLoading(false);
                setOpenProgress(false);
                console.log(_error);
                reject();
              });
          })
        : (newData = { ...newData, trailer: decryptPath(trailerUpload) });

    const checkPostThumbnailPromise =
      typeof thumbnailUpload === 'object'
        ? new Promise((resolve, reject) => {
            getPresignedUrlS3(
              'image',
              `uploads/output/images`,
              setFileNameS3(thumbnailUpload.name),
              'post'
            )
              .then((resUrl) => {
                thumbnail_pre_signed = resUrl.data.pre_signed;
                newData = {
                  ...newData,
                  thumbnail: resUrl.data.file_path,
                };
              })
              .then(() => {
                uploadS3ByPresigned(
                  thumbnail_pre_signed,
                  thumbnailUpload,
                  setPercentThumbnail,
                  percentThumbnail,
                  0
                )
                  .then(() => {
                    resolve();
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              })
              .catch((_error) => {
                setPopupLoading(false);
                setOpenProgress(false);
                reject();
                console.log(_error);
              });
          })
        : (newData = { ...newData, thumbnail: decryptPath(thumbnailUpload) });

    const handleShowPopupComfirmFail = (data) => {
      setPopupLoading(false);
      setOpenProgress(false);
      setPercentUpload([]);
      setPercentImg([]);
      setPercentTrailer([]);
      setPercentThumbnail([]);
    };
    const checkNewImg = imgUpload?.img?.filter((item) => {
      return typeof item === 'object';
    });
    const checkOld = imgUpload?.img?.filter((item) => {
      return typeof item !== 'object';
    });
    listLinkS3Img = checkOld || [];
    const checkPostListImgPromise = checkNewImg
      ? new Promise((resolve, reject) => {
          const promiseList = checkNewImg.map((item) => {
            return getPresignedUrlS3(
              'image',
              `uploads/output/images`,
              setFileNameS3(item.name),
              'post'
            );
          });
          Promise.all(promiseList)
            .then((responses) => {
              responses.forEach((element) => {
                if (element.success) {
                  listLinkS3Img.push(element.data.file_path);
                  listLinkPreSignedImg.push(element.data.pre_signed);
                }
              });
              const promiseGenerate = listLinkPreSignedImg.map((m, i) => {
                return uploadS3ByPresigned(m, checkNewImg[i], setPercentImg, percentImg, i);
              });
              Promise.all(promiseGenerate).then(() => {
                newData = {
                  ...newData,
                  images: listLinkS3Img,
                };
                resolve();
              });
            })
            .catch((_errors) => {
              reject();
              setPopupLoading(false);
              setOpenProgress(false);
              console.log(_errors);
            });
        })
      : (newData = { ...newData, images: listLinkS3Img });
    await Promise.all([
      checkPostVideoPromise,
      checkPostTrailerPromise,
      checkPostThumbnailPromise,
      checkPostListImgPromise,
    ])
      .then(() => {
        dispatch(
          creatorEditArticle(
            detailPost.id,
            newData,
            2,
            handleShowPopupComfirmSuccess,
            handleShowPopupComfirmFail
          )
        );
      })
      .catch((error) => {
        console.log(error);
        setPopupLoading(false);
        setOpenProgress(false);
      });
  };

  const handleShowPopupComfirmSuccess = (data) => {
    dispatch(updatePostMyPageWhenUpdate(data, true));
    setPopupLoading(false);
    setOpenProgress(false);
    navigate(`${newPathUser}post/${data?.id}`, {
      state: { isEditHomePage: location.state?.isEditHomePage },
    });
  };

  const handleChoiceMethod = (method) => {
    if (method === 1) {
      setIsChoicePrice((prev) => !prev);
    } else {
      setIsChoicePremium((prev) => !prev);
    }
  };

  const onProductContent1 = (checked) => {
    if (isPostHaveBy) {
      if (isCheckOnlyCreator) {
        setIsVisiblePopUpConfirmChangeCreator(true);
      } else {
        setCheckIsOnlyCreator((prev) => !prev);
      }
    } else {
      setCheckIsOnlyCreator((prev) => !prev);
    }
    setProductContent1(!productContent1);
    if (checked) {
      setIsCheckPackagePaid(false);
    }
  };

  const onProductContent2 = (checked) => {
    setProductContent2(!productContent2);
    if (checked) {
      setIsCheckPackagePaid(true);
      setValue('paid_package_radio', 1);
    } else {
      setIsCheckPackagePaid(false);
      setValue('paid_package_radio', 2);
    }
  };

  const onProductContent3 = (checked) => {
    setProductContent3(!productContent3);
    if (checked) {
      setIsCheckTimeSell(true);
      setValue('time_sell_radio', 1);
    } else {
      setIsCheckTimeSell(false);
      setValue('time_sell_radio', 2);
    }
  };

  const onProductContent4 = (checked) => {
    if (dataProfile?.status !== 1) {
      setIsShowPopupConfirm(true);
    } else {
      setProductContent4(!productContent4);
    }
  };

  const onProductContent5 = (checked) => {
    if(checked) {
      setProductContent2(false);
    }
    setProductContent5(!productContent5);
  };

  const onProductContent6 = (checked) => {
    setProductContent6(!productContent6);
  };

  const onProductContent7 = (checked) => {
    setProductContent7(!productContent7);
  };

  const inputImage = () => {
    const len = typeof videoUpload?.img === 'string' ? 1 : videoUpload?.img?.length;
    const isDisable =
      len + checkListImg?.img?.length >= 10 || typePost === TYPE_SHORT || !isPostRejectByAdmin;
    return (
      <div
        className={`inputFile ${isDisable && 'disableImgInput'}`}
        onClick={() => refUploadVideo?.current?.click()}>
        <div className='btn-upload'>
          <UploadImageIconV2 />
          <span>{t('Creator.PostArticle.imageUpload')}</span>
        </div>
        <input
          ref={refUploadVideo}
          style={{ display: 'none' }}
          type='file'
          multiple='multiple'
          accept={acceptedImgVideoTypes.map((item) => item).join(',')}
          onChange={handleInputImg}
          onClick={(e) => (e.target.value = null)}
          disabled={isDisable}
        />
      </div>
    );
  };

  const handleInputImg = async (e) => {
    let sizeImgBiggerTenMb = false;
    let sizeVideoBiggerFiveGB = false;
    let checkImg = true;
    let video = null;
    let listImages = [];
    let checkVideos = true;
    let lengthVideoUpload = 0;
    let lentVideoUploadPublicFlag = videoUploadPublicFlag0?.img ? 1 : 0;
    const checkLength =
      lentVideoUploadPublicFlag +
      checkListImg?.img?.length +
      checkListImgPublicFlag0?.img?.length +
      e.target.files.length;
    for (var i = 0; i < e.target.files.length; i++) {
      if (acceptedImageTypes.includes(e.target.files[i]['type'])) {
        if (e.target.files[i].size > TEN_MB) {
          sizeImgBiggerTenMb = true;
        }
        listImages.push(e.target.files[i]);
      } else if (TYPE_VIDEOS.includes(e.target.files[i]['type'])) {
        video = e.target.files[i];
        if (e.target.files[i].size > TEN_GB) {
          sizeVideoBiggerFiveGB = true;
        }
        lengthVideoUpload += 1;
      } else {
        checkImg = false;
      }
    }
    if (
      lengthVideoUpload > 1 ||
      ((videoUpload?.img.length > 0 || videoUploadPublicFlag0?.img.length > 0) &&
        lengthVideoUpload > 0)
    ) {
      checkVideos = false;
    }

    if (
      e.target.files[0] &&
      checkImg &&
      !sizeImgBiggerTenMb &&
      !sizeVideoBiggerFiveGB &&
      checkLength <= 10 &&
      checkVideos
    ) {
      setIsChangeDataImage(true);
      setCheckListImg((prev) => ({ ...prev, img: [...checkListImg.img, ...listImages] }));
      if (video) {
        const mediaInfoLib = await getInfo(e.target.files[0]);
        const durationVideo = +mediaInfoLib?.media?.track[0]?.Duration;
        if (productContent4) {
          if (durationVideo < 3 * 60) {
            setVideoUpload({ img: [], files: null });
            setDurationVideo(0);
            failVideoLonger3Minutes();
            return true;
          }
        }
        setVideoUpload({ files: URL.createObjectURL(video), img: [video] });
        setDurationVideo(durationVideo);
      }
      setTypePost(TYPE_NORMAL);
    } else {
      !checkVideos && failMoreThan1Video();
      sizeVideoBiggerFiveGB && failFileThan10Gb();
      sizeImgBiggerTenMb && failImgThan10Mb();
      checkLength > 10 && failImgThan10();
      !checkImg && failImgInvalid();
    }
  };

  const inputVideo = () => {
    const len = typeof videoUpload?.img === 'string' ? 1 : videoUpload?.img?.length;
    const isDisable = len > 0 || imgUpload?.img?.length > 10 || !isPostRejectByAdmin;
    return (
      <div
        className={`inputFile ${isDisable && 'disableVideoInput'}`}
        onClick={() => refUploadImage?.current?.click()}>
        <div className='btn-upload'>
          <UploadImageIconV2 />
          <span>{t('Creator.PostArticle.imageUpload')}</span>
        </div>
        <input
          ref={refUploadImage}
          style={{ display: 'none' }}
          type='file'
          multiple='multiple'
          accept={acceptedImgVideoTypes.map((item) => item).join(',')}
          onChange={handleInputVideo}
          disabled={isDisable}
          onClick={(e) => (e.target.value = null)}
        />
      </div>
    );
  };

  const handleInputVideo = async (e) => {
    let videoSelect = false;
    let imageSelect = false;
    const targetFiles = e.target.files;
    let targetFilesObject = [...targetFiles];
    if (
      targetFilesObject.length + checkListImg.length > 10 ||
      targetFilesObject.length + videoUpload.length > 10
    ) {
      return pushNotify('error', `${t('ValidateMsg.uploadThan10Img')}`);
    }

    /**
     * check img vs video
     */
    targetFilesObject?.map((file) => {
      const isImage = file.type.includes('image');
      const isVideo = file.type.includes('video');
      console.log('isImage', isImage);
      const maxSize = isImage ? TEN_MB : TEN_GB;
      const fileType = isImage ? acceptedImageTypes : TYPE_VIDEOS;
      if (+file.size > +maxSize) {
        return pushNotify(
          'error',
          isImage ? `${t('ValidateMsg.imgThan10Mb1')}` : `${t('ValidateMsg.videoThan1Gb1')}`
        );
      } else if (isImage && !fileType.includes(file.type)) {
        return pushNotify('error', `${t('ValidateMsg.typeImg1')}`);
      } else if (isVideo && !fileType.includes(file.type)) {
        return pushNotify('error', `${t('ValidateMsg.typeVideo1')}`);
      } else if (!isVideo && !isImage) {
        pushNotify('error', `${t('ValidateMsg.typeImg1')}`);
        return pushNotify('error', `${t('ValidateMsg.typeVideo1')}`);
      } else if (
        (checkListImg?.img.length > 0 && isVideo) ||
        (videoUpload?.img.length > 0 && isImage)
      ) {
        return pushNotify('error', `${t('ValidateMsg.ImgAndVideoRequired')}`);
      } else if (isImage) {
        return (imageSelect = true);
      } else {
        return (videoSelect = true);
      }
    });
    const checkLengthVideo = videoUpload?.img.length + e?.target?.files?.length;
    if (videoSelect === true && (checkLengthVideo > 1 || e?.target?.files?.length > 1)) {
      return pushNotify('error', `${t('ValidateMsg.uploadThan1Video')}`);
    }

    if (imageSelect && videoSelect) {
      return pushNotify('error', `${t('ValidateMsg.ImgAndVideoRequired')}`);
    } else if (imageSelect && !videoSelect) {
      let sizeImgBiggerTenMb = false;
      let checkImg = true;
      const checkLength = checkListImg?.img?.length + e?.target.files.length;
      const newArrImg = [];
      for (var i = 0; i < e?.target.files.length; i++) {
        if (e?.target.files[i].size > TEN_MB) sizeImgBiggerTenMb = true;
        if (!acceptedImageTypes.includes(e?.target.files[i]['type'])) checkImg = false;
        newArrImg.push(URL.createObjectURL(e?.target.files[i]));
      }
      if (e?.target.files[0] && checkImg && !sizeImgBiggerTenMb && checkLength <= 10) {
        setIsChangeDataImage(true);
        setCheckListImg((prev) => ({
          ...prev,
          files: [...checkListImg.files, ...newArrImg],
          img: [...checkListImg.img, ...e?.target.files],
        }));
        setTypePost(TYPE_SHORT);
      } else {
        sizeImgBiggerTenMb && failImgThan10Mb();
        checkLength > 10 && failImgThan10();
        !checkImg && failImgInvalid();
      }
    } else if (!imageSelect && videoSelect) {
      let sizeImgBiggerTenGB = false;
      sizeImgBiggerTenGB = e?.target.files[0].size > TEN_GB ? true : false;
      const checkTypes = e?.target.files[0]['type'].includes('video');
      const checkLength = videoUpload?.img.length + e?.target.files.length;
      if (e?.target.files[0]['type'] === 'video/x-ms-wmv') {
        dispatch(
          postDurationVideo(e?.target.files[0], ({ data }) => {
            if (checkTypes && !sizeImgBiggerTenGB && checkLength <= 1) {
              if (data <= 60) {
                setDurationVideo(data);
                setIsChangeDataImage(true);
                setVideoUpload({
                  files: URL.createObjectURL(e?.target.files[0]),
                  img: e?.target.files,
                });
                setTypePost(TYPE_SHORT);
              } else {
                failShortVideo();
              }
            } else {
              sizeImgBiggerTenGB && failFileThan10Gb();
              !checkTypes && failVideoInvalid();
              checkLength > 1 && failVideoThan1();
            }
          })
        );
      } else {
        getDurationVideo(e?.target.files[0], (duration) => {
          if (duration <= 60) {
            if (checkTypes && !sizeImgBiggerTenGB && checkLength <= 1) {
              setIsChangeDataImage(true);
              setVideoUpload({
                files: URL.createObjectURL(e?.target.files[0]),
                img: e?.target.files,
              });
              setDurationVideo(duration);
              setTypePost(TYPE_SHORT);
            } else {
              sizeImgBiggerTenGB && failFileThan10Gb();
              !checkTypes && failVideoInvalid();
              checkLength > 1 && failVideoThan1();
            }
          } else {
            failShortVideo();
          }
        });
      }
    }
  };

  return (
    <>
      {openProgress && (
        <div className='layer-spinner'>
          <ProcessingStatusBar percentage={pcUpload} />
        </div>
      )}
      {popupLoading && (
        <div className='layer-spinner'>
          <LoadingIconV2 className='spinner-icon' />
        </div>
      )}
      {isShowPerformerPopup && (
        <PerformerPopup
          listPerformer={listPerformer}
          setListPerformer={setListPerformer}
          title={t('Contract.createContract')}
          handleClosePopup={() => setIsShowPerformerPopup(false)}
        />
      )}
      {isShowPopupConfirm && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsShowPopupConfirm(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Creator.Message.featureNotAvailableSale')}
          className={'popup-confirm__with-draw'}>
          <div
            className='btn confirm'
            onClick={() => {
              navigate(`/profile/info`, {
                state: {
                  redirectBlockRequest: true,
                },
              });
            }}>
            {t('Common.update')}
          </div>
          <div className='btn cancel' onClick={() => setIsShowPopupConfirm(false)}>
            {t('WithDraw.cancel')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpConfirm1 && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopUpConfirm1(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.confirmCreatePost')}>
          <div className='ok btn' onClick={handleConfirmSubmit}>
            {t('Common.buttonYes')}
          </div>
          <div className='btn cancel' onClick={() => setIsVisiblePopUpConfirm1(false)}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpConfirm2 && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopUpConfirm2(e)}
          colorSvg='#28C76F'
          iconNote={false}
          text={t('Popup.postCompleted')}>
          <div
            className='ok btn'
            onClick={() => {
              setIsVisiblePopUpConfirm2(false);
              navigate(`${newPathUser}post-sale/${detailPost?.id}`, {
                state: {
                  isEditHomePage: location.state?.isEditHomePage,
                  account_id: location?.state?.account_id,
                },
              });
            }}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpConfirmChangeCreator && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopUpConfirmChangeCreator(e)}
          iconNote={true}
          colorSvg='#FFBF00'
          fillColor={true}
          text={t('Popup.post_only_creator')}>
          <div
            className='ok btn'
            onClick={(e) => {
              e.stopPropagation();
              setCheckIsOnlyCreator(false);
              setIsVisiblePopUpConfirmChangeCreator(false);
            }}>
            {t('Common.Ok')}
          </div>
          <div
            className='btn cancel'
            onClick={(e) => {
              e.stopPropagation();
              setIsVisiblePopUpConfirmChangeCreator(false);
            }}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}

      <LayoutCreatorRight
        classContainer='post-content-page-article'
        className='edit-post-content-header'
        titlePage={t('Creator.PostArticle.postUpdate')}>
        <div className='fansite-creator-post-content edit'>
          {(loading || loadingListPackage) && !detailPost ? (
            <div style={{ padding: `${isMobile ? '16px' : '24px'}` }}>
              <ShimmerPostDetails className='shimmer-loading' />
            </div>
          ) : (
            <form onSubmit={(e) => e.preventDefault()} className='fansite-form form-edit'>
              {isApproved && (
                <div className='box-img-video' style={{ marginTop: '0' }}>
                  <div className='list-img-video'>
                    <div
                      className={`list-item scroll-bar-custom-horizontal list-imgs ${
                        videoUpload?.img?.length > 0 && 'has-video'
                      }`}>
                      {detailPost?.type !== 4 && videoUploadPublicFlag0?.files ? (
                        <div
                          className={`box-item ${
                            checkListImg.img?.length > 0 ? 'box-img2' : 'box-img1'
                          }`}>
                          <ImgCustom src={IMG_NO_WATER_MARK} />
                        </div>
                      ) : (
                        <>
                          {videoUpload?.img?.length > 0 && typePost === TYPE_NORMAL && (
                            <>
                              {videoUpload?.files?.indexOf('.m3u8') === -1 && (
                                <div
                                  className={`box-item box-video1 ${
                                    checkListImg.files.length > 0 ? '' : 'self-video'
                                  }`}>
                                  <Player poster=''>
                                    <source
                                      src={
                                        typeof videoUpload?.files === 'string' &&
                                        typeof videoUpload?.img === 'string'
                                          ? `${linkS3Video}${decryptPath(videoUpload?.files)}`
                                          : videoUpload?.files
                                      }
                                    />
                                    <ControlBar>
                                      <CurrentTimeDisplay order={4.1} />
                                      <TimeDivider order={4.2} />
                                      <VolumeMenuButton />
                                    </ControlBar>
                                  </Player>
                                  {isPostRejectByAdmin && (
                                    <CloseIconV2
                                      onClick={() => {
                                        setIsChangeDataImage(true);
                                        setVideoUpload({ img: [], files: null });
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {(checkListImg.files.length > 0 || checkListImg.img.length > 0) &&
                        checkListImg.files.map((item, index) => {
                          return (
                            <>
                              <div
                                key={index}
                                className={`box-item ${
                                  checkListImg.img?.length > 1 ? 'box-img2' : 'box-img1'
                                }`}>
                                <ImgCustom
                                  src={
                                    typeof item === 'string' &&
                                    typeof checkListImg.img[index] === 'string'
                                      ? `${item}`
                                      : item
                                  }
                                  alt=''
                                />
                                {isPostRejectByAdmin && (
                                  <CloseIconV2
                                    onClick={() => {
                                      setIsChangeDataImage(true);
                                      handleRemoveImg(index, item);
                                    }}
                                  />
                                )}
                              </div>
                            </>
                          );
                        })}
                      {(checkListImgPublicFlag0.files.length > 0 ||
                        checkListImgPublicFlag0.img.length > 0) &&
                        checkListImgPublicFlag0.files.map((item, index) => {
                          return (
                            <>
                              <div
                                className={`box-item ${
                                  checkListImg.img?.length > 0 ? 'box-img2' : 'box-img1'
                                }`}>
                                <ImgCustom src={IMG_NO_WATER_MARK} />
                              </div>
                            </>
                          );
                        })}
                    </div>

                    <div className={`list-item list-videos`} style={{ marginBottom: '17px' }}>
                      {typePost === TYPE_SHORT && (
                        <>
                          {(videoUpload?.files || videoUpload?.img.length > 0) && (
                            <>
                              {videoUpload?.files?.indexOf('.m3u8') === -1 && (
                                <div className={`box-item box-video1 self-video`}>
                                  <VideoDetail
                                    className='video-item'
                                    src={
                                      typeof videoUpload?.files === 'string' &&
                                      typeof videoUpload?.img === 'string'
                                        ? `${linkS3Video}${decryptPath(videoUpload?.files)}`
                                        : videoUpload?.files
                                    }
                                  />
                                  {isPostRejectByAdmin && (
                                    <CloseIconV2
                                      onClick={() => {
                                        setIsChangeDataImage(true);
                                        setVideoUpload({ img: [], files: null });
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                            </>
                          )}
                          {videoUpload?.img === 0 && (
                            <div className={`box-item box-img1 435345`}>
                              <ImgCustom src={IMG_NO_WATER_MARK} />
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    <div className='box-btn-upload d-flex align-items-center'>
                      {typePost !== TYPE_SHORT ? inputImage() : inputVideo()}
                    </div>
                    {!isHaveImgOrVideo && !watchContent && detailPost?.type === 2 && (
                      <div className='text-err 2'>
                        {t('Creator.PostArticle.postShort.noImgWhenOnlyFollow')}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {isShowErrorPackagesNormal && detailPost?.type === 1 && (
                <div className='text-err 3'>
                  {t('Creator.PostArticle.postShort.noImgWhenOnlyFollow')}
                </div>
              )}
              {isShowErrorPackages && (
                <div className='text-err 4'>
                  {t('Creator.PostArticle.postShort.noImgWhenOnlyFollow')}
                </div>
              )}
              {detailPost?.type === 1 && detailPost?.share_type === 2 && !hiddenQuote && (
                <>
                  {detailPost?.share_post ? (
                    <div
                      style={{
                        cursor: 'pointer',
                        position: 'relative',
                        marginBottom: '10px',
                      }}
                      className={`block-repost-no-img`}
                      onClick={() =>
                        navigate(`${newPathUser}post/${detailPost?.share_post?.id}`)
                      }>
                      <div className='d-flex' style={{ gap: '12px' }}>
                        {detailPost?.share_post?.avatar ? (
                          <ImgCustom
                            onClick={() =>
                              navigate(`${newPathUser}${detailPost?.share_post?.account_id}`)
                            }
                            style={{
                              minWidth: 44,
                              maxWidth: 44,
                              minHeight: 44,
                              maxHeight: 44,
                              borderRadius: '50%',
                              objectFit: 'cover',
                              cursor: 'pointer',
                            }}
                            className='cls-img'
                            screen='avatar_44_44'
                            src={detailPost?.share_post?.avatar}
                          />
                        ) : (
                          <img src={AVATAR_DEFAULT} alt='AVATAR_DEFAULT' />
                        )}
                        <div className='wrap--text'>
                          <p className='mb-0 cls-title fw-bold'>
                            {detailPost?.share_post?.account_name || 'no account name'}
                          </p>
                          <div
                            className='d-flex align-items-center'
                            style={{ fontSize: '12px' }}>
                            <p
                              className='cls-title-2nd cls-date'
                              style={{ color: '#666565', marginTop: '0px' }}>
                              {convertToCurrentTime(
                                detailPost?.share_post?.public_time ||
                                  detailPost?.share_post?.created_at,
                                'YYYY/MM/DD HH:mm'
                              )}
                            </p>
                            &nbsp;
                            {detailPost?.share_post?.unpublic_time && (
                              <>
                                <p
                                  className='cls-title-2nd cls-date ms-1'
                                  style={{
                                    color: '#DC143C',
                                    marginTop: '0px',
                                    border: '1px solid',
                                    padding: '0px 5px',
                                  }}>
                                  <span className='me-1'>{t('Common.publicDay')}</span>
                                  {convertToCurrentTime(
                                    detailPost?.share_post?.unpublic_time,
                                    'YYYY/MM/DD HH:mm'
                                  )}
                                </p>
                              </>
                            )}
                            {detailPost?.share_post?.in_24h_flag === 1 && (
                              <>
                                <p
                                  className='cls-title-2nd cls-date ms-1'
                                  style={{
                                    color: '#DC143C',
                                    marginTop: '0px',
                                    border: '1px solid',
                                    padding: '0px 5px',
                                  }}>
                                  <span className='me-1'>{t('Common.publicDay')}</span>
                                  {convertToCurrentTime(
                                    renderTimeAfter24h(detailPost?.share_post?.public_time),
                                    'YYYY/MM/DD HH:mm'
                                  )}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className='mt-3 block-img1'
                        style={{
                          whiteSpace: 'pre-wrap',
                          margin: '0',
                          fontWeight: '400',
                        }}>
                        <MarkDownRead
                          content={detailPost?.share_post?.content || ''}
                          charLimit={isMobile ? 90 : 160}
                        />
                      </div>
                      <div
                        className='mt-3 block-img1'
                        style={{
                          whiteSpace: 'pre-wrap',
                          margin: '0',
                          fontWeight: '400',
                        }}>
                        {`${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${
                          detailPost?.share_post?.id
                        }`}
                      </div>
                      <div style={{ position: 'absolute', right: 0, top: '5px' }}>
                        {isPostRejectByAdmin && (
                          <CloseIconV2
                            onClick={(e) => {
                              e.stopPropagation();
                              setHiddenQuote(true);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className={`block-repost-no-img`}>{t('Common.unavailable')}</div>
                  )}
                </>
              )}
              <>
                {detailPost?.type === 2 && (
                  <InputWrapper
                    id='fsite-content'
                    label={t('Creator.PostArticle.postContent.content')}
                    required={true}
                    error={errors?.content ? errors?.content?.message : ''}>
                    <div
                      className={`wrapper-content-ogp ${
                        postDetailMeta?.title ? 'have-meta' : ''
                      }`}>
                      <Textarea
                        {...register('content')}
                        placeholder={t('Creator.PostArticle.postContent.placeholderContent')}
                        maxLength={500}
                        autoSize
                        className='area-l-height'
                        value={content}
                        onInput={(e) => {
                          if (e.target.value) {
                            clearErrors('content');
                            if (e.target.value.length >= 500) {
                              setError('content', {
                                type: 'maxLength',
                                message: t('ValidateMsg.CONTENT_IS_TOO_LONG'),
                              });
                            } else clearErrors('content');
                            if (
                              !patternNoFirstSpacing.test(e.target.value?.toString()?.trim())
                            ) {
                              setError('content', {
                                type: 'firstSpace',
                                message: t('ValidateMsg.contentRequired'),
                              });
                            } else clearErrors('content');
                          } else {
                            setError('content', {
                              type: 'required',
                              message: t('ValidateMsg.itemRequired'),
                            });
                          }
                        }}
                        onChange={(e) => {
                          setContent(e.target.value);
                          setValue('content', e.target.value);
                        }}
                      />
                      {postDetailMeta?.title && (
                        <MetaTagViewer
                          title={postDetailMeta?.title}
                          url={postDetailMeta?.url}
                          thumbnail={postDetailMeta?.thumbnail}
                        />
                      )}
                    </div>
                  </InputWrapper>
                )}
                {detailPost?.type !== 2 && (
                  <InputWrapper
                    id='fsite-content'
                    label={t('Creator.PostArticle.postContent.content')}
                    error={errors?.content ? errors?.content?.message : ''}>
                    <div
                      className={`wrapper-content-ogp ${
                        postDetailMeta?.title ? 'have-meta' : ''
                      }`}>
                      <Textarea
                        {...register('content')}
                        placeholder={t('Creator.PostArticle.postContent.placeholderContent')}
                        maxLength={500}
                        autoSize
                        className='area-l-height'
                        value={content}
                        onInput={(e) => {
                          if (e.target.value) {
                            clearErrors('content');
                            if (e.target.value.length >= 500) {
                              setError('content', {
                                type: 'maxLength',
                                message: t('ValidateMsg.CONTENT_IS_TOO_LONG'),
                              });
                            } else clearErrors('content');
                          }
                        }}
                        onChange={(e) => {
                          setContent(e.target.value);
                          setValue('content', e.target.value);
                        }}
                      />
                      {postDetailMeta?.title && (
                        <MetaTagViewer
                          title={postDetailMeta?.title}
                          url={postDetailMeta?.url}
                          thumbnail={postDetailMeta?.thumbnail}
                        />
                      )}
                    </div>
                  </InputWrapper>
                )}
                {isApproved && (
                  <>
                    <InputWrapper
                      className='group-list-performer'
                      label={t('Creator.PostArticle.postContent.listPerformer')}
                      required></InputWrapper>
                    {listPerformer?.length > 0 && (
                      <div className='list-performer'>
                        {listPerformer?.map((item) => {
                          return (
                            <div
                              key={item?.id}
                              id={item?.id}
                              className='item-performer'>{`${item.last_name_romaji} ${item.first_name_romaji}`}</div>
                          );
                        })}
                      </div>
                    )}

                    <div
                      className={`btn-performer ${
                        !isPostRejectByAdmin ? 'post-approved' : ''
                      }`}
                      onClick={() => {
                        if (isPostRejectByAdmin) {
                          setIsShowPerformerPopup(true);
                        }
                      }}>
                      <PerformerIcon
                        className='performer-icon'
                        color={!isPostRejectByAdmin && '#b1b6bb'}
                      />
                      {t('Creator.PostArticle.postContent.PerformerInformation')}
                    </div>
                  </>
                )}
              </>
              {!isApproved && (
                <div className='d-flex justify-content-between mt-4'>
                  <label className={`fw-bold`}>{t('Creator.PostArticle.canReply')}</label>
                  <ToggleButton
                    id='message_notification6'
                    checked={productContent6}
                    onChange={onProductContent6}
                  />
                </div>
              )}
              {isApproved && detailPost?.type !== 2 && (
                <>
                  <div className='d-flex justify-content-between mt-4'>
                    <label className={`fw-bold`}>
                      {t('Creator.PostArticle.postShort.userFollow')}
                    </label>
                    <ToggleButton
                      id='message_notification5'
                      checked={productContent5}
                      onChange={onProductContent5}
                      disabled={!isHaveImgOrVideo}
                    />
                  </div>
                  <div className='d-flex justify-content-between mt-4'>
                    <label className={`fw-bold`}>{t('Creator.PostArticle.canReply')}</label>
                    <ToggleButton
                      id='message_notification6'
                      checked={productContent6}
                      onChange={onProductContent6}
                    />
                  </div>
                  {/* <div className='d-flex justify-content-between mt-4'>
                    <label className={`fw-bold`}>{t('Creator.PostArticle.postIn24h')}</label>
                    <ToggleButton
                      disabled
                      id='message_notification7'
                      checked={productContent7}
                      onChange={onProductContent7}
                    />
                  </div> */}
                </>
              )}
              {isApproved &&
                ((detailPost?.type === 1 && !productContent5) || detailPost?.type === 2) && (
                  <div className='d-flex justify-content-between mt-4'>
                    <label className={`fw-bold`}>
                      {t('Creator.PostArticle.postContent.includePlan')}
                    </label>
                    <ToggleButton
                      disabled={isCheckOnlyCreator && productContent4}
                      id='message_notification2'
                      checked={!!productContent2 ? true : false}
                      onChange={onProductContent2}
                    />
                  </div>
                )}
              {productContent2 && !productContent1 && (
                <InputWrapper
                  className={`box-category mt-2 ${
                    productContent2 && !listChecked.length > 0 && 'add-error-message'
                  }`}>
                  <div className='box-checkbox scroll-bar-custom list-package'>
                    {dataCheckBox.length > 0 ? (
                      <>
                        {/* check all */}
                        <Checkbox
                          register={{ ...register('all_package') }}
                          type='checkbox'
                          onChange={(e) => handleAllPlanPost(e)}
                          checked={listChecked?.length === dataCheckBox?.length}
                          text={t('Common.allPlan')}
                        />
                        {dataCheckBox.map((item, index) => (
                          <Controller
                            name={`package_ids[${index}]`}
                            control={control}
                            key={index}
                            render={() => {
                              return (
                                <Checkbox
                                  id={item.id}
                                  type='checkbox'
                                  register={{ ...register(`package_ids[${index}]`) }}
                                  onChange={(e) => handleOnchangePlanPost(e, item)}
                                  text={item.title}
                                  checked={item.checked}
                                />
                              );
                            }}
                          />
                        ))}
                      </>
                    ) : (
                      <span style={{ color: '#ff0000' }}>{t('Common.doesNotPackage')}</span>
                    )}
                  </div>
                  {isCheckPackagePaid && !listChecked.length > 0 && (
                    <div
                      className='error-message-custom'
                      style={{
                        color: '#ff0000',
                      }}>
                      {t('ValidateMsg.selectPlanRequired')}
                    </div>
                  )}
                </InputWrapper>
              )}
              {isApproved && (
                <div className='d-flex justify-content-between mt-4'>
                  <label className='fw-bold'>
                    {t('Creator.PostArticle.postContent.settingSalesPeriod')}
                  </label>
                  <ToggleButton
                    id='message_notification3'
                    checked={!!productContent3 ? true : false}
                    onChange={onProductContent3}
                  />
                </div>
              )}
              {!productContent4 && productContent3 && (
                <div
                  className='text-creator-info'
                  style={{ marginBottom: `${productContent3 && '15px'}` }}>
                  {t('Creator.PostArticle.postContent.notestartend')}
                </div>
              )}

              {isCheckTimeSell && !productContent4 && (
                <div
                  className='d-flex align-items-center d-sm-block '
                  style={{ marginBottom: `${detailPost?.type === 1 && '40px'}` }}>
                  <div className={`box-time-sell`}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        flex: 1,
                      }}>
                      <InputWrapper
                        required={true}
                        error={errors?.startTime ? errors?.startTime?.message : ''}>
                        <div className='start-time'>
                          <CalendarIconV2
                            onClick={() => {
                              if (!disableStartTime) {
                                if (isVisibleCalendar2) {
                                  toggleCalendar2();
                                }
                                toggleCalendar1();
                              }
                            }}
                            color={isVisibleCalendar1 && '#DC143C'}
                          />
                          {(date1 || watchPublickTime) && (
                            <CloseIcon
                              style={{ right: '35px' }}
                              onClick={() => {
                                onChangeDate1(null);
                                setValue('public_time', null);
                                setTimePost(null);
                              }}
                            />
                          )}
                          <input
                            placeholder='YYYY/MM/DD'
                            {...register('public_time')}
                            value={date1 ?? undefined}
                            readOnly
                            onClick={() => {
                              if (isVisibleCalendar2) {
                                toggleCalendar2();
                              }
                              toggleCalendar1();
                            }}
                          />
                          {isVisibleCalendar1 && (
                            <ReactCalendar
                              minDate={new Date()}
                              onChange={(e) => {
                                onChangeDate1(e);
                                setIsLoadingFirst(false);
                                setIsChangeDateTimePublic(false);
                                setIsChangeTime1(true);
                              }}
                              defaultActiveStartDate={startDate && moment(startDate).toDate()}
                              defaultValue={startDate && moment(startDate).toDate()}
                              className='react-calendar-C081_1'
                              toggleCalendar={toggleCalendar1}
                            />
                          )}
                          {/* {isCheckTimeSell &&
                            !date1 &&
                            !watchPublickTime &&
                            textValidateRequired2()} */}
                        </div>
                        <div style={{ position: 'relative' }}>
                          <CustomSelectTime
                            placeholder='--:--'
                            timePost={(e) => {
                              setIsLoadingFirst(false);
                              setIsChangeTime1(true);
                              setTimePost(e);
                              setTimePostCheck(e);
                              setIsChangeDateTimePublic(false);
                            }}
                            handleOnClick={() => {
                              if (isVisibleCalendar1) {
                                toggleCalendar1();
                              }
                              if (isVisibleCalendar2) {
                                toggleCalendar2();
                              }
                            }}
                            initTime={timePost ?? null}
                            checkDisabled={true}
                            showIconV2
                            colorDefaultIcon='#BEBEBE'
                          />
                        </div>
                        {isCheckTimeSell &&
                          date1 &&
                          !timePost &&
                          textValidateRequired2({
                            left: 'unset',
                            right: '0',
                          })}
                        <span className='d-md-inline-flex'>〜</span>
                      </InputWrapper>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        marginTop: `${isMobile ? '20px' : '0'}`,
                      }}>
                      <InputWrapper
                        required={true}
                        error={errors?.endTime ? errors?.endTime?.message : ''}>
                        <div className='end-time'>
                          <CalendarIconV2
                            onClick={() => {
                              if (isVisibleCalendar1) {
                                toggleCalendar1();
                              }
                              toggleCalendar2();
                            }}
                            color={isVisibleCalendar2 && '#DC143C'}
                          />
                          {(date2 || watchUnPublickTime) && (
                            <CloseIcon
                              style={{ right: '35px' }}
                              onClick={() => {
                                onChangeDate2(null);
                                setValue('unpublic_time', null);
                                setTimeRelease(null);
                              }}
                            />
                          )}
                          <input
                            disabled={detailPost?.in_24h_flag === 1}
                            placeholder='YYYY/MM/DD'
                            {...register('unpublic_time')}
                            value={date2}
                            readOnly
                            onClick={() => {
                              if (isVisibleCalendar1) {
                                toggleCalendar1();
                              }
                              toggleCalendar2();
                            }}
                          />
                          {isVisibleCalendar2 && (
                            <ReactCalendar
                              minDate={new Date()}
                              onChange={(e) => {
                                setIsLoadingFirst(false);
                                onChangeDate2(e);
                                setIsChangeDateTime(false);
                              }}
                              customPosition='bottom-right'
                              defaultActiveStartDate={endDate && moment(endDate).toDate()}
                              defaultValue={endDate && moment(endDate).toDate()}
                              className='react-calendar-C081_1'
                              toggleCalendar={toggleCalendar2}
                            />
                          )}
                          {/* {
                            isCheckTimeSell &&
                            !date2 &&
                            !watchUnPublickTime &&
                            textValidateRequired2()} */}
                        </div>
                        <div style={{ position: 'relative' }}>
                          <CustomSelectTime
                            disabledInput={detailPost?.in_24h_flag === 1}
                            placeholder='--:--'
                            scrollToBottom={true}
                            timePost={(e) => {
                              setIsLoadingFirst(false);
                              setTimeRelease(e);
                              setIsChangeDateTime(false);
                              setTimeReleaseCheck(e);
                            }}
                            handleOnClick={() => {
                              if (isVisibleCalendar1) {
                                toggleCalendar1();
                              }
                              if (isVisibleCalendar2) {
                                toggleCalendar2();
                              }
                            }}
                            checkDisabled={true}
                            initTime={timeRelease}
                            classNameCustom='top'
                            showIconV2
                            colorDefaultIcon='#BEBEBE'
                          />
                        </div>
                        {isCheckTimeSell &&
                          date2 &&
                          !timeRelease &&
                          textValidateRequired2({
                            left: 'unset',
                            right: '0',
                          })}
                      </InputWrapper>
                    </div>
                  </div>
                </div>
              )}

              {isCheckTimeSell && productContent4 && (
                <div
                  className='d-flex align-items-center d-sm-block '
                  style={{ marginBottom: `${detailPost?.type === 1 && '40px'}` }}>
                  <div className={`box-time-sell`}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        flex: 1,
                      }}>
                      <InputWrapper
                        required={true}
                        error={errors?.startTime ? errors?.startTime?.message : ''}>
                        <div className='start-time'>
                          <CalendarIconV2
                            onClick={() => {
                              if (!disableStartTime) {
                                if (isVisibleCalendar2) {
                                  toggleCalendar2();
                                }
                                toggleCalendar1();
                              }
                            }}
                            color={isVisibleCalendar1 && '#DC143C'}
                          />
                          {(date1 || watchPublickTime) && (
                            <CloseIcon
                              style={{ right: '35px' }}
                              onClick={() => {
                                onChangeDate1(null);
                                setValue('public_time', null);
                                setTimePost(null);
                              }}
                            />
                          )}
                          <input
                            placeholder='YYYY/MM/DD'
                            {...register('public_time')}
                            value={date1}
                            readOnly
                            onClick={() => {
                              if (isVisibleCalendar2) {
                                toggleCalendar2();
                              }
                              toggleCalendar1();
                            }}
                          />
                          {isVisibleCalendar1 && (
                            <ReactCalendar
                              minDate={new Date()}
                              onChange={(e) => {
                                onChangeDate1(e);
                                setIsChangeDateTimePublic(false);
                              }}
                              defaultActiveStartDate={date1 ? date1 && moment(date1).toDate() : startDate && moment(startDate).toDate()}
                              defaultValue={date1 ? date1 && moment(date1).toDate() : startDate && moment(startDate).toDate()}
                              className='react-calendar-C081_1'
                              toggleCalendar={toggleCalendar1}
                            />
                          )}
                          {productContent4 &&
                            !date1 &&
                            !watchPublickTime &&
                            textValidateRequired2()}
                        </div>
                        <div style={{ position: 'relative' }}>
                          <CustomSelectTime
                            placeholder='--:--'
                            timePost={(e) => {
                              setTimePost(e);
                              setTimePostCheck(e);
                              setIsChangeDateTimePublic(false);
                            }}
                            handleOnClick={() => {
                              if (isVisibleCalendar1) {
                                toggleCalendar1();
                              }
                              if (isVisibleCalendar2) {
                                toggleCalendar2();
                              }
                            }}
                            initTime={timePost ?? null}
                            checkDisabled={true}
                            showIconV2
                            colorDefaultIcon='#BEBEBE'
                          />
                        </div>
                        {productContent4 &&
                          date1 &&
                          !timePost &&
                          textValidateRequired2({
                            left: 'unset',
                            right: '0',
                          })}
                        <span className='d-md-inline-flex'>〜</span>
                      </InputWrapper>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        marginTop: `${isMobile ? '20px' : '0'}`,
                      }}>
                      <InputWrapper
                        required={true}
                        error={errors?.endTime ? errors?.endTime?.message : ''}>
                        <div className='end-time'>
                          <CalendarIconV2
                            onClick={() => {
                              if (isVisibleCalendar1) {
                                toggleCalendar1();
                              }
                              toggleCalendar2();
                            }}
                            color={isVisibleCalendar2 && '#DC143C'}
                          />
                          {(date2 || watchUnPublickTime) && (
                            <CloseIcon
                              style={{ right: '35px' }}
                              onClick={() => {
                                onChangeDate2(null);
                                setValue('unpublic_time', null);
                                setTimeRelease(null);
                              }}
                            />
                          )}
                          <input
                            placeholder='YYYY/MM/DD'
                            {...register('unpublic_time')}
                            value={date2}
                            readOnly
                            onClick={() => {
                              if (isVisibleCalendar1) {
                                toggleCalendar1();
                              }
                              toggleCalendar2();
                            }}
                          />
                          {isVisibleCalendar2 && (
                            <ReactCalendar
                              minDate={new Date()}
                              onChange={(e) => {
                                onChangeDate2(e);
                                setIsChangeDateTime(false);
                              }}
                              customPosition='bottom-right'
                              defaultActiveStartDate={date2 ? date2 && moment(date2).toDate() : endDate && moment(endDate).toDate()}
                              defaultValue={date2 ? date2 && moment(date2).toDate() : endDate && moment(endDate).toDate()}
                              className='react-calendar-C081_1'
                              toggleCalendar={toggleCalendar2}
                            />
                          )}
                          {productContent4 &&
                            isCheckTimeSell &&
                            !date2 &&
                            !watchUnPublickTime &&
                            textValidateRequired2()}
                        </div>
                        <div style={{ position: 'relative' }}>
                          <CustomSelectTime
                            placeholder='--:--'
                            timePost={(e) => {
                              setTimeRelease(e);
                              setIsChangeDateTime(false);
                              setTimeReleaseCheck(e);
                            }}
                            handleOnClick={() => {
                              if (isVisibleCalendar1) {
                                toggleCalendar1();
                              }
                              if (isVisibleCalendar2) {
                                toggleCalendar2();
                              }
                            }}
                            checkDisabled={true}
                            initTime={timeRelease}
                            showIconV2
                            colorDefaultIcon='#BEBEBE'
                          />
                        </div>
                        {productContent4 &&
                          isCheckTimeSell &&
                          date2 &&
                          !timeRelease &&
                          textValidateRequired2({
                            left: 'unset',
                            right: '0',
                          })}
                      </InputWrapper>
                    </div>
                  </div>
                </div>
              )}

              {isApproved && detailPost?.type !== 2 && (
                <>
                  <div className='d-flex justify-content-between mt-4'>
                    <label className={`fw-bold`}>{t('Creator.PostArticle.postIn24h')}</label>
                    <ToggleButton
                      disabled
                      id='message_notification7'
                      checked={productContent7}
                      onChange={onProductContent7}
                    />
                  </div>
                </>
              )}
              {(detailPost?.type === 2 || (detailPost?.type === 1 && !isApproved)) && (
                <div
                  className={`d-flex justify-content-between ${
                    (isCheckTimeSell && !date1) ||
                    (isCheckTimeSell && !date2) ||
                    (isCheckTimeSell && date2 && !timeReleaseCheck) ||
                    (isCheckTimeSell && date1 && !timePostCheck)
                      ? 'mt-5'
                      : 'mt-4'
                  }`}>
                  <div className='block-require'>
                    <label className={`fw-bold`}>
                      {t('Creator.PostArticle.postContent.sellingIndividua')}
                    </label>
                  </div>
                  <ToggleButton
                    id='message_notification4'
                    disabled={detailPost?.type === 2}
                    checked={productContent4}
                    onChange={onProductContent4}
                  />
                </div>
              )}

              {detailPost?.type === 2 && (
                <>
                  <ListImgVideoZip
                    trailer_video={handleInputTrailer}
                    setDurationVideoTrailer={setDurationVideoTrailer}
                    initData={detailPost ? detailPost?.medias : []}
                    errorTimeDurationInput={errorTimeDurationInput}
                    setErrorTimeDurationInput={setErrorTimeDurationInput}
                    isErrorDurationInput={isErrorDurationInput}
                    isPostApprovedByAdmin={isPostApprovedByAdmin}
                    isPostRejectByAdmin={isPostRejectByAdmin}
                    isPostWaitApprove={isPostWaitApprove}
                    thumbnail={handleInputThumbnail}
                  />
                  <InputWrapper
                    className='fsite-setting mt-4'
                    id='fsite-setting'
                    label={t('Creator.PostArticle.postContent.productNumberSetting')}
                    error={
                      watchNumberPart
                        ? errors?.number_part
                          ? errors?.number_part?.message
                          : ''
                        : ''
                    }>
                    <div className='input-setting'>
                      <input
                        {...register('number_part', {
                          maxLength: {
                            value: 10,
                            message: t('ValidateMsg.min10Character'),
                          },
                          validate: checkValueNumberPart
                            ? {
                                noFirstSpacing: (value) =>
                                  (value &&
                                    patternNoFirstSpacing.test(value.toString()?.trim())) ||
                                  t('ValidateMsg.min10Character'),
                                requiredStringAndNumb: (value) =>
                                  (value &&
                                    patternRequiredStringAndNumber.test(
                                      value.toString()?.trim()
                                    )) ||
                                  t('ValidateMsg.enterHaftWidthCharacter'),
                              }
                            : {},
                          onChange: (e) => {
                            e.target.value
                              ? setCheckValueNumberPart(true)
                              : setCheckValueNumberPart(false);
                          },
                        })}
                        placeholder={t('Creator.PostArticle.postContent.placeholderPart')}
                      />
                    </div>
                  </InputWrapper>

                  <InputWrapper
                    id='fsite-price'
                    className='group-method'
                    label={t('Creator.PostArticle.postContent.paymentMethod')}
                    required>
                    <div className='point group-haft'>
                      <Checkbox
                        type='checkbox'
                        onChange={() => {
                          handleChoiceMethod(1);
                        }}
                        checked={isChoicePrice}
                        text={t('Common.point')}
                        required
                        value={1}
                        direction='horizontal'
                        className='label-checkbox'
                      />
                      <div
                        className={`item-haft input-price ${
                          isChoicePrice &&
                          (valueCoin === null ||
                            !checkValidInteger(valueCoin) ||
                            !checkValidIntegerJP(valueCoin)) &&
                          'add-error-message'
                        }`}
                        after-content={t('Creator.PostArticle.postContent.afterContentPrice')}>
                        <input
                          {...register('price', {
                            maxLength: {
                              value: 7,
                              message: t('ValidateMsg.min6Character'),
                            },
                            validate: {
                              inValid: (value) => {
                                const newValue = convertFullToHalf(value);
                                if (
                                  newValue &&
                                  (patterTextJp.test(value.toString()?.trim()) ||
                                    !patternKatakanaHiraganaFullWidth.test(
                                      value.toString()?.trim()
                                    )) &&
                                  !patternOnlyNumber.test(newValue.toString()?.trim())
                                ) {
                                  return t('Creator.PostArticle.postContent.formatPrice');
                                }
                              },
                            },
                            onChange: (e) => {
                              const { value } = e.target;
                              if (value?.length <= 6) {
                                const newValue = convertFullToHalf(value?.toString()?.trim());
                                setValueChange(newValue);
                                if (+newValue < 0) {
                                  setValueCoin(newValue);
                                  return e.preventDefault();
                                } else if (
                                  patterTextJp.test(newValue) ||
                                  !patternOnlyNumber.test(newValue) ||
                                  patternKatakanaHiraganaFullWidth.test(value)
                                ) {
                                  setValueCoin(newValue);
                                  return e.preventDefault();
                                } else if (!newValue) {
                                  setValueCoin(null);
                                  return e.preventDefault();
                                } else {
                                  setValueCoin(+newValue);
                                }
                              }
                            },
                          })}
                          disabled={!isChoicePrice}
                          placeholder={t('Creator.PostArticle.postContent.placeholderPrice')}
                          value={handleOnBlurPrice ? valueCoinConvert : valueCoin}
                          onBlur={() => {
                            setHandleOnBlurPrice(true);
                            if (valueCoin && regexInteger.test(+valueCoin)) {
                              setValueCoinConvert(formatCoin(valueCoin));
                            } else {
                              setValueCoinConvert(valueCoin);
                            }
                            if (!valueCoin && !valueChange && valueCoin !== 0)
                              setValueCoin(null);
                          }}
                          onFocus={() => setHandleOnBlurPrice(false)}
                          onKeyDown={(e) => {
                            const newValue = convertFullToHalf(e.key);
                            !acceptIntegerOfInputText.includes(newValue) && e.preventDefault();
                          }}
                          type='text'
                          maxLength='20'
                        />
                        {isChoicePrice &&
                          (!checkValidInteger(valueCoin) ||
                            !checkValidIntegerJP(valueCoin)) && (
                            <div
                              className='error-message-custom'
                              style={{
                                color: '#ff0000',
                              }}>
                              {t('ValidateMsg.POINT_INTEGER_REQUIRED')}
                            </div>
                          )}
                        {isChoicePrice &&
                          checkValidInteger(valueCoin) &&
                          valueCoin === null && (
                            <div
                              className='error-message-custom'
                              style={{
                                color: '#ff0000',
                              }}>
                              {valueCoin === null
                                ? t('ValidateMsg.itemRequired')
                                : t('ValidateMsg.minPriceSales')}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className='premium-ticker group-haft'>
                      <Checkbox
                        type='checkbox'
                        onChange={() => {
                          handleChoiceMethod(2);
                          //setValue('number_premium_ticket', null);
                          clearErrors(['number_premium_ticket']);
                        }}
                        checked={isChoicePremium}
                        text={t('Creator.PostArticle.postContent.premiumTicket1')}
                        required
                        value={2}
                        direction='horizontal'
                        className='label-checkbox mt-24'
                      />
                      <InputWrapper
                        id='fsite-price'
                        className='d-flex justify-content-right flex-column'
                        error={
                          watchNumberTicket
                            ? errors?.number_premium_ticket
                              ? errors?.number_premium_ticket?.message
                              : ''
                            : ''
                        }>
                        <div
                          className={`item-haft input-condition ${
                            isCheckCondition &&
                            !valuePremiumTicket &&
                            'add-error-message add-error-message-custom'
                          }`}
                          after-content={t(
                            'Creator.PostArticle.postContent.afterContentCondition'
                          )}>
                          <input
                            maxLength='4'
                            type='text'
                            placeholder={t('Creator.PostArticle.postContent.placeholderPrice')}
                            disabled={!isChoicePremium}
                            {...register('number_premium_ticket', {
                              maxLength: {
                                value: 5,
                                message: 'ValidateMsg.min4Character',
                              },
                              // min: {
                              //   value: 1,
                              //   message: t('ValidateMsg.minNumberTicket0'),
                              // },
                              validate: valuePremiumTicket
                                ? {
                                    noFirstSpacing: (value) =>
                                      (value &&
                                        patternNoFirstSpacing.test(
                                          value.toString()?.trim()
                                        )) ||
                                      'ValidateMsg.min10Character',
                                    checkInteger: (value) =>
                                      (value && regexInteger.test(+value)) ||
                                      'ValidateMsg.PREMIUM_INTEGER_REQUIRED',
                                  }
                                : {},
                              onChange: (e) => {
                                if (e.target.value && isCheckCondition) {
                                  setValuePremiumTicket(true);
                                } else {
                                  setValuePremiumTicket(false);
                                }
                              },
                            })}
                            onWheel={(e) => e.target.blur()}
                            onKeyDown={(e) => {
                              return (
                                !acceptIntegerOfInputText.includes(e.key) && e.preventDefault()
                              );
                            }}
                          />
                          {isChoicePremium && watchNumberTicket && watchNumberTicket == 0 && (
                            <div
                              className='error-message-custom'
                              style={{
                                color: '#ff0000',
                              }}>
                              {t('ValidateMsg.minNumberTicket0')}
                            </div>
                          )}
                          {isChoicePremium && !watchNumberTicket && textValidateRequired2()}
                        </div>
                      </InputWrapper>
                    </div>
                  </InputWrapper>
                  <InputWrapper
                    className={`discount-group group-haft ${
                      showErrorDiscount(dateStartDiscount) ? 'error-start-discount' : ''
                    }`}
                    label={t('Creator.PostArticle.postContent.discountLabel')}>
                    <div className='discount item-haft mb-12'>
                      <div className='mb-4'>
                        {t('Creator.PostArticle.postContent.discount')}
                      </div>
                      <input
                        type='number'
                        placeholder='10%'
                        disabled={!isChoicePrice}
                        {...register('discount', {
                          pattern: {
                            value: /^[^.,]+$/,
                            message: 'ValidateMsg.DISCOUNT_INTEGER_REQUIRED',
                          },
                          validate: {
                            validDataDiscount: (value) => {
                              if (!!value) {
                                return (
                                  (+value >= 1 && +value < 100) ||
                                  'ValidateMsg.DISCOUNT_INVALID'
                                );
                              } else {
                                return true;
                              }
                            },
                            checkInteger: (value) => {
                              if (!!value) {
                                return (
                                  regexInteger.test(+value) ||
                                  'ValidateMsg.DISCOUNT_INTEGER_REQUIRED'
                                );
                              } else {
                                return true;
                              }
                            },
                          },
                        })}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) => {
                          return (
                            !acceptIntegerOfInputText.includes(e.key) && e.preventDefault()
                          );
                        }}
                      />
                      {isChoicePrice && errors?.discount && (
                        <div className='text-error'>{t(errors?.discount?.message)}</div>
                      )}
                      {!errors?.discount?.message &&
                        showErrorDiscount(watchDiscount) &&
                        textValidateRequired2({
                          width: '100%',
                          'white-space': 'pre-wrap',
                          position: 'relative',
                          'margin-top': '4px',
                        })}
                    </div>
                    <div
                      className={`group-time item-haft ${
                        showErrorDiscount(dateStartDiscount) ||
                        showErrorDiscount(dateEndDiscount)
                          ? 'error-start-discount'
                          : ''
                      }`}>
                      <div className='left'>
                        <div className='mb-4'>
                          {t('Creator.PostArticle.postContent.startDate')}
                        </div>
                        <div>
                          <InputWrapper
                            id='fsite-start-time'
                            className={`item-time ${!isChoicePrice ? 'disabled' : ''}`}>
                            <div className='start-time'>
                              <CalendarIconV2
                                onClick={() => {
                                  if (isVisibleCalendarStartDiscount) {
                                    toggleCalendarEndDiscount();
                                  }
                                  toggleCalendarStartDiscount();
                                }}
                                color={isVisibleCalendarStartDiscount && '#DC143C'}
                              />
                              {(dateStartDiscount || watchStartDiscount) && (
                                <CloseIcon
                                  className='abc'
                                  style={{ right: '35px' }}
                                  onClick={() => {
                                    onChangeDateStartDiscount(null);
                                    setValue('start_time_discount', null);
                                    setTimeStartDiscount(null);
                                  }}
                                />
                              )}
                              <input
                                placeholder='YYYY/MM/DD'
                                {...register('start_time_discount')}
                                value={dateStartDiscount}
                                readOnly
                                disabled={!isChoicePrice}
                                onClick={() => {
                                  if (isVisibleCalendarStartDiscount) {
                                    toggleCalendarEndDiscount();
                                  }
                                  toggleCalendarStartDiscount();
                                }}
                              />
                              {isVisibleCalendarStartDiscount && (
                                <ReactCalendar
                                  toggleCalendar={toggleCalendarStartDiscount}
                                  minDate={new Date()}
                                  onChange={(e) => {
                                    onChangeDateStartDiscount(e);
                                    setValue(
                                      'start_time_discount',
                                      moment(e).format('YYYY/MM/DD')
                                    );
                                  }}
                                  inputRef={datePickerRefStartDiscount}
                                  className='react-calendar-C081_1'
                                />
                              )}
                              {showErrorDiscount(watchStartDiscount) &&
                                textValidateRequired2({
                                  width: '100%',
                                  'white-space': 'pre-wrap',
                                })}
                            </div>
                            <div style={{ position: 'relative' }}>
                              <CustomSelectTime
                                placeholder='--:--'
                                timePost={(e) => {
                                  setTimeStartDiscount(e);
                                }}
                                disabledInput={!isChoicePrice}
                                handleOnClick={() => {
                                  if (isVisibleCalendarStartDiscount) {
                                    toggleCalendarStartDiscount();
                                  }
                                  if (isVisibleCalendar2) {
                                    toggleCalendarEndDiscount();
                                  }
                                }}
                                initTime={timeStartDiscount}
                                checkDisabled={true}
                                showIconV2
                                colorDefaultIcon='#BEBEBE'
                              />
                              {showErrorDiscount(timeStartDiscount) &&
                                textValidateRequired2({
                                  width: '100%',
                                  'white-space': 'pre-wrap',
                                })}
                            </div>
                          </InputWrapper>
                        </div>
                      </div>
                      <div className='right'>
                        <div className='mb-4'>
                          {t('Creator.PostArticle.postContent.endDate')}
                        </div>
                        <div>
                          <InputWrapper
                            id='fsite-start-time'
                            className={`item-time ${!isChoicePrice ? 'disabled' : ''}`}>
                            <div className='start-time'>
                              <CalendarIconV2
                                onClick={() => {
                                  if (isVisibleCalendarStartDiscount) {
                                    toggleCalendarStartDiscount();
                                  }
                                  toggleCalendarEndDiscount();
                                }}
                                color={isVisibleCalendarEndDiscount && '#DC143C'}
                              />
                              {(dateEndDiscount || watchEndDiscount) && (
                                <CloseIcon
                                  style={{ right: '35px' }}
                                  onClick={() => {
                                    onChangeDateEndDiscount(null);
                                    setValue('end_time_discount', null);
                                    setTimeEndDiscount(null);
                                  }}
                                />
                              )}
                              <input
                                placeholder='YYYY/MM/DD'
                                {...register('end_time_discount')}
                                value={dateEndDiscount}
                                disabled={!isChoicePrice}
                                readOnly
                                onClick={() => {
                                  if (isVisibleCalendarStartDiscount) {
                                    toggleCalendarStartDiscount();
                                  }
                                  toggleCalendarEndDiscount();
                                }}
                              />
                              {isVisibleCalendarEndDiscount && (
                                <ReactCalendar
                                  toggleCalendar={toggleCalendarEndDiscount}
                                  minDate={new Date()}
                                  onChange={(e) => {
                                    onChangeDateEndDiscount(e);
                                    setValue(
                                      'end_time_discount',
                                      moment(e).format('YYYY/MM/DD')
                                    );
                                  }}
                                  inputRef={datePickerRefEndDiscount}
                                  className='react-calendar-C081_1'
                                />
                              )}
                              {showErrorDiscount(watchEndDiscount) &&
                                textValidateRequired2({
                                  width: '100%',
                                  'white-space': 'pre-wrap',
                                })}
                            </div>
                            <div style={{ position: 'relative' }}>
                              <CustomSelectTime
                                placeholder='--:--'
                                timePost={(e) => {
                                  setTimeEndDiscount(e);
                                }}
                                disabledInput={!isChoicePrice}
                                handleOnClick={() => {
                                  if (isVisibleCalendarEndDiscount) {
                                    toggleCalendarEndDiscount();
                                  }
                                  if (isVisibleCalendarStartDiscount) {
                                    toggleCalendarStartDiscount();
                                  }
                                }}
                                initTime={timeEndDiscount}
                                checkDisabled={true}
                                showIconV2
                                colorDefaultIcon='#BEBEBE'
                              />
                              {showErrorDiscount(timeEndDiscount) &&
                                textValidateRequired2({
                                  width: '100%',
                                  'white-space': 'pre-wrap',
                                })}
                            </div>
                          </InputWrapper>
                        </div>
                      </div>
                    </div>
                  </InputWrapper>
                  <div className='d-flex justify-content-between mt-4'>
                    <label className='fw-bold'>
                      {t('Creator.PostArticle.postContent.onlyCreator')}
                    </label>
                    <ToggleButton
                      id='message_notification1'
                      checked={isCheckOnlyCreator}
                      onChange={onProductContent1}
                      disabled={
                        (isPostHaveBy && !isOnlyCreatorBeforeEdit) || isCheckPackagePaid
                      }
                    />
                  </div>
                  <div className='text-creator-info mt-1'>
                    {t('Creator.PostArticle.postContent.onlyCreatorInfo')}
                  </div>
                </>
              )}
              <InputWrapper
                className={`button-submit ${
                  detailPost?.type === 2
                    ? isDisabledButton
                    : disabledButtonPostNormal
                    ? 'disabled'
                    : ''
                }`}>
                <div className='fansite-creator-post-content__footer'>
                  <button
                    disabled={
                      detailPost?.type === 2 ? isDisabledButton : disabledButtonPostNormal
                    }
                    onClick={handleSubmit(
                      detailPost?.type === 2 ? onSubmit : onSubmitPostNormal
                    )}
                    className='btn btn-submit'
                    type='submit'>
                    {t('Common.buttonComplete')}
                  </button>
                </div>
              </InputWrapper>
            </form>
          )}
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(EditPostContent);

import { LayoutCenter } from 'components';
import { useTranslation } from 'react-i18next';
import { TERMS, TERMS_JP } from '../constant';
import './styles.scss';
import { Link } from 'react-router-dom';
import { getEnv } from 'configs/env';

const TermScreen = () => {
  const { i18n } = useTranslation();
  return (
    <>
      {i18n.language === 'en' ? (
        <LayoutCenter
          titlePage={'Sharegram Terms of Use'}
          notLogin={true}
          isShowLeftIcon={false}
          className={'title-page-creator page-legal'}
          uiMobile={true}>
          <div className={`container-fluid w-100 bg-white term-page creator`}>
            {/* <div className='title-page'>{TERMS?.title_page || ''}</div> */}
            <div>
              These terms of use (hereinafter referred to as the “Terms of Use”) set forth the
              terms and conditions of the service and the rights and obligations between DRAGON
              BLADE Inc. (hereinafter referred to as the “Company”) and users. In order to use
              the service, users must read the Terms of Use in its entirety and agree to the
              terms.
            </div>
            <div>
              <div>
                <div className='title-chapter'>Article 1 (Application)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. The purpose of the Terms of Use is to set forth the terms and conditions
                    of the provision of the service and the rights and obligations between the
                    Company and users regarding the use of the service. They shall apply to all
                    relationships between the Company and users regarding the use of the
                    service.
                  </div>
                  <div className={`content`}>
                    2. The rules regarding the use of the service {'('}
                    <Link to='/terms-of-use' target='_blank' className='link_route'>
                      {getEnv('REACT_APP_FAN_URL')}/terms-of-use
                    </Link>
                    {')'} posted on the Company's website shall constitute a part of these
                    Terms of Use.
                  </div>
                  <div className={`content`}>
                    3. In the event of any discrepancy between the contents of the Terms of Use
                    and the rules in the preceding paragraph or other explanations of the
                    service outside the Terms of Use, the provisions of the Terms of Use shall
                    take precedence over such discrepancy.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 2 (Definitions)</div>
                <div className='text'>
                  The following terms used in the Terms of Use shall have the meanings set
                  forth below:
                </div>
                <div>
                  <div className={`content`}>
                    (1) “The Service” means the service provided by the Company under the name
                    of “Sharegram” for supporting exchanges between creators and fans
                    (including the service after such change in the event that the name or
                    content of the service is changed for any reason).
                  </div>
                  <div className={`content`}>
                    (2) “The Service Agreement” means an agreement for the use of the Service
                    between the Company and users, made under the terms and conditions of the
                    Terms of Use.
                  </div>
                  <div className={`content`}>
                    (3) “Intellectual Property Rights” means copyrights, patents, utility model
                    rights, design rights, trademarks, and other intellectual property rights
                    (including the right to acquire such rights or to apply for registration of
                    such rights).
                  </div>
                  <div className={`content`}>
                    (4) “Posted Data” means any content (including but not limited to, text,
                    images, videos, and other data) posted or otherwise transmitted by Users
                    using the Service.
                  </div>

                  <div className={`content`}>
                    (5) “The Website” means the website operated by the Company with the domain
                    “share-gram.com” (including the website after any change to the domain or
                    content of our website for any reason).
                  </div>
                  <div className={`content`}>
                    (6) “Users” mean individuals who have registered as users of the Service in
                    accordance with Article 3 (Registration). Users consist of Creators and
                    general users.
                  </div>
                  <div className={`content`}>
                    (7) “Creators” mean individuals who have registered as users of the Service
                    in accordance with Article 3 (Registration) and provide content for
                    distribution through the Service.
                  </div>
                  <div className={`content`}>
                    (8) “General Users” means all users except Creators.
                  </div>
                  <div className={`content`}>
                    (9) “Streaming Content” means all content, including videos, audio (such as
                    music and other sounds), images, photos, language (such as comments and
                    scripts), trade names, trademarks, service marks, logos, interactive
                    features, software, indicators, and materials, that are streamed by
                    Creators through the Service.
                  </div>
                  <div className={`content`}>
                    (10) “The Fan Club” means a website established by Creators through the
                    Service for interaction with Creators and General Users.
                  </div>
                  <div className={`content`}>
                    (11) “Points” mean a payment method provided by the Company that is used by
                    General Users as compensation for viewing or using Streaming Content.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 3 (User Registration)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Anyone who wishes to use the Service (hereinafter referred to as the
                    “Applicants”) shall apply for registration to use the Service by agreeing
                    to comply with these Terms of Use and by providing the Company with certain
                    information, such as identity and age (hereinafter referred to as
                    “Registration Information”) in the manner prescribed by the Company. When
                    applying for registration, a photo identification card issued by a public
                    institution is required.
                  </div>
                  <div className={`content`}>
                    2. The Company shall determine whether or not to approve the registration
                    of the Applicants who applied for registration in accordance with Paragraph
                    1 (hereinafter referred to as “Registration Applicants”) in accordance with
                    the Company’s standards. If the Company approves the registration, the
                    Registration Applicants shall be notified accordingly. Registration of
                    Registration Applicants as Users shall be deemed completed when the Company
                    has notified the applicant in writing (including by e-mail, etc.) under
                    this paragraph.
                  </div>
                  <div className={`content`}>
                    3. Upon completion of the registration as stipulated in the preceding
                    paragraph, the Service Agreement will be established between Users and the
                    Company, and Users will be able to use the Service in accordance with the
                    Terms of Use.
                  </div>
                  <div className={`content`}>
                    4. In the event that the Registration Applicants fall under any of the
                    following items, the Company may refuse registration or re-registration and
                    shall not be obligated to disclose any reason for such refusal.
                    <div className={`sub-content`}>
                      (1) If any part or all of the registration information provided to the
                      Company is false, incorrect, or missing.
                    </div>
                    <div className={`sub-content`}>
                      (2) If the applicant is a minor, an adult ward, a person under
                      curatorship or assistance, and has not obtained the consent of their
                      legal representative, guardian, curator, or assistant.
                    </div>
                    <div className={`sub-content`}>
                      (3) If the Company determines that Registration Applicants are a member
                      of an antisocial force (meaning an organized crime group, a member of an
                      organized crime group, a right-wing group, an antisocial force, or any
                      other similar people; the same applies below), or that the Registration
                      Applicants have some kind of interaction or involvement with an
                      antisocial force, such as cooperating or participating in the
                      maintenance, operation or management of an antisocial force through
                      funding or other means.
                    </div>
                    <div className={`sub-content`}>
                      (4) If the Company determines that the Registration Applicants have
                      violated the Service Agreement with the Company in the past or have been
                      involved in violations.
                    </div>
                    <div className={`sub-content`}>
                      (5) If the Registration Applicants have been subjected to any measures
                      stipulated in Article 21.
                    </div>
                    <div className={`sub-content`}>
                      (6) Any other case in which the Company deems the registration is
                      inappropriate.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 4 (Change of Registered Information)
                </div>
                <div className='text'>
                  In the event of any change in the Registered Information, Users shall notify
                  the Company of such changes without delay in a manner determined by the
                  Company.
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 5 (Management of Password and User ID)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Users shall properly manage and store the passwords and user IDs for the
                    Service at their own responsibility and shall not allow any third party to
                    use, lend, assign, transfer, change the name of, or sell them.
                  </div>
                  <div className={`content`}>
                    2. The User shall be liable for any damages caused by inadequate management
                    of the passwords or user IDs, errors in use, or use by a third party.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 6 (Functions of the Service)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. The Company shall provide all or part of the following functions in the
                    Service to Creators:
                    <div className={`sub-content`}>(1) Opening a Fan Club site</div>
                    <div className={`sub-content`}>(2) Distributing Streaming Content</div>
                    <div className={`sub-content`}>
                      (3) Other functions that the Company deems useful for the Service.
                    </div>
                  </div>
                  <div className={`content`}>
                    2. The Company shall provide all or part of the following functions in the
                    Service to General Users:
                    <div className={`sub-content`}>(1) Viewing of Streaming Content</div>
                    <div className={`sub-content`}>
                      (2) Posting data, such as questions, comments, Likes, and “Likes” on the
                      Service
                    </div>
                    <div className={`sub-content`}>
                      (3) Other functions that the Company deems useful for the Service
                    </div>
                  </div>
                  <div className={`content`}>
                    3. The Company may add or change the functions of the Service without prior
                    notice unless there is a significant impact on Users.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 7 (Fan Club Usage Agreement)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. When General Users apply for membership in the Fan Club on the Website,
                    and Creators notify them of the application acceptance, a Fan Club Usage
                    Agreement will be established between Creators and General Users.
                  </div>
                  <div className={`content`}>
                    2. By entering into the Fan Club Usage Agreement, General Users agree that
                    they will be obligated to pay the Fan Club membership fees set for each fan
                    club.
                  </div>
                  <div className={`content`}>
                    3. The fee structure and payment methods for each Fan Club shall be
                    determined separately on the Website.
                  </div>
                  <div className={`content`}>
                    4. Creators may change all or part of the contents or fee structure of the
                    Fan Club or terminate all or part of the Fan Club in accordance with the
                    method separately specified by the Company.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 8 (Terms of Fan Club Usage Agreement, etc.)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. The term of the Fan Club Usage Agreement shall be until the same day of
                    the month following the date of admission of General Users to the Fan Club,
                    and thereafter, the agreement shall be automatically renewed on a monthly
                    basis. However, if there is no calendar day corresponding to the joining
                    date in a month, the contract period will be until the day before the last
                    day of the month. (For example, if Users apply on August 31st, the last day
                    of the contract is September 29th.) In addition, if members of the Fan Club
                    (hereinafter referred to as the "Fan Club Members") apply for withdrawal,
                    the Fan Club Usage Agreement will end on the day of the withdrawal
                    application.
                  </div>
                  <div className={`content`}>
                    2. Even if a Fan Club Member withdraws from the Fan Club in the middle of
                    the term of the Fan Club Usage Agreement, the Fan Club Membership fee is
                    not prorated, and the Fan Club Members may not request a refund of the Fan
                    Club Membership fee for the current month.
                  </div>
                  <div className={`content`}>
                    3. If payment of Fan Club Membership fees is delayed (including cases where
                    the payment procedure is not completed due to insufficient point balance,
                    etc.), Fan Club Member shall be deemed to have automatically withdrawn from
                    the Fan Club.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 9 (Termination of Fan Club Usage Agreement, etc. by the Company)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. If the Company terminates the User Registration of Creators or General
                    Users, all agreements between the relevant Creators and the Fan Club
                    Members or between the relevant General Users and registered Creators shall
                    be terminated at the time.
                  </div>
                  <div className={`content`}>
                    2. Even in the case of the preceding paragraph, the Fan Club Membership fee
                    will not be calculated on a pro-rata basis, and Fan Club Members cannot
                    request a refund of Fan Club Membership fees for that month.
                  </div>
                  <div className={`content`}>
                    3. Even if the Fan Club Agreement is terminated pursuant to this Article,
                    the Company will be exempt from liability to the extent that the measures
                    taken are based on the Terms of Use. In this case, Users will not be able
                    to claim any damages from the Company.
                  </div>
                  <div className={`content`}>
                    4. If the Company terminates the User Registration of particular Creators,
                    the Company may delete all Fan Club sites (including the Streaming Content)
                    opened by such Creators. Even in this case, the Company shall be exempted
                    from liability to the extent that the action is based on the Terms of Use,
                    and the relevant Creators shall not be entitled to claim any compensation
                    for damages against the Company.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 10 (Individual Content Streaming Agreement)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Creators may stream content (hereinafter referred to as “Individual
                    Content Streaming”) by setting the price, distribution date and time, and
                    other conditions in a manner separately specified, in addition to the
                    member-only streaming content under the Fan Club Usage Agreement.
                  </div>
                  <div className={`content`}>
                    2. Registered Users may apply to view or use Individual Content Streaming
                    in a manner separately determined by the Company, which will result in
                    forming an Individual Content Streaming Agreement with the relevant
                    Creators.
                  </div>
                  <div className={`content`}>
                    3. Creators may change all or part of the contents of individual Content
                    Streaming or fee structure or terminate all or part of such Content
                    Streaming in accordance with a manner separately determined by the Company.
                  </div>
                  <div className={`content`}>
                    4. With regard to the termination of an Individual Content Streaming
                    Agreement at the discretion of our company, the provisions of Article 9
                    shall apply.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 11 (Purchase and Handling of Points)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. General Users may purchase points as payment methods for the service fee
                    by credit card, bank account transfer, or other methods specified by the
                    Company. They may use the points when using the service, with 1 point
                    equivalent to 1 yen. Other details of points and terms of use shall be
                    specified separately by the Company and displayed on the Service.
                  </div>
                  <div className={`content`}>
                    2. The maximum number of points general users can hold is 100,000.
                  </div>
                  <div className={`content`}>
                    3. General users may only use their points for 180 days from the date of
                    issuance of paid points. Users may only use the same points for a period of
                    180 days from the date they receive general free points or for a period of
                    days separately determined by the Company before the grant of points.
                    Unused points after the expiration date will expire and cannot be used
                    thereafter.
                  </div>
                  <div className={`content`}>
                    4. The Company will not refund or convert points into cash, regardless of
                    the reason. However, this does not apply when required by law. In such
                    cases, the method of refunding points will be determined by the Company in
                    accordance with the law and will be notified separately.
                  </div>
                  <div className={`content`}>
                    5. The Company will notify General Users of the points earned by General
                    Users, the points used by General Users, and the remaining points
                    (hereinafter referred to as “Point Amount”) in a manner separately
                    determined by the Company. If General Users have doubts about the Point
                    Amount, they must immediately contact the Company and explain the details.
                    The Company shall make the final decision regarding the Point Amount, and
                    General Users shall comply with such decision.
                  </div>
                  <div className={`content`}>
                    6. The use of points must be done by the General Users themselves and
                    cannot be done by a third party other than the said General Users. Even in
                    the case of unauthorized use of points by a third party, we are not
                    obligated to return the points used and are not liable for any damages
                    incurred by the general user.
                  </div>
                  <div className={`content`}>
                    Points must be used by General Users themselves and cannot be used by any
                    third party other than General Users. Even if points are used illegally by
                    a third party, the Company is not obligated to return the points used. It
                    is not liable for any damages incurred by General Users.
                  </div>
                  <div className={`content`}>
                    7. If any taxes or incidental expenses are incurred as a result of
                    acquiring or using points, General Users are responsible for these
                    expenses.
                  </div>
                  <div className={`content`}>
                    8. Regardless of the reason, if General Users withdraw from the Service or
                    their registration is canceled, all points held by those General Users will
                    become invalid and will no longer be available for use.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 12 (Campaigns)</div>
                <div className='text'>
                  The Company may grant points free of charge to General Users who participate
                  in various campaigns on the Service or perform other acts separately
                  designated by the Company.
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 13 (Settlement and Payment Application)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Creators shall grant the Company the authority to receive Fan Club
                    Membership fees and Individual Content Streaming Agreement usage fees
                    (hereinafter referred to as “Membership Fees, etc.”) from General Users on
                    behalf of Creators, and the authority to subcontract such receipt to a
                    third party designated by the Company (hereinafter collectively with the
                    Company, the “Proxy Recipients”). When the Proxy Recipient receives the
                    Membership Fee, etc., from General Users, the payment obligations of the
                    General Users under these Terms of Use shall be extinguished.
                  </div>
                  <div className={`content`}>
                    2. General Users shall not pay the Membership Fee directly to Creators.
                    General Users may not reject any claim from the Company on the basis that
                    they have paid the Membership Fee, etc., directly to Creators.
                  </div>
                  <div className={`content`}>
                    3. The Company shall pay Creators the amount of the Membership Fees, etc.,
                    paid by General Users to the relevant Creators during the calculation
                    period separately stipulated by the Company by way of a bank transfer to
                    the account designated by the relevant Creators after deducting the bank
                    transfer fee. Creators agree that the Company may remit the payment above
                    in US dollars and that Creators shall bear the exchange commission.
                  </div>
                  <div className={`content`}>
                    4. In the event that Creators cancel their own User Registration or have
                    their registration canceled in accordance with the provisions of Article 9,
                    the Company may deem that Creators have waived any and all membership fees
                    that it may charge to Creators.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 14 (Fees for Use of the Service)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. The Creator shall pay the Company a fee separately determined by the
                    Company as compensation for using the Service.
                  </div>
                  <div className={`content`}>
                    2. The Company may deduct the Usage Fee stipulated in the preceding
                    paragraph from the Membership Fee, etc., paid to Creators in accordance
                    with Paragraph 3 of the preceding Article instead of receiving the fee.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 15 (Responsibilities of Users)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. If Users defame another person, infringes the right to privacy,
                    discloses a third party's personal information without authorization,
                    violates copyright laws, or otherwise infringes the rights of a third
                    party, the User shall resolve the matter at the User's own responsibility
                    and expense, and the Company shall not be held liable for any loss or
                    damage incurred. We will not be liable for any damages.
                  </div>
                  <div className={`content`}>
                    2. The Company shall not be liable for any loss or damage caused by
                    storage, preservation, backup, etc., of data that Users have accumulated or
                    stored within the Service by using the Users, except for reasons
                    attributable to the Company.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 16 (Performers of Streaming Content)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. In the event that Creators or a third party appear in the Streaming
                    Content (the persons appearing in the Streaming Content are hereinafter
                    referred to as the “Performers”), Creators shall provide to the Company
                    before the Streaming Content is delivered through the Service, in a manner
                    separately determined by the Company, attribute information, such as the
                    name and age of such Performers, an identification card with a photograph
                    of Performers issued by a public agency and a photograph of the Performers’
                    faces side by side with the photo IDs, a consent form for the performance,
                    public distribution, download, etc. (hereinafter referred to as “Performer
                    Information, etc.”) that should be obtained in accordance with the
                    requirements of the laws applicable to Creators and Performers.
                  </div>
                  <div className={`content`}>
                    2. Creators shall obtain prior consent from Performers for the provision of
                    the Performer Information, etc., to the Company and for the storage of the
                    Performer Information, etc., by the Company.
                  </div>
                  <div className={`content`}>
                    3. If requested by the Company, Creators shall promptly submit any
                    information or materials requested by the Company in addition to the
                    Performer Information, etc.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 17 (Authority and Responsibility of the Company)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. The Company shall, without the Creators’ consent, confirm that the
                    Content is legal and does not violate the Terms of Use before it is
                    distributed through the Service, and the Creators agree to such
                    confirmation.
                  </div>
                  <div className={`content`}>
                    2. When Creators have Streaming Content in real-time, the Company shall
                    monitor the content in question in real-time to ensure that it is legal and
                    does not violate the Terms of Use.
                  </div>
                  <div className={`content`}>
                    3. If the Company determines that any of the Streaming Content in
                    accordance with the preceding two paragraphs is illegal or in violation of
                    these Terms of Use, the Company may take the measures stipulated in Article
                    21, and Creators shall agree to such measures.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 18 (Prohibited Matters)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. In using the Service, the Creator shall not engage in any of the
                    following acts or any act that the Company deems to fall under any of the
                    following items:
                    <div className={`sub-content`}>
                      (1) Any acts that violate laws and regulations or are related to criminal
                      acts.
                    </div>
                    <div className={`sub-content`}>
                      (2) Fraud or threats against the Company, other users of the Service, or
                      other third parties.
                    </div>
                    <div className={`sub-content`}>
                      (3) Posting of distributed contents that include the body of a minor.
                    </div>
                    <div className={`sub-content`}>
                      (4) Any acts that are offensive to public order and morals.
                    </div>
                    <div className={`sub-content`}>
                      (5) Infringement of intellectual property rights, portrait rights, rights
                      of privacy, honor, or any other rights or interests of the Company, other
                      users of the Service, or any other third party.
                    </div>
                    <div className={`sub-content`}>
                      (6) Any acts that transmit the information that is applicable to the
                      following or the Company considers applicable to the following through
                      the Service to the Company or other users of the Service:
                      <div className={`sub-content`}>
                        ・ Information containing excessively violent or cruel expressions.
                      </div>
                      <div className={`sub-content`}>
                        ・ Information containing computer viruses or other harmful computer
                        programs.
                      </div>
                      <div className={`sub-content`}>
                        ・ Information containing expressions that defame or discredit the
                        Company, other users of the Service, or other third parties.
                      </div>
                      <div className={`sub-content`}>
                        ・ Information containing expressions that promote discrimination.
                      </div>
                      <div className={`sub-content`}>
                        ・ Information containing expressions that promote suicide or
                        self-harm.
                      </div>
                      <div className={`sub-content`}>
                        ・ Information containing expressions that promote human trafficking,
                        sexual abuse, or child exploitation.
                      </div>
                      <div className={`sub-content`}>
                        ・ Information containing expressions that encourage inappropriate use
                        of drugs or excessive alcohol consumption.
                      </div>
                      <div className={`sub-content`}>
                        ・ Information containing antisocial expressions.
                      </div>
                      <div className={`sub-content`}>
                        ・ Information containing expressions that request the spread of
                        information to a third party, such as chain mail.
                      </div>
                      <div className={`sub-content`}>
                        ・ Information containing expressions that may cause discomfort to
                        others.
                      </div>
                    </div>
                    <div className={`sub-content`}>
                      (7) Any acts that excessively load the Service's network or system.
                    </div>
                    <div className={`sub-content`}>
                      (8) Reverse engineering or other analysis of the software or other
                      systems provided by the Company.
                    </div>
                    <div className={`sub-content`}>
                      (9) Any acts that may interfere with the operation of the Service.
                    </div>
                    <div className={`sub-content`}>
                      (10) Unauthorized access to the Company’s network or system, etc.
                    </div>
                    <div className={`sub-content`}>
                      (11) Any acts that impersonate a third party.
                    </div>
                    <div className={`sub-content`}>
                      (12) Using the ID or password of another user of the Service.
                    </div>
                    <div className={`sub-content`}>
                      (13) Any acts of promoting, advertising, soliciting, or conducting sales
                      activities on the Service without prior permission from the Company.
                    </div>
                    <div className={`sub-content`}>
                      (14) Any acts of collecting information of other users of the Service.
                    </div>
                    <div className={`sub-content`}>
                      (15) Any acts of causing any disadvantage, damage, or discomfort to the
                      Company, other users of the Service, or any other third party.
                    </div>
                    <div className={`sub-content`}>
                      (16) Any acts of providing benefits to antisocial forces, etc.
                    </div>
                    <div className={`sub-content`}>
                      (17) Any acts that aim at meeting members who are not acquainted.
                    </div>
                    <div className={`sub-content`}>
                      (18) Any acts that directly or indirectly cause or facilitate the acts
                      above.
                    </div>
                    <div className={`sub-content`}>
                      (19) Any attempts to commit any of the aforementioned acts.
                    </div>
                    <div className={`sub-content`}>
                      (20) Any other acts that the Company deems inappropriate.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 19 (Suspension of the Service, etc.)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. The Company may suspend or interrupt the provision of all or part of the
                    Service without prior notice to the User if any of the following
                    circumstances apply:
                    <div className={`sub-content`}>
                      (1) When urgent inspection or maintenance work is carried out on the
                      computer system related to this service
                    </div>
                    <div className={`sub-content`}>
                      (2) When the operation of the Service becomes impossible due to computer
                      or communication line failure, operational errors, excessive
                      concentration of access, unauthorized access, hacking, etc.
                    </div>
                    <div className={`sub-content`}>
                      (3) If the operation of the Service becomes impossible due to force
                      majeure, such as earthquakes, lightning, fires, storms, floods, power
                      outages, and other natural disasters
                    </div>
                    <div className={`sub-content`}>
                      (4) Any other reason the Company deems necessary to suspend or interrupt
                      the service.
                    </div>
                  </div>
                  <div className={`content`}>
                    2. The Company may change the contents of the Service or terminate its
                    provision at its convenience.
                  </div>
                  <div className={`content`}>
                    3. If the Company terminates the provision of this Service, the Company
                    will notify Users in advance in a manner specified by the Company.
                  </div>
                  <div className={`content`}>
                    4. The Company shall not be liable to compensate Users for any damages
                    incurred by Users due to the suspension of the Service, as stipulated in
                    this Article, regardless of the reason.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 20 (Attribution of Rights)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. All intellectual property rights relating to the Website and the Service
                    belong to the Company or its licensors, and the license to use the Service
                    under the Terms of Use does not imply any license to use any intellectual
                    property rights of the Company or its licensors relating to the Website or
                    the Service.
                  </div>
                  <div className={`content`}>
                    2. Users represent and warrant to the Company that they have the lawful
                    right to post or otherwise transmit the posted data or distributed content
                    (hereinafter referred to as “Posted Data, etc.”) and that the posted Data,
                    etc., does not infringe the rights of any third party.
                  </div>
                  <div className={`content`}>
                    3. Users grant the Company a worldwide, non-exclusive, royalty-free,
                    sublicensable, and transferable license to use, copy, distribute, create
                    derivative works from, display, and execute the Posted Data, etc. Users
                    also grant other Users a non-exclusive license to use, copy, distribute,
                    create derivative works from, display, and execute the Posted Data, etc.,
                    posted or otherwise transmitted by the User using the Service.
                  </div>
                  <div className={`content`}>
                    4. Users represent and warrant to the Company or any person who has
                    succeeded to or been granted rights by the Company that they have the legal
                    right to use the Posted Data, etc., for their own purposes.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 21 (Cancellation of Registration)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. If Users fall under any of the following items, the Company may, without
                    prior notice or warning, delete or hide the Posted Data, etc., temporarily
                    suspend Users from using the Service, or terminate their registration:
                    <div className='sub-content'>
                      (1) If Users violate any of the provisions of the Terms of Use
                    </div>
                    <div className='sub-content'>
                      (2) If any false information is discovered in the registration
                    </div>
                    <div className='sub-content'>
                      (3) If Users stop making payments or become insolvent, or file a petition
                      for the commencement of bankruptcy proceedings, civil rehabilitation
                      proceedings, corporate reorganization proceedings, special liquidation,
                      or similar proceedings
                    </div>
                    <div className='sub-content'>
                      (4) If Users do not respond to inquiries or other communications
                      requesting a response from the Company for more than 30 days
                    </div>
                    <div className='sub-content'>
                      (5) If any of the items of Article 3, Paragraph 4 applies
                    </div>
                    <div className='sub-content'>
                      (6) In any other case in which the Company deems it inappropriate for
                      Users to use the Service or to continue their registration
                    </div>
                    <div className='sub-content'>
                      In the event of any of the events listed in the preceding paragraphs, the
                      User shall lose the benefit of time for all debts owed to the Company and
                      shall immediately pay all debts owed to the Company.
                    </div>
                  </div>
                  <div className={`content`}>
                    2. If any of the items in the preceding paragraph applies to Users, all
                    debts owed to the Company will automatically become due and payable by
                    Users, and they must immediately pay all debts to the Company.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 22 (Withdrawal from Membership)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Users may withdraw from the Service upon completing the procedures
                    prescribed by the Company; in the case of Users, they can terminate their
                    registration as Users.
                  </div>
                  <div className={`content`}>
                    2. When Users cancel their memberships, if they have any debts to the
                    Company, all such debts will automatically become due and payable, and the
                    User must immediately pay all such debts to the Company.
                  </div>
                  <div className={`content`}>
                    3. The handling of User Information after cancellation shall be in
                    accordance with the provisions of Article 15.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 23 (Treatment after Termination of Contract)
                </div>
                <div className='text'>
                  For any reason, the Company may delete all Service data after the Service
                  Agreement's termination.
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 24 (Deletion Request Procedure)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Users can request the Company to delete Posted Data for copyright
                    infringement, other violations of laws and regulations, or the Terms of Use
                    via the application form (the inquiry URL) or the “report button” on each
                    post.
                  </div>
                  <div className={`content`}>
                    2. If the Company determines that the deletion request in the preceding
                    paragraph is reasonable, the Company shall delete the relevant Posted Data
                    within seven days of the date of the deletion request. In such a case, the
                    Company shall report its action to the persons requesting the deletion
                    within one month of the date of the deletion request.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 25 (Disclaimer of Warranty and Exemption of Liability)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. The Company shall not warrant, expressly or implicitly, that the Service
                    will conform to the Users’ specific purpose, that the Service will have the
                    expected functionality, commercial value, accuracy, and usefulness, that
                    the Users’ use of the Service will comply with applicable laws and
                    regulations or internal rules of industry associations, that the Service
                    will be continuously available, or that it will be free from malfunctions.
                  </div>
                  <div className={`content`}>
                    2. With respect to any damage incurred by Users in connection with the
                    Service, the Company shall be liable for compensation only to the extent of
                    actual and direct ordinary damages (excluding special damages, lost
                    profits, indirect damages, and attorney's fees). The amount of compensation
                    shall be limited to a maximum of 10,000 yen.
                  </div>
                  <div className={`content`}>
                    3. The Company does not guarantee that the Service is compatible with all
                    information terminals. Users acknowledge in advance that malfunctions in
                    the Service operation may occur due to updates to the OS of the information
                    terminal used to use the Service. The Company does not guarantee that such
                    malfunctions will be resolved by the Company’s modification of the program
                    in the event of such malfunctions.
                  </div>
                  <div className={`content`}>
                    4. Users shall be responsible for resolving any transactions,
                    communications, or disputes arising between Users and other Users or third
                    parties in connection with the Service or the Website.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 26 (Use of External Services)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. When using this Service via Facebook, Twitter, or other external
                    services, Users shall comply with the terms of use and other conditions
                    stipulated by such external services in addition to the Terms of Use
                    (including the revised terms in the event that such terms of use are
                    changed).
                  </div>
                  <div className={`content`}>
                    2. With regard to external services, a company or an individual providing
                    such external services shall be responsible. The Company makes no guarantee
                    that such external services are free of errors, bugs, malfunctions, or
                    security defects, that they do not infringe the rights of third parties,
                    that they have the properties and commercial value expected by users, or
                    that they comply with laws, regulations or internal rules that are
                    applicable to users.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 27 (Confidentiality)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    Users shall treat any non-public information disclosed by the Company to
                    them in connection with the Service, which the Company requires Users to
                    treat as confidential, as confidential unless Users have given the
                    Company’s prior written consent.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 28 (Handling of User Information)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Its separate Privacy Policy shall govern The Company’s handling of the
                    User's User Information{' ('}
                    <Link to='/privacy-policy' target='_blank' className='link_route'>
                      {getEnv('REACT_APP_FAN_URL')}/privacy-policy
                    </Link>
                    {')'}. Users agree that the Company will handle the User Information in
                    accordance with the Privacy Policy.
                  </div>
                  <div className={`content`}>
                    2. The Company may, at its discretion, use and disclose any information,
                    data, etc., provided by Users as statistical information in a form that
                    does not identify individuals. Users shall not object to such use and
                    disclosure.
                  </div>
                  <div className={`content`}>
                    3. If the Posted Data contains personal information, Users must obtain the
                    consent of the individuals to whom the personal information pertains to be
                    included in the Posted Data. Users must handle the personal information at
                    their own risk and with the due care of a prudent administrator, and the
                    Company will cooperate with such efforts as much as possible.
                  </div>
                  <div className={`content`}>
                    4. Users shall handle any claims or disputes arising from including
                    personal information in Posted Data at their own responsibility and
                    expense, and the Company shall not be liable for any such claims or
                    disputes.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 29 (Modification of these Terms of Use)
                </div>
                <div className='text'>
                  The Company may change these Terms of Use if it deems it necessary. When
                  changing the Terms of Use, the Company will notify Users of the effective
                  date and content of the changed Terms of Use by posting on the Website or
                  other appropriate means. However, if the changes require the Users’ consent
                  under law, the Users’ consent will be obtained in a manner prescribed by the
                  Company.
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 30 (Communication and Notice)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Inquiries regarding the Service and other communications or notices from
                    Users to the Company, as well as notices regarding changes to the Terms of
                    Use and other communications or notices from the Company to Users, shall be
                    made in the manner specified by the Company.
                  </div>
                  <div className={`content`}>
                    2. In the event that the Company contacts or notifies Users via the email
                    address or other contact details included in the Registration Information,
                    Users shall be deemed to have received such contact or notification.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 31 (Termination of Use of the Website)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Users may terminate their use of the Service at any time by deleting
                    their account or by other means specified by the Company. Users who
                    terminate their use of the Service will no longer be able to use the
                    Service from the time of such termination.
                  </div>
                  <div className={`content`}>
                    2. If Users lose the right to use the Service due to accidental deletion of
                    the account or for any other reasons, Users agree in advance that they will
                    no longer be able to use the account, provided content, or other
                    information accumulated in the Service.
                  </div>
                  <div className={`content`}>
                    3. Even after Users have terminated use of the Service, Users shall not be
                    exempted from any obligations and liabilities (including but not limited to
                    compensation for damages) to the Company and third parties under the
                    Service Agreement.
                  </div>
                  <div className={`content`}>
                    4. The Company may retain and use the information provided by Users even
                    after they have terminated their use of the Service.
                  </div>
                  <div className={`content`}>
                    5. If Users terminate their use of the Service, the Company may, at its
                    discretion, delete their account without prior notice, and they agree that
                    any and all content remaining on the Website at the time of termination may
                    be deleted.
                  </div>
                  <div className={`content`}>
                    6. In the event of the User’s death, their account, User Information, and
                    other information stored on this Website will be deleted, and the right to
                    use the Service and this Website will not be subject to inheritance.
                  </div>
                  <div className={`content`}>
                    7. The Company reserves the right to delete any account that has not been
                    accessed for more than six months at its discretion and without prior
                    notice.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 32 (Transfer of Status under Service Usage Contract)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Users may not assign, transfer, pledge as security, or otherwise dispose
                    of their status under the Service Agreement or their rights or obligations
                    under the Terms of Use to a third party without the Company’s prior written
                    consent.
                  </div>
                  <div className={`content`}>
                    2. In the event that the Company transfers the business related to the
                    Service to another company, the Company may transfer the status under the
                    Service Agreement, the rights and obligations under the Terms of Use, and
                    the Users’ Registration Information and other customer information to the
                    transferee of the business. Users agree to such transfer in advance under
                    this paragraph. The business transfer stipulated in this paragraph shall
                    include not only ordinary business transfers but also corporate
                    divestitures and any other cases in which business is transferred.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 33 (Severability)</div>
                <div className='text'>
                  Even if any provision or part of any provision of the Terms of Use is
                  determined to be invalid or unenforceable pursuant to the Consumer Contract
                  Act or other laws and regulations, the remaining provisions of the Terms of
                  Use and the remaining part of the provision that is determined to be invalid
                  or unenforceable shall continue in full force and effect.
                </div>
                <div></div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 34 (Governing Law and Court of Jurisdiction)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. The Terms of Use and the Service Agreement shall be governed by and
                    construed in accordance with the laws of the State of Nevada, USA.
                  </div>
                  <div className={`content`}>
                    2. Any disputes arising from or relating to the Terms of Use or the Service
                    Agreement shall be submitted to the exclusive jurisdiction of the District
                    Court of Nevada, USA.
                  </div>
                </div>
              </div>
            </div>
            <div className='end-text'>
              [Adopted on June 19, 2023]
            </div>
            <div className='end-text'>
              [Revised on September 15, 2024]
            </div>
          </div>
        </LayoutCenter>
      ) : (
        <LayoutCenter
          titlePage='Sharegram利用規約'
          notLogin={true}
          isShowLeftIcon={false}
          className={'title-page-creator page-legal'}
          uiMobile={true}>
          <div className={`container-fluid w-100 bg-white term-page creator`}>
            {/* <div className='title-page'>{TERMS_JP?.title_page || ''}</div> */}
            <div>
              本利用規約（以下「本規約」といいます）には、本サービスの提供条件及びDRAGON BLADE
              inc
              （以下「当社」といいます）とユーザーの皆様との間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。
            </div>
            <div>
              <div>
                <div className='title-chapter'>第1条（適用）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.	本規約は、本サービスの提供条件及び本サービスの利用に関する当社とユーザーとの間の権利義務関係を定めることを目的とし、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されます。
                  </div>
                  <div className={`content`}>
                    2. 当社が本ウェブサイト上で掲載する本サービス利用に関するルール{' ('}
                    <Link to='/terms-of-use' target='_blank' className='link_route'>
                      {getEnv('REACT_APP_FAN_URL')}/terms-of-use
                    </Link>
                    {') '}
                    は、本規約の一部を構成するものとします。
                  </div>
                  <div className={`content`}>
                    3.	本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>第2条（定義）</div>
                <div className='text'>
                  本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。
                </div>
                <div>
                  <div className={`content`}>
                    (1)
                    「本サービス」とは、当社が提供する【Sharegram】という名称のクリエイター及びファンの交流支援関連サービス（理由の如何を問わずサービスの名称または内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。
                  </div>
                  <div className={`content`}>
                    (2)
                    「サービス利用契約」とは、本規約を契約条件として当社とユーザーの間で締結される、本サービスの利用契約を意味します。
                  </div>
                  <div className={`content`}>
                    (3)
                    「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。
                  </div>
                  <div className={`content`}>
                    (4)
                    「投稿データ」とは、ユーザーが本サービスを利用して投稿その他送信するコンテンツ（文章、画像、動画その他のデータを含みますがこれらに限りません。）を意味します。
                  </div>
                  <div className={`content`}>
                    (5)
                    「本ウェブサイト」とは、そのドメインが「share-gram.com」である、当社が運営するウェブサイト（理由の如何を問わず、当社のウェブサイトのドメインまたは内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。
                  </div>
                  <div className={`content`}>
                    (6)
                    「ユーザー」とは、第3条（登録）に基づいて本サービスの利用者としての登録がなされた個人を意味します。ユーザーはクリエイター及び一般ユーザーから構成されます。
                  </div>
                  <div className={`content`}>
                    (7)
                    「クリエイター」とは、第3条（登録）に基づいて本サービスの利用者としての登録がなされた個人のうち、本サービスを通じて配信コンテンツを提供する者を意味します。
                  </div>
                  <div className={`content`}>
                    (8)
                    「一般ユーザー」とは、ユーザーのうち、クリエイターを除くすべての者を意味します。
                  </div>
                  <div className={`content`}>
                    (9)
                    「配信コンテンツ」とは、本サービスを通じてクリエイターによって配信される動画、オーディオ（音楽やその他のサウンドなど）、画像、写真、言語（コメントや脚本など）、商号、商標、サービスマーク、ロゴ、インタラクティブ機能、ソフトウェア、指標、素材等の一切のコンテンツを意味します。
                  </div>
                  <div className={`content`}>
                    (10)
                    「ファンクラブ」とは、クリエイターが本サービスを通じて開設する、クリエイター及び一般ユーザーとの交流サイトを意味します。
                  </div>
                  <div className={`content`}>
                    (11)
                    「ポイント」とは、当社が提供する、一般ユーザーによる配信コンテンツの視聴　ないし利用の対価として用いられる決済手段をいいます。
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>第3条（ユーザー登録）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    本サービスの利用を希望する者（以下「登録希望者」といいます）は、本規約を遵守することに同意し、かつ当社の定める身元や年齢等の一定の情報（以下「登録事項」といいます）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。なお、登録申請の際には公的機関が発行した顔写真付きの身分証明書が必要となります。
                  </div>
                  <div className={`content`}>
                    2.
                    当社は、当社の基準に従って、第１項に基づいて登録申請を行った登録希望者（以下「登録申請者」といいます）の登録の可否を判断し、当社が登録を認める場合にはその旨を登録申請者に通知します。登録申請者のユーザーとしての登録は、当社が本項の通知を書面（Eメール等を含みます）により行ったことをもって完了したものとします。
                  </div>
                  <div className={`content`}>
                    3.
                    前項に定める登録の完了時に、サービス利用契約がユーザーと当社の間に成立し、ユーザーは本サービスを本規約に従い利用することができるようになります。
                  </div>
                  <div className={`content`}>
                    4.
                    当社は、登録申請者が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。
                    <div className={`sub-content`}>
                      (1)
                      当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合
                    </div>
                    <div className={`sub-content`}>
                      (2)
                      未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合
                    </div>
                    <div className={`sub-content`}>
                      (3)
                      反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合
                    </div>
                    <div className={`sub-content`}>
                      (4)
                      過去当社との契約に違反した者またはその関係者であると当社が判断した場合
                    </div>
                    <div className={`sub-content`}>
                      (5) 第21条に定める措置を受けたことがある場合
                    </div>
                    <div className={`sub-content`}>
                      (6) その他、登録を適当でないと当社が判断した場合
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>第4条（登録事項の変更）</div>
                <div className='text'>
                  ユーザーは、登録事項に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。
                </div>
              </div>
              <div>
                <div className='title-chapter'>第5条（パスワード及びユーザーIDの管理）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    ユーザーは、自己の責任において、本サービスに関するパスワード及びユーザーIDを適切に管理及び保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。
                  </div>
                  <div className={`content`}>
                    2.
                    パスワードまたはユーザーIDの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任はユーザーが負うものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第6条（本サービスの機能）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    当社は、クリエイターに対し、本サービスにおいて、以下の機能の全部又は一部を提供します。
                    <div className={`sub-content`}>(1) ファンクラブサイト開設機能</div>
                    <div className={`sub-content`}>(2) 配信コンテンツ配信機能</div>
                    <div className={`sub-content`}>
                      (3) その他当社が本サービスにおいて有用と判断する機能
                    </div>
                  </div>
                  <div className={`content`}>
                    2.
                    当社は、一般ユーザーに対して、本サービスにおいて、以下の機能の全部又は一部を提供します。
                    <div className={`sub-content`}>(1) 配信コンテンツの視聴機能</div>
                    <div className={`sub-content`}>
                      (2)
                      本サービス上に質問、コメント、LIKE及び「いいね」等の投稿データを投稿する機能
                    </div>
                    <div className={`sub-content`}>
                      (3) その他当社が本サービスにおいて有用と判断する機能
                    </div>
                  </div>
                  <div className={`content`}>
                    3.
                    当社は、本サービスの機能を追加変更する場合があるものとし、ユーザーに重大な影響がない限り、事前通知をしないものとします。
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>第7条（ファンクラブ利用契約）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    一般ユーザーが本ウェブサイト上でファンクラブへの入会を申し込み、クリエイターが一般ユーザーに対しこれを承諾する旨の通知をした時点で、クリエイターと一般ユーザーとの間にファンクラブ利用契約が成立します。
                  </div>
                  <div className={`content`}>
                    2.
                    一般ユーザーは、ファンクラブ利用契約を締結することにより、ファンクラブ毎に設定されたファンクラブ会費の支払い義務が発生することに同意するものとします。
                  </div>
                  <div className={`content`}>
                    3.
                    各ファンクラブの料金体系及び支払方法は、別途本ウェブサイト上において定めるものとします。
                  </div>
                  <div className={`content`}>
                    4.
                    クリエイターは、別途当社が定める方法に従い、ファンクラブの内容若しくは料金体系等の全部又は一部の変更を行い、又はファンクラブの全部若しくは一部を終了させることができるものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第8条（ファンクラブ利用契約の期間等）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    ファンクラブ利用契約の期間は、一般ユーザーが当該ファンクラブに入会した日の翌月同日までとし、それ以降は１か月単位での自動更新契約とします。ただし、加入日に該当する暦日がない月の場合、月末日の前日までが契約期間となります。（例：
                    8月31日申し込みの場合、9月29日が契約最終日です）。なお、当該ファンクラブの会員（以下「ファンクラブ会員」といいます）が退会申請を行った場合、当該ファンクラブ利用契約は、退会申請を行った日を以って終了します。
                  </div>
                  <div className={`content`}>
                    2.
                    ファンクラブ利用契約の期間の途中で当該ファンクラブを退会した場合であっても、ファンクラブ会費は日割計算とはならず、ファンクラブ会員は、当月分のファンクラブ会費の返還を請求することはできません。
                  </div>
                  <div className={`content`}>
                    3.
                    ファンクラブ会費の支払を遅滞した場合（ポイントの残高不足等により決済手続がなされなかった場合を含む）、当該ファンクラブ会員は、自動的に、当該ファンクラブを退会したものとみなされます。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>
                  第9条（当社によるファンクラブ利用契約等の終了）
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    当社がクリエイター又は一般ユーザーのユーザー登録を抹消した場合、当該時点において、当該クリエイターと当該ファンクラブ会員との間の契約又は当該一般ユーザーと各登録クリエイターとの間の契約は全て終了します。
                  </div>
                  <div className={`content`}>
                    2.
                    前項の場合もファンクラブ会費は日割計算とはならず、ファンクラブ会員は、当月分のファンクラブ会費の返還を請求することはできません。
                  </div>
                  <div className={`content`}>
                    3.
                    本条によるファンクラブ利用契約の終了の場合であっても、本規約に基づく措置である限りにおいて当社は免責され、この場合、ユーザーは、当社に対して一切の損害賠償請求を行うことはできません。
                  </div>
                  <div className={`content`}>
                    4.
                    当社が特定のクリエイターのユーザー登録を抹消した場合、当社は、当該クリエイターが開設した全てのファンクラブサイト（配信コンテンツを含む）を削除することができるものとします。この場合でも、本規約に基づく措置である限りにおいて当社は免責され、当該クリエイターは、当社に対して一切の損害賠償請求を行うことはできません。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第10条（個別コンテンツ配信契約）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    クリエイターは、ファンクラブ利用契約における会員限定の配信コンテンツとは別に、別途定める方法により、ファンクラブサイト上にて価格・配信日時・その他の条件を設定のうえ、コンテンツ配信（以下「個別コンテンツ配信」といいます）を行うことができます。
                  </div>
                  <div className={`content`}>
                    2.
                    登録ユーザーは、当社が別途定める方法により、個別コンテンツ配信の視聴ないし利用を申込むことができ、これにより、当該クリエイターとの間で個別コンテンツ配信契約が成立します。
                  </div>
                  <div className={`content`}>
                    3.
                    クリエイターは、別途当社が定める方法に従い、個別コンテンツ配信の内容若しくは料金体系等の全部又は一部の変更を行い、又は当該コンテンツの全部若しくは一部の配信を終了させることができるものとします。
                  </div>
                  <div className={`content`}>
                    4.
                    当社判断による個別コンテンツ配信契約の終了については第９条の規定を準用するものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第11条（ポイントの購入及び取扱い）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    一般ユーザーは、本サービスの利用料金の支払い方法として、クレジットカード決済、銀行口座振替、その他当社の定める方法によりポイントを購入し、本サービスの利用に際して1ポイント＝1円相当額として利用できるものとします。その他、ポイントの内容および利用条件については、当社が別途定め、本サービスにおいて表示するところに従うものとします。
                  </div>
                  <div className={`content`}>
                    2.
                    一般ユーザーが保有することのできるポイントの上限は100,000ポイントとします。
                  </div>
                  <div className={`content`}>
                    3.
                    一般ユーザーは、有償ポイントの発行を受けた日から180日間のみ同ポイントを利用することができます。ユーザーは、一般無償ポイントを獲得した日から180日間もしくは付与前に別途当社が定めた日数の期間中にのみ同ポイントを利用することができます。有効期限を過ぎた未使用のポイントは消滅し、その後利用することはできません。
                  </div>
                  <div className={`content`}>
                    4.
                    当社は、理由のいかんを問わず、ポイントの払戻し又は換金を行いません。ただし、法令上必要な場合はこの限りではありません。この場合、ポイントの払戻し方法は、法令に従って当社が定め、別途通知するところによるものとします。
                  </div>
                  <div className={`content`}>
                    5.
                    当社は、別途当社が定める方法により、一般ユーザーが獲得したポイント、一般ユーザーが使用したポイントおよびポイントの残高（以下「ポイント額」といいます）を、一般ユーザーに告知します。一般ユーザーは、ポイント額に疑義のある場合には、直ちに当社に連絡し、その内容を説明するものとします。ポイント額に関する最終的な決定は当社が行うものとし、一般ユーザーはこれに従うものとします。
                  </div>
                  <div className={`content`}>
                    6.
                    ポイントの使用は、一般ユーザー本人が行うものとし、当該一般ユーザー本人以外の第三者が行うことはできません。第三者による不正使用であった場合でも、当社は、使用されたポイントを返還する義務を負わず、一般ユーザーに生じた損害について一切責任を負いません。
                  </div>
                  <div className={`content`}>
                    7.
                    ポイントの取得、ポイントの利用にともない、税金や付帯費用が発生する場合には、一般ユーザーがこれらを負担するものとします。
                  </div>
                  <div className={`content`}>
                    8.
                    理由のいかんを問わず、一般ユーザーが退会した場合又は一般ユーザー登録が抹消された場合には、当該一般ユーザーが保有するポイントは全て失効し、以後利用することはできないものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第12条（キャンペーン）</div>
                <div className='text'>
                  当社は、本サービスにおける各種キャンペーンへの参加その他当社が別途指定する行為を行った一般ユーザーに対し、ポイントを無償で付与することができるものとします。
                </div>
              </div>

              <div>
                <div className='title-chapter'>第13条（決済及び支払申請）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    クリエイターは、当社に対し、一般ユーザーよりファンクラブ会費及び個別コンテンツ配信契約利用料（以下「会費等」といいます）を代理受領する権限並びにかかる受領業務を当社の指定する第三者（以下、当社とあわせて「代理受領者」といいます。）に再委託する権限を付与するものとします。代理受領者が一般ユーザーから会費等を受領することにより、本規約に基づき一般ユーザーが負う会費等の支払債務は消滅するものとします。
                  </div>
                  <div className={`content`}>
                    2.
                    一般ユーザーは、会費等を直接にクリエイターに支払ってはなりません。一般ユーザーは、会費等を直接にクリエイターに支払ったことをもって、当社からの請求を拒絶することができないものとします。
                  </div>
                  <div className={`content`}>
                    3.
                    当社は、クリエイターに対し、別途当社が定める計算期間中において一般ユーザーが当該クリエイターに支払った会費等が別途当社の定める金額に達した場合には、振込手数料を控除した上で当該クリエイターの指定する口座に振り込む方法によって支払うものとします。なお、クリエイターは、当社が上記金員をドルで送金すること及びクリエイターが為替手数料を負担することを同意するものとします。
                  </div>
                  <div className={`content`}>
                    4.
                    クリエイターが、自らユーザー登録を抹消した場合又は第9条の規定に基づき登録を抹消された場合、当社は、当該クリエイターに対して請求できる会費等の一切を放棄したものとみなすことができるものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第14条（本サービスの利用手数料）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    クリエイターは、当社に対し、本サービスの利用の対価として、別途当社が定める利用手数料を当社に対して支払うものとします。
                  </div>
                  <div className={`content`}>
                    2.
                    当社は、前条第３項の規定に基づきクリエイターに対して支払う会費等から前項に定める利用手数料を差し引くことにより、利用手数料の受領に代えることができるものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第15条（ユーザーの責任）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    ユーザーは、他人の名誉を毀損した場合、プライバシー権を侵害した場合、許諾なく第三者の個人情報を開示した場合、著作権法に違反する行為を行った場合そのほか第三者の権利を侵害した場合には、自己の責任と費用においてこれを解決しなければならず、当社は一切の責任を負いません。
                  </div>
                  <div className={`content`}>
                    2.
                    当社は、ユーザーが本サービスを利用して本サービス内に蓄積、保存したデータ等の保管、保存、バックアップ等に関して、当社の責めに帰すべき事由による場合を除き、一切責任を負わないものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第16条（配信コンテンツの出演者）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    クリエイターは、配信コンテンツに当該クリエイター以外の第三者（以下「出演者」といいます）が出演する場合には、当社に対し、配信コンテンツが本サービスを通じ配信される前に、別途当社が定める方法で、当該出演者の氏名・年齢等の属性情報、公的機関が発行した当該出演者の顔写真付きの身分証明書及び当該顔写真付き身分証と当該出演者の顔とを並べた社員、当該クリエイター及び当該出演者に適用される法の要求に従って取得されるべき配信コンテンツへの出演・公衆配布・ダウンロード等に対する同意書等（以下「出演者情報等」といいます）を提出するものとします。
                  </div>
                  <div className={`content`}>
                    2.
                    クリエイターは、出演者に対し、当社に対して当該出演者の出演者情報が提供されることについて、事前に同意を得るものとします。{' '}
                  </div>
                  <div className={`content`}>
                    3.
                    クリエイターは、当社が要求する場合には、出演者情報の他に当社が要求する情報ないし資料等を遅滞なく提出するものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第17条（当社の権限及び責任）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    当社は、クリエイターの同意なく、配信コンテンツが本サービスを通じ配信される前に、当該コンテンツが適法かつ本規約に違反するものではないかを確認するものとし、クリエイターはそれに同意するものとします。
                  </div>
                  <div className={`content`}>
                    2.
                    当社は、クリエイターがリアルタイムの配信を行う場合には、当該配信コンテンツが適法かつ本規約に違反するものではないかリアルタイムでモニタリングするものとします。
                  </div>
                  <div className={`content`}>
                    3.
                    当社は、前２項の配信コンテンツが違法な意思本規約に違反するものであると判断できる場合、第２１条に定める措置を講ずることができるものとし、クリエイターは当該措置に同意するものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第18条（禁止事項）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。
                    <div className={`sub-content`}>
                      (1) 法令に違反する行為または犯罪行為に関連する行為
                    </div>
                    <div className={`sub-content`}>
                      (2)
                      当社、本サービスの他の利用者またはその他の第三者に対する詐欺または脅迫行為
                    </div>
                    <div className={`sub-content`}>
                      (3) 未成年者の身体が含まれる配信コンテンツの投稿
                    </div>
                    <div className={`sub-content`}>(4) 公序良俗に反する行為</div>
                    <div className={`sub-content`}>
                      (5)
                      当社、本サービスの他の利用者またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為
                    </div>
                    <div className={`sub-content`}>
                      (6)
                      本サービスを通じ、以下に該当し、または該当すると当社が判断する情報を当社または本サービスの他の利用者に送信すること
                      <div className={`sub-content dot`}>
                        過度に暴力的または残虐な表現を含む情報
                      </div>
                      <div className={`sub-content dot`}>
                        コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報
                      </div>
                      <div className={`sub-content dot`}>
                        当社、本サービスの他の利用者またはその他の第三者の名誉または信用を毀損する表現を含む情報
                      </div>
                      <div className={`sub-content dot`}>差別を助長する表現を含む情報</div>
                      <div className={`sub-content dot`}>
                        自殺、自傷行為を助長する表現を含む情報
                      </div>
                      <div className={`sub-content dot`}>
                        人身売買、性的虐待、児童搾取を助長する表現を含む情報
                      </div>
                      <div className={`sub-content dot`}>
                        薬物の不適切な利用及び過度な飲酒を助長する表現を含む情報
                      </div>
                      <div className={`sub-content dot`}>反社会的な表現を含む情報</div>
                      <div className={`sub-content dot`}>
                        チェーンメール等の第三者への情報の拡散を求める情報
                      </div>
                      <div className={`sub-content dot`}>
                        他人に不快感を与える表現を含む情報
                      </div>
                    </div>
                    <div className={`sub-content`}>
                      (7) 本サービスのネットワークまたはシステム等に過度な負荷をかける行為
                    </div>
                    <div className={`sub-content`}>
                      (8)
                      当社が提供するソフトウェアその他のシステムに対するリバースエンジニアリングその他の解析行為
                    </div>
                    <div className={`sub-content`}>
                      (9) 本サービスの運営を妨害するおそれのある行為
                    </div>
                    <div className={`sub-content`}>
                      (10) 当社のネットワークまたはシステム等への不正アクセス
                    </div>
                    <div className={`sub-content`}>(11) 第三者に成りすます行為</div>
                    <div className={`sub-content`}>
                      (12) 本サービスの他の利用者のIDまたはパスワードを利用する行為
                    </div>
                    <div className={`sub-content`}>
                      (13)
                      当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為
                    </div>
                    <div className={`sub-content`}>
                      (14) 本サービスの他の利用者の情報の収集
                    </div>
                    <div className={`sub-content`}>
                      (15)
                      当社、本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為
                    </div>
                    <div className={`sub-content`}>(16) 反社会的勢力等への利益供与</div>
                    <div className={`sub-content`}>
                      (17) 面識のない異性との出会いを目的とした行為
                    </div>
                    <div className={`sub-content`}>
                      (18) 前各号の行為を直接または間接に惹起し、または容易にする行為
                    </div>
                    <div className={`sub-content`}>(19) 前各号の行為を試みること</div>
                    <div className={`sub-content`}>
                      (20) その他、当社が不適切と判断する行為
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第19条（本サービスの停止等）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    当社は、以下のいずれかに該当する場合には、ユーザーに事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。
                    <div className={`sub-content`}>
                      (1)
                      本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合
                    </div>
                    <div className={`sub-content`}>
                      (2)
                      コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合
                    </div>
                    <div className={`sub-content`}>
                      (3)
                      地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
                    </div>
                    <div className={`sub-content`}>
                      (4) その他、当社が停止または中断を必要と判断した場合
                    </div>
                  </div>
                  <div className={`content`}>
                    2.
                    当社は、当社の都合により、本サービスの内容を変更し、又は本サービスの提供を終了することができるものとします。
                  </div>
                  <div className={`content`}>
                    3.
                    当社が本サービスの提供を終了する場合、当社は当社所定の方法で、ユーザーに事前に通知するものとします。
                  </div>
                  <div className={`content`}>
                    4.
                    当社は、本条に定める本サービスの停止等によってユーザーが被った一切の損害について、その理由の如何を問わず賠償する責任を負いません。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第20条（権利帰属）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    本ウェブサイト及び本サービスに関する知的財産権は全て当社または当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、本ウェブサイトまたは本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。
                  </div>
                  <div className={`content`}>
                    2.
                    ユーザーは、投稿データないし配信コンテンツ（以下「投稿データ等」といいます）について、自らが投稿その他送信することについての適法な権利を有していること、及び投稿データ等が第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。
                  </div>
                  <div className={`content`}>
                    3.
                    ユーザーは、投稿データ等について、当社に対し、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与します。また、他のユーザーに対しても、本サービスを利用してユーザーが投稿その他送信した投稿データ等の使用、複製、配布、派生著作物を作成、表示及び実行することについての非独占的なライセンスを付与します。
                  </div>
                  <div className={`content`}>
                    4.
                    ユーザーは、当社及び当社から権利を承継しまたは許諾された者に対して著作者人格権を行使しないことに同意するものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第21条（登録抹消等）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    当社は、ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、投稿データ等を削除もしくは非表示にし、当該ユーザーについて本サービスの利用を一時的に停止し、または当該ユーザーの登録を抹消することができます。
                    <div className={`sub-content`}>
                      (1) 本規約のいずれかの条項に違反した場合
                    </div>
                    <div className={`sub-content`}>
                      (2) 登録事項に虚偽の事実があることが判明した場合
                    </div>
                    <div className={`sub-content`}>
                      (3)
                      支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
                    </div>
                    <div className={`sub-content`}>
                      (4)
                      当社からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない場合
                    </div>
                    <div className={`sub-content`}>(5) 第3条第4項各号に該当する場合</div>
                    <div className={`sub-content`}>
                      (6)
                      その他、当社が本サービスの利用またはユーザーとしての登録の継続を適当でないと判断した場合
                    </div>
                  </div>
                  <div className={`content`}>
                    2.
                    前項各号のいずれかの事由に該当した場合、ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第22条（退会等）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    ユーザーは、当社所定の手続の完了により、本サービスから退会することができるものとし、ユーザーの場合は、自己のユーザーとしての登録を抹消することができます。
                  </div>
                  <div className={`content`}>
                    2.
                    退会にあたり、当社に対して負っている債務が有る場合は、ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
                  </div>
                  <div className={`content`}>
                    3. 退会後の利用者情報の取扱いについては、第15条の規定に従うものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第23条（契約終了後の処理）</div>
                <div className='text'>
                  当社は、理由の如何を問わず契約終了後、本サービスに係る全てのデータを削除できるものとします。
                </div>
              </div>

              <div>
                <div className='title-chapter'>第24条（削除申請手続）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    ユーザーは、申請フォーム（問い合わせURL）又は各投稿の「通報ボタン」を通じて、著作権侵害その他法令違反ないし本規約違反を理由として当社に対して投稿データ等の削除申請を行うことができます。
                  </div>
                  <div className={`content`}>
                    2.
                    当社は、前項の削除申請が合理的なものであると判断できる場合、当該削除申請があった日から７日以内に当該投稿データ等を削除するものとします。また、その場合、当社は削除申請があった日から１か月以内に当該削除申請者に対し、当社が講じた措置について報告するものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第25条（保証の否認及び免責）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    当社は、本サービスがユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、ユーザーによる本サービスの利用がユーザーに適用のある法令または業界団体の内部規則等に適合すること、継続的に利用できること、及び不具合が生じないことについて、明示又は黙示を問わず何ら保証するものではありません。
                  </div>
                  <div className={`content`}>
                    2.
                    当社は、本サービスに関してユーザーが被った損害につき、現実かつ直接に発生した通常の損害（特別損害、逸失利益、間接損害及び弁護士費用を除く。）の範囲内でのみ損害賠償責任を負うものとし、かつ損害賠償の額は1万円を上限とするものとします。
                  </div>
                  <div className={`content`}>
                    3.
                    当社は、本サービスが全ての情報端末に対応していることを保証するものではなく、本サービスの利用に供する情報端末のOSのバージョンアップ等に伴い、本サービスの動作に不具合が生じる可能性があることにつき、ユーザーはあらかじめ了承するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。
                  </div>
                  <div className={`content`}>
                    4.
                    本サービスまたは本ウェブサイトに関連してユーザーと他のユーザーまたは第三者との間において生じた取引、連絡、紛争等については、ユーザーが自己の責任によって解決するものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第26条（外部サービスの利用）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    ユーザーは、本サービスの利用にあたり、Facebook、Twitterその他の外部サービス等を利用する場合には、本規約のほか、当該外部サービスの定める利用規約その他の条件(当該利用規約等が変更された場合には変更後の条件を含みます。)に従うものとします。
                  </div>
                  <div className={`content`}>
                    2.
                    外部サービスについては、当該外部サービスを提供する企業又は個人が責任を負うものとし、当社は、当該外部サービスにつき、エラー、バグ、不具合又はセキュリティ上の欠陥が存しないこと、第三者の権利を侵害しないこと、ユーザーが期待する性質及び商品的価値を有すること、並びにユーザーに適用のある法令又は内部規則等に適合することについて、如何なる保証も行うものではありません。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第27条（秘密保持）</div>
                <div className='text'>
                  ユーザーは、本サービスに関連して当社がユーザーに対して秘密に取扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取扱うものとします。
                </div>
              </div>

              <div>
                <div className='title-chapter'>第28条（利用者情報の取扱い）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    当社によるユーザーの利用者情報の取扱いについては、別途当社プライバシーポリシー
                    {' ('}
                    <Link to='/privacy-policy' target='_blank' className='link_route'>
                      {getEnv('REACT_APP_FAN_URL')}/privacy-policy
                    </Link>
                    {') '}
                    の定めによるものとし、ユーザーはこのプライバシーポリシーに従って当社がユーザーの利用者情報を取扱うことについて同意するものとします。
                  </div>
                  <div className={`content`}>
                    2.
                    当社は、ユーザーが当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用及び公開することができるものとし、ユーザーはこれに異議を唱えないものとします。
                  </div>
                  <div className={`content`}>
                    3.
                    ユーザーは、投稿データに個人情報が含まれる場合、当該個人情報に係る個人に対して投稿データに含まれることについての同意を得なければならないものとします。ユーザーは、当該個人情報について自己の責任で善良なる管理者の注意をもって取り扱うものとし、当社はそれにできるかぎり協力するものとします。
                  </div>
                  <div className={`content`}>
                    4.
                    ユーザーは、投稿データに個人情報が含まれていたことに起因するクレーム、紛争等に対して、自己の責任と費用を持って対応するものとし、当社はこれに一切責任を負わないものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第29条（本規約等の変更）</div>
                <div className='text'>
                  当社は、当社が必要と認めた場合は、本規約を変更できるものとします。本契約を変更する場合、変更後の本規約の施行時期及び内容を本ウェブサイト上での掲示その他の適切な方法により周知し、またはユーザーに通知します。但し、法令上ユーザーの同意が必要となるような内容の変更の場合は、当社所定の方法でユーザーの同意を得るものとします。
                </div>
              </div>

              <div>
                <div className='title-chapter'>第30条（連絡／通知）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    本サービスに関する問い合わせその他ユーザーから当社に対する連絡または通知、及び本規約の変更に関する通知その他当社からユーザーに対する連絡または通知は、当社の定める方法で行うものとします。
                  </div>
                  <div className={`content`}>
                    2.
                    当社が登録事項に含まれるメールアドレスその他の連絡先に連絡または通知を行った場合、ユーザーは当該連絡または通知を受領したものとみなします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第31条（本ウェブサイトの利用終了）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    ユーザーは、本サービスのアカウントの削除その他当社所定の方法により、いつでも本サービスの利用を終了することができます。本サービスの利用を終了したユーザーは、当該終了時点から本サービスを利用することができなくなります。
                  </div>
                  <div className={`content`}>
                    2.
                    誤ってアカウントを削除した場合その他理由の如何を問わず、ユーザーが本サービスを利用する権利を失った場合、ユーザーは、アカウント、提供コンテンツユーザーコンテンツその他本サービスに蓄積した情報を利用することができなくなることを、あらかじめ承諾するものとします。
                  </div>
                  <div className={`content`}>
                    3.
                    ユーザーは、本サービス利用終了後も、当社及び第三者に対する本利用契約上の一切の義務及び債務(損害賠償を含みますが、これに限りません。)を免れるものではありません。
                  </div>
                  <div className={`content`}>
                    4.
                    当社は、ユーザーが本サービスの利用を終了した後も、当該ユーザーが当社に提供した情報を保有・利用することができるものとします。
                  </div>
                  <div className={`content`}>
                    5.
                    ユーザーが本サービスの利用を終了した場合、当社は、当社の裁量により、事前に通知することなく当該ユーザーのアカウントを削除することができるものとし、ユーザーは、終了時に残存している本ウェブサイト内の一切のコンテンツが消滅する場合があることに同意するものとします。
                  </div>
                  <div className={`content`}>
                    6.
                    ユーザーが死亡した場合、当該ユーザーのアカウント、ユーザー情報その他本ウェブサイトに蓄積した情報は削除されるものとし、本サービス及び本ウェブサイトの利用権は相続の対象とはなりません。
                  </div>
                  <div className={`content`}>
                    7.
                    当社は、当社の裁量により、あらかじめ通知することなく、最終のアクセスから6か月以上経過しているアカウントを削除することができるものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第32条（サービス利用契約上の地位の譲渡等）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
                  </div>
                  <div className={`content`}>
                    2.
                    当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びにユーザーの登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第33条（分離可能性）</div>
                <div className='text'>
                  本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
                </div>
              </div>

              <div>
                <div className='title-chapter'>第34条（準拠法及び管轄裁判所）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. 本規約及びサービス利用契約の準拠法はアメリカ合衆国ネヴァダ州法とします。
                  </div>
                  <div className={`content`}>
                    2.
                    本規約またはサービス利用契約に起因し、または関連する一切の紛争については、アメリカ合衆国ネヴァダ州地方裁判所を第一審の専属的合意管轄裁判所とします。
                  </div>
                </div>
              </div>
            </div>

            <div className='end-text'>
            【2023年06月19日制定】
            </div>
            <div className='end-text'>
            【2024年09月15日改定】
            </div>
          </div>
        </LayoutCenter>
      )}
    </>
  );
};

export default TermScreen;

import {
  AccountSidebar,
  HeaderListIdolV2,
  ImgCustom,
  LayoutCreatorRight,
  MenuSidebarCustom,
  PopupFanDetail,
  PopupLoading,
} from 'components';
import { InputWrapper } from 'components/FormLogin';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { newPathUser } from 'constants/layout/constant';
import withCreatorRole from 'hocs/withCreatorRole';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import {
  getContentBuyers,
  getSaleContents,
  handleResetListContentBuyers,
  getIDSearchBuyer,
  resetIDSearchBuyer,
  resetIDSearchBuyerNew,
} from 'store/actions/users';
import {
  CustomOption,
  convertToCurrentTime,
  customStyles,
  isAdmin,
  isAgency,
  linkS3,
} from 'utils';
import './index.scss';
import NoBuyer from 'images/NoSubscriber.svg';
import DefaultAvatar from 'images/DefaultAvatar.svg';
import { ArrowLeftIcon, ClearInputIcon, CloseIcon, MenuIcon, SearchIcon } from 'images';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { isMobile } from 'react-device-detect';
import { resetIDHomePage } from 'store/actions/usersNew';
import { useGetIdFromRecommendUser } from 'hooks/v2/useGetIdFromRecommendUser';

const customStylesReselect = {
  ...customStyles,

  singleValue: (base) => ({
    ...base,
    fontWeight: 500,
    fontSize: 14,
    position: 'absolute',
    padding: '2px 2px 2px 0',
    color: '#1a1a1a',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    display: 'block',
  }),
  control: (base, state) => {
    return {
      ...base,
      width: '187px',
      boxShadow: 'none',
      svg: {
        margin: '0 8px',
        color: '#666565',
        transform: state.menuIsOpen ? 'rotate(180deg)' : '',
      },
      borderColor: state.isFocused ? '#1480FF' : '#E5E6EB',
    };
  },
  menu: (base, state) => ({
    ...base,
    border: 'none !important',
    borderRadius: '8px',
    overflow: 'hidden',
    zIndex: 2,
    width: 187,
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.06)',
  }),
  option: (base, state) => {
    return {
      ...base,
      position: 'relative',
      backgroundColor: state.isSelected ? '#FFDBDB' : 'transparent',
      color: '#1A1A1A',

      '&.content-right': {
        color: '#DC143C',
      },
      svg: {
        color: '#DC143C',
        display: state.isSelected ? 'block' : 'none',
        position: 'absolute',
        right: 2,
        alignItems: 'center',
        margin: 'auto',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        marginLeft: '6px',
      },
      fontWeight: state.isSelected ? 500 : 400,
      padding: '12px 24px 12px 16px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      display: 'block',
    };
  },
};

const ListBuyer = () => {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageOptions, setPageOptions] = useState(1);
  const [isVisible, setIsVisible] = useState(false);
  const [idFan, seIdFan] = useState(false);
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(true);
  const [focusSort, setFocusSort] = useState(false);
  const [flgSearch, setFlgSearch] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);
  const { idFromRecommendUser, clearIdScrollBackRecommend } = useGetIdFromRecommendUser();
  const {
    listSaleContents,
    listContentBuyers = [],
    listContentBuyersPagination,
    listSaleContentsPagination,
    idBuyer,
    initPageBuyer,
    dataUser,
  } = useSelector((state) => state.users);
  const [page, setPage] = useState(initPageBuyer ? +initPageBuyer : 1);

  const postIDParams = searchParams.get('postID');
  const methodParams = searchParams.get('method');
  const keywordParams = searchParams.get('keyword');

  const initContentId = useMemo(() => {
    return state?.content_id ? state?.content_id : '';
  }, [state]);
  const content_id = window.sessionStorage.getItem('content_id');
  const [keyword, setKeyword] = useState(keywordParams || idBuyer?.SearchName || '');
  const [contentId, setContent] = useState(
    Number(postIDParams) ||
      (content_id
        ? Number(content_id)
        : idBuyer?.selectPost === 'all'
        ? null
        : idBuyer?.selectPost) ||
      'null'
  );
  const [selectedSort, setSelectedSort] = useState(
    methodParams || (idBuyer?.selectSale === 'all' ? null : idBuyer?.selectSale) || 'null'
  );
  const [method, setMethod] = useState(
    methodParams || (idBuyer?.selectSale === 'all' ? null : idBuyer?.selectSale) || 'null'
  );
  const optionListContent = listSaleContents.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  console.log('method', method);
  useEffect(() => {
    if (idBuyer) {
      const idPost = document.getElementById(idBuyer.id);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idBuyer]);
  // useEffect(() => {
  //   return () => dispatch(handleResetListContentBuyers());
  // }, []);

  const funCallApi = useCallback(
    (rs, search) => {
      setIsLoading1(true);
      page === 1 && setIsLoading(true);
      rs === 1 && setFirstLoading(true);
      dispatch(
        getContentBuyers(
          rs,
          10,
          search && !flgSearch && !postIDParams
            ? ''
            : content_id
            ? content_id
            : contentId === 'null'
            ? ''
            : contentId
            ? Number(contentId)
            : '',
          search && !flgSearch && !methodParams
            ? ''
            : method === 'null'
            ? ''
            : method
            ? method
            : '',
          search && !flgSearch && !keywordParams ? '' : keyword ? keyword : '',
          () => {
            setFirstLoading(false);
            setIsLoading(false);
            setIsLoading1(false);
          }
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, method, contentId, keyword]
  );

  useEffect(() => {
    //window.sessionStorage.removeItem('content_id');
    const top = document.getElementById('app_cocofans_0');
    if (initPageBuyer < 1) {
      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
      setIsLoading1(true);
      page === 1 && setFirstLoading(true);
      page === 1 && setIsLoading(true);
      dispatch(
        getContentBuyers(
          page,
          10,
          content_id
            ? content_id
            : contentId === 'null'
            ? ''
            : contentId
            ? Number(contentId)
            : '',
          method === 'null' ? '' : method ? method : '',
          keyword ? keyword : '',
          () => {
            setIsLoading(false);
            setFirstLoading(false);
            setIsLoading1(false);
          }
        )
      );
    }
  }, []);

  //GET PACKAGE OF CREATOR
  useEffect(() => {
    dispatch(getSaleContents(pageOptions, 20));
  }, [pageOptions]);

  const fetchNext = () => {
    const pages = listContentBuyersPagination?.total / listContentBuyersPagination?.per_page;
    if (pages > page) {
      funCallApi(page + 1, true);
      setPage(page + 1);
    }
  };
  const fetchOptionsNext = () => {
    const pages = listSaleContentsPagination?.total / listSaleContentsPagination?.per_page;
    if (pages > pageOptions) {
      setPageOptions(pageOptions + 1);
    }
  };

  const onChangeSelect = (value) => {
    window.sessionStorage.removeItem('content_id');
    dispatch(resetIDSearchBuyerNew());
    setContent(value.value);
  };

  const OptionsSelect = useMemo(
    () => [{ label: t('Creator.all'), value: '' }],
    [i18n.language]
  );

  const OPTION_SORT_PRICE = useMemo(
    () => [
      { label: t('Creator.all'), value: '' },
      {
        value: 'point',
        label: t('Purchase.point'),
      },
      {
        value: 'ticket',
        label: t('Purchase.premium'),
      },
    ],
    [i18n.language]
  );

  const onChangeSelectSort = (value) => {
    dispatch(resetIDSearchBuyerNew());
    console.log('aa', value.value);
    setMethod(value.value);
    setSelectedSort(value.value);
  };

  return (
    <>
      {/* {(isLoading) && <PopupLoading className={'popup-loading'} />} */}
      {isVisible && (
        <PopupFanDetail
          closeModal={() => setIsVisible(false)}
          isVisible={isVisible}
          id={idFan}
        />
      )}
      <LayoutCreatorRight
        noButtonBack
        titlePage={t('Common.buyerListForEachContent')}
        classContainer={`${listContentBuyers.length ? '' : 'data-empty'}`}
        className='list-buyer'>
        <div className='buyer-page-creator'>
          <form className='fansite-form form-buyer' onSubmit={(e) => e.preventDefault()}>
            <InputWrapper id='fsite-select'>
              <div className='select'>
                <Select
                  options={[OptionsSelect[0], ...optionListContent]}
                  value={
                    idBuyer?.selectPost === 'all' ||
                    (contentId === 'null' && idFromRecommendUser)
                      ? OptionsSelect[0]
                      : optionListContent?.find((e) => e.value === contentId)
                  }
                  defaultValue={
                    content_id ? { label: state?.title, value: state?.content_id } : ''
                  }
                  isSearchable={false}
                  isClearable={false}
                  onChange={onChangeSelect}
                  placeholder={t('Common.allPostTitle')}
                  width='60px'
                  className='select-buyer'
                  styles={{
                    ...customStyles,
                    singleValue: (provided, state) => {
                      const opacity = state.isDisabled ? 0.5 : 1;
                      return {
                        ...provided,
                        opacity,
                        fontWeight: 500,
                        fontSize: 14,
                        position: 'absolute',
                        padding: '2px 16px 2px 0',
                        color: '#282727',
                      };
                    },
                    control: (base, state) => ({
                      ...base,
                      minWidth: i18n.language === 'jp' ? 187 : 187,
                      width: 'max-content',
                      display: 'flex',
                      borderRadius: '6px',
                      boxShadow: 'none',
                      svg: {
                        color: '#666565',
                        transform: state.menuIsOpen ? 'rotate(180deg)' : '',
                      },
                      borderColor: state.isFocused ? '#1480FF' : '#E5E6EB',
                    }),
                    option: (base, state) => {
                      return {
                        ...base,
                        position: 'relative',
                        backgroundColor: state.isSelected ? '#FFDBDB' : 'transparent',
                        color: '#1A1A1A',
                        '&:hover': {
                          backgroundColor: '#FFDBDB',
                          color: '#282727',
                        },
                        '&.content-right': {
                          color: '#DC143C',
                        },
                        svg: {
                          color: '#DC143C',
                          display: state.isSelected ? 'block' : 'none',
                          position: 'absolute',
                          right: 2,
                          alignItems: 'center',
                          margin: 'auto',
                          top: '50%',
                          transform: 'translate(-50%, -50%)',
                          marginLeft: '6px',
                        },
                        fontWeight: state.isSelected ? 500 : 400,
                        padding: '12px 24px 12px 16px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                        display: 'block',
                      };
                    },
                  }}
                  components={{
                    Option: CustomOption,
                    DropdownIndicator: () => <ArrowLeftIcon style={{ marginRight: '5px' }} />,
                  }}
                  captureMenuScroll={(e) => {}}
                  onMenuScrollToBottom={fetchOptionsNext}
                />
              </div>
            </InputWrapper>
            <InputWrapper id='fsite-select'>
              <div
                className={`select`}
                onBlur={() => setFocusSort(false)}
                onClick={() => setFocusSort(!focusSort)}>
                <Select
                  id='sortBox'
                  options={OPTION_SORT_PRICE}
                  isSearchable={false}
                  value={
                    idBuyer?.selectSale === 'all' || (method === 'null' && idFromRecommendUser)
                      ? OPTION_SORT_PRICE[0]
                      : OPTION_SORT_PRICE?.find((e) => e.value === selectedSort)
                  }
                  isClearable={false}
                  onChange={onChangeSelectSort}
                  className='select-buyer'
                  placeholder={t('Common.allSales')}
                  styles={{
                    ...customStyles,
                    singleValue: (provided, state) => {
                      const opacity = state.isDisabled ? 0.5 : 1;
                      return {
                        ...provided,
                        opacity,
                        fontWeight: 500,
                        fontSize: 14,
                        position: 'absolute',
                        padding: '2px 16px 2px 0',
                        color: '#282727',
                      };
                    },
                    control: (base, state) => ({
                      ...base,
                      minWidth: i18n.language === 'jp' ? 187 : 187,
                      width: 'max-content',
                      display: 'flex',
                      borderRadius: '6px',
                      boxShadow: 'none',
                      svg: {
                        color: '#666565',
                        transform: state.menuIsOpen ? 'rotate(180deg)' : '',
                      },
                      borderColor: state.isFocused ? '#1480FF' : '#E5E6EB',
                    }),
                  }}
                  components={{
                    Option: CustomOption,
                    DropdownIndicator: () => <ArrowLeftIcon style={{ marginRight: '5px' }} />,
                  }}
                />
              </div>
            </InputWrapper>
            <div className='form-search'>
              <div className='form-item'>
                <div className='icon-container'>
                  <SearchIcon className='search-icon' />
                  {!!keyword?.trim() && (
                    <ClearInputIcon
                      className='clear-icon'
                      onClick={() => {
                        setFlgSearch(false);
                        setKeyword('');
                      }}
                    />
                  )}
                </div>
                <input
                  className={`${!!keyword ? 'is_input' : ''} input-field`}
                  id='id-input-search'
                  type='text'
                  value={keyword}
                  placeholder={t('Common.searchName')}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                />
              </div>
              <div
                className='button-search'
                onClick={() => {
                  navigate(`/buyer?postID=${contentId}&method=${method}&keyword=${keyword}`);
                  setFlgSearch(true);
                  setPage(1);
                  funCallApi(1, false);
                }}>
                {t('Search.title')}
              </div>
            </div>
          </form>
          {firstLoading ? (
            <div className='shimmer-loading'>
              <ShimmerPostDetails />
            </div>
          ) : (
            <>
              {!!listContentBuyers.length ? (
                <InfiniteLoad
                  loading={isLoading && listContentBuyers?.length > 0}
                  data={listContentBuyers || []}
                  fetchNext={fetchNext}>
                  <div
                    className='d-flex'
                    style={{
                      padding: '0 24px',
                      color: '#1a1a1a',
                      fontWeight: '500',
                      marginTop: '-5px',
                    }}>
                    <span>{t('Creator.numberList')}</span>
                    <span style={{ marginLeft: '3px' }}>
                      {listContentBuyersPagination?.total}
                      {i18n.language === 'en' && ' '}
                      {t('Creator.recore')}
                    </span>
                  </div>
                  <div className='list-buyer-creator'>
                    {listContentBuyers.map((item, index) => (
                      <div className='account-buyer account' key={index} id={item?.post_id}>
                        <div className={`content-left`}>
                          <ImgCustom
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch(resetIDHomePage());
                              dispatch(
                                getIDSearchBuyer({
                                  id: item?.post_id,
                                  selectPost: contentId ? contentId : 'all',
                                  selectSale: method ? method : 'all',
                                  SearchName: keyword,
                                  page: page,
                                })
                              );
                              navigate(`${newPathUser}${item?.account_id}`);
                            }}
                            src={item?.avatar ? `${item?.avatar}` : DefaultAvatar}
                            alt='avatar'
                            screen={item?.avatar ? 'recommend_top_56_56' : ''}
                          />
                          <span
                            style={{ cursor: 'pointer' }}
                            className='name fw-bold'
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch(
                                getIDSearchBuyer({
                                  id: item?.post_id,
                                  selectPost: contentId ? contentId : 'all',
                                  selectSale: method ? method : 'all',
                                  SearchName: keyword,
                                  page: page,
                                })
                              );
                              navigate(`${newPathUser}${item?.account_id}`);
                            }}>
                            {item?.account_name ||
                              item?.full_name ||
                              t('Creator.accountNoName')}
                          </span>
                        </div>
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => {
                            dispatch(
                              getIDSearchBuyer({
                                id: item?.post_id,
                                selectPost: contentId ? contentId : 'all',
                                selectSale: method ? method : 'all',
                                SearchName: keyword,
                                page: page,
                              })
                            );
                            navigate(`${newPathUser}post/${item?.post_id}`);
                          }}>
                          <div className='mt-3 d-flex'>
                            <div className='lable-text me-1'>
                              {t('PostDetail.productDesc')}:
                            </div>
                            <span className='value-text max-title'>{item?.content}</span>
                          </div>
                          <div className='mt-2 d-flex'>
                            <div className='lable-text me-1'>
                              {t('Creator.BuyerList.price')}:
                            </div>
                            <span className='value-text'>
                              {item?.number_premium_ticket
                                ? item?.number_premium_ticket
                                : item?.price}
                              {i18n.language === 'en' && ' '}
                              {item?.price
                                ? 'pt'
                                : t('Creator.PostArticle.postContent.afterContentCondition')}
                            </span>
                          </div>
                          {!!item?.price && (
                            <div className='mt-2 d-flex'>
                              <div className='lable-text me-1'>
                                {t('Creator.BuyerList.discount')}:
                              </div>
                              {item?.discount ? (
                                <span className='value-text'>{item?.discount}%</span>
                              ) : (
                                '-'
                              )}
                            </div>
                          )}
                          <div className='mt-2 d-flex'>
                            <div className='lable-text me-1'>
                              {t('Creator.BuyerList.purchaseDate')}:
                            </div>
                            <span className='value-text'>
                              {convertToCurrentTime(item?.purchased_at, 'YYYY/MM/DD HH:mm')}
                            </span>
                          </div>
                          <div className='border-card'></div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* {isLoading1 && listContentBuyers?.length > 0 && (
                    <div className='loading-dots-custom'>
                      <div className={`lds-ellipsis`}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  )} */}
                </InfiniteLoad>
              ) : (
                <div className='no-data'>
                  <img src={NoBuyer} alt='NoBuyer Icon' />
                  {t('Creator.BuyerList.thereAreNoEligibleContentBuyers')}
                </div>
              )}
            </>
          )}
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(ListBuyer);

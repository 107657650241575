import { LayoutCenter } from 'components';
import { useTranslation } from 'react-i18next';
import { POLICY, POLICY_JP } from '../constant';
import './styles.scss';
import { Link } from 'react-router-dom';
import { newPathUser } from 'constants/layout/constant';
import { STORAGE } from 'utils';
import Storage from 'utils/storage';

const PrivacyScreen = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      {i18n.language === 'en' ? (
        <LayoutCenter
          titlePage={POLICY.title_page}
          notLogin={true}
          isShowLeftIcon={false}
          className={'title-page-creator page-legal'}
          uiMobile={true}>
          <div className={`container-fluid w-100 bg-white policy-page creator`}>
            <div>{POLICY?.desc || ''}</div>
            <div>
              <div>
                <div className='title-chapter'>
                  1. User information to be collected and collection method
                </div>
                <div className='text'>
                  For the purpose of the Policy, “user information” refers to information
                  pertaining to the user's identification, behavioral history on communication
                  services, and other information generated or accumulated in connection with
                  the users on their devices, which is collected by the Company in accordance
                  with this policy.
                </div>
                <div className='text'>
                  The user information collected by the Company in this service is as follows,
                  depending on the method of collection:
                </div>
                <div>
                  <div className={`content nodot`}>
                    (1) Information provided by users
                    <div className='ms-4'>
                      The following information is provided by users in order to use the Service or through the use of the Service:
                    </div>
                    <div className='ms-4'>
                      -  Name, date of birth, gender, occupation, and other profile information
                    </div>
                    <div className='ms-4'>
                      -  Contact information, such as email address, telephone number, address, etc.
                    </div>
                    <div className='ms-4'>
                      -  Information regarding payment methods, such as credit card information, bank account information, and electronic money information
                    </div>
                    <div className='ms-4'>
                      -  Information entered or sent by the user through input forms or other methods specified by the Company
                    </div>
                  </div>
                  <div className={`content nodot`}>
                    (2) Information provided by other services when users permit linking with other services when using the Service
                    <div className='ms-4'>
                    If users permit linking with other services, such as social networking services, when using the Service, the following information will be collected from the relevant external service based on the consent given at the time of permission:
                    </div>
                    <div className='ms-4'>
                      - ID used by the user in the relevant external service
                    </div>
                    <div className='ms-4'>
                      - Other information that the user has permitted disclosure to the partner through the privacy settings of the relevant external service
                    </div>
                  </div>
                  <div className={`content nodot`}>
                    (3) Information collected by the Company when users use the Service
                    <div className='ms-4'>The Company may collect information about the access status and usage of the Service, including the following information:</div>
                    <div className='ms-4'>-  Referrer</div>
                    <div className='ms-4'>-  IP address</div>
                    <div className='ms-4'>-  Information regarding server access logs</div>
                    <div className='ms-4'>
                      -  Cookies, ADID, IDFA and other identifiers
                    </div>
                  </div>
                  <div className={`content nodot`}>
                    (4) Information that the Company collects based on the users’ individual consent when users use the Service
                    <div className='ms-4'>If the users gives individual consent in the manner specified in 3-1, the Company will collect the following information from the device being used:</div>
                  </div>
                  <div className={`ms-4`}>
                    {/* {'\n'} */}
                    <div className='ms-4'>- Location information</div>
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>2. Purpose of use</div>
                <div className='text'>
                  The specific purposes for which User Information is used in providing the
                  Service are as follows:
                </div>
                <div>
                  <div className={`content nodot`}>
                    (1) To provide, maintain, protect and improve the Service, including
                    accepting registrations for the Service, verifying identity, authenticating
                    users, recording user settings, and calculating usage fees
                  </div>
                  <div className={`content nodot`}>
                    (2) To improve the quality of the Services
                  </div>

                  <div className={`content nodot`}>
                    (3) To measure user traffic and behavior
                  </div>
                  <div className={`content nodot`}>
                    (4) To distribute, display advertisements and measure their effectiveness
                  </div>
                  <div className={`content nodot`}>
                    (5) To provide information about the Service and respond to inquiries, etc.
                  </div>
                  <div className={`content nodot`}>
                    (6) To conduct market research, analysis, marketing and survey research
                  </div>
                  <div className={`content nodot`}>
                    (7) To create statistical data that has been processed into a format that
                    does not identify individuals
                  </div>
                  <div className={`content nodot`}>
                    (8) To respond to any violations of our terms, policies, etc. regarding
                    this service (hereinafter referred to as the “Terms and Conditions, etc.”)
                  </div>
                  <div className={`content nodot`}>
                    (9) To notify users of changes to the terms of the Service
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>
                  3. Method of notification, public announcement or obtaining consent, and
                  method of requesting suspension of use
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content nodot`}>
                    3-1 The following user information shall be subject to user consent prior
                    to its collection:
                    <div className='ms-4'>- Location information.</div>
                  </div>
                  <div className={`content nodot`}>
                    3-2 The user may request the suspension of collection or use of all or part
                    of the user information by configuring the designated settings of the
                    Service, in which case the Company will promptly suspend the use in
                    accordance with the Company's regulations. Additionally, since the
                    collection or use of some user information is a prerequisite for the
                    Service, the Company will suspend the collection or use of such information
                    only if the user unsubscribes from the Service in a manner designated by
                    the Company.
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>
                  4. Existence of external transmission, provision to third parties, or
                  information collection modules
                </div>
                <div className='text'>
                  To monitor how the Service is being used, the Service uses Google Analytics,
                  which utilizes cookies to collect information about users. Please refer to
                  the Google Analytics website for the Google Analytics Terms of Use and
                  Privacy Policy. Please note that the Company is not be liable for any damages
                  resulting from the use of Google Analytics services.
                </div>
                <div></div>
              </div>

              <div>
                <div className='title-chapter'>5. Provision to third parties</div>
                <div className='text'></div>
                <div>
                  <div className={`content nodot`}>
                    5-1 The Company will not provide personal information, among other user
                    information, to a third party (including those located outside Japan)
                    without the prior consent of users. However, this does not apply when it is
                    necessary to provide such information to a third party (including those
                    outside Japan) for the following reasons:
                    <div className='sub-content nodot'>
                      (1) When the Company outsources all or part of the handling of personal
                      information to a third party within the scope necessary to achieve the
                      purpose of use
                    </div>
                    <div className='sub-content nodot'>
                      (2) When personal information is provided in connection with business
                      succession due to a merger or other reasons.
                    </div>
                    <div className='sub-content nodot'>
                      (3) When personal information is provided to a business partner or
                      information collection module provider in accordance with the provisions
                      of Section 4.
                    </div>
                    <div className='sub-content nodot'>
                      (4) When it is necessary to cooperate with a national agency, a local
                      government, or an individual or entity entrusted by either a national
                      agency or local government to execute affairs prescribed by law, and
                      obtaining the consent of the user may impede the execution of such
                      affairs
                    </div>
                    <div className='sub-content nodot'>
                      (5) In other cases as permitted by the Act on the Protection of Personal
                      Information (hereinafter referred to as the "Personal Information
                      Protection Act") or other cases permitted by laws and regulations
                    </div>
                  </div>
                  <div className={`content nodot`}>
                    5-2 Notwithstanding Paragraph 1, for the purpose of quality improvement of
                    the Service, market research, analysis, marketing and survey research, the
                    Company may provide user information that has been irreversibly and
                    appropriately processed so that individual users cannot be identified to
                    third parties with whom we do business through data transmission, magnetic
                    media, paper, and oral communication. The users agree in advance to such
                    provision to a third party.
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>6. Disclosure of personal information</div>
                <div className='text'>
                  When users request disclosure of personal information in accordance with the
                  provisions of the Personal Information Protection Law, the Company will
                  confirm that the request is made by users themselves and disclose the
                  information to them without delay (if the relevant personal information does
                  not exist, the Company will notify the user to that effect). However, this
                  does not apply to cases in which the Company is not obligated to disclose the
                  information under the Personal Information Protection Law or other laws and
                  regulations. Please note that a fee (1,000 yen per case) will be charged for
                  the disclosure of personal information
                </div>
                <div></div>
              </div>

              <div>
                <div className='title-chapter'>
                  7. Correction and Suspension of Use of Personal Information
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content nodot`}>
                    7-1 When user request the Company to correct the content of personal
                    information in accordance with the provisions of the Personal Information
                    Protection Law because (1) the personal information is untrue, or (2) the
                    personal information has been handled beyond the scope of the purpose of
                    use publicly announced in advance or has been collected through deception
                    or other wrongful means, the Company will conduct any necessary
                    investigation without delay after confirming that the request is from users
                    themselves, correct the contents of the personal information or suspend the
                    use based on the results, and notify them to that effect. If the Company
                    decides not to correct or suspend the use, the Company will notify the user
                    to that effect.
                  </div>
                  <div className={`content nodot`}>
                    7-2 When users request deletion of their personal information, and the
                    Company deems it necessary to comply with the request, the Company will
                    delete the personal information after confirming that the request is made
                    by users themselves, and notify them to that effect.
                  </div>
                  <div className={`content nodot`}>
                    7-3 If the Company is not obligated to correct or suspend use under the
                    Personal Information Protection Act or other laws and regulations, the
                    provisions of 7-1 and 7-2 shall not apply.
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>8. Contact information</div>
                <div className='content nodot'>
                  Please direct any comments, questions, complaints, or other inquiries
                  regarding the handling of user information to the contact listed at the
                  following URL:{' '}<br></br>URL:&nbsp;
                  {!!Storage.get(STORAGE.USER_ACCESS_TOKEN) ? (
                    <Link to={`${newPathUser}help`} target='_blank'  style={{ color: "#1480FF "}}>
                       https://share-gram.com/help
                    </Link>
                  ) : (
                    <Link to={`/sign-in`} target='_blank'  style={{ color: "#1480FF "}}>
                      https://share-gram.com/help
                    </Link>
                  )}
                </div>
              </div>

              <div>
                <div className='title-chapter'>
                  9. Procedures for changing the privacy policy
                </div>
                <div className='text'>
                  The Company will change the Policy as necessary. However, in the event that
                  the Company modifies this Policy in a manner that requires the consent of
                  users under the law, the modified Policy shall apply only to those users who
                  have agreed to the modification in the manner prescribed by the Company. In
                  the event of any modification of this Policy, the Company will notify users
                  or publicize the effective date and content of the modified Policy by
                  displaying the modified Policy on its website or by any other appropriate
                  means.
                </div>
              </div>
            </div>
            <div className='end-text'>[Established on June 2, 2023]</div>
            <div className='end-text'>[Revised on September 15, 2024]</div>
          </div>
        </LayoutCenter>
      ) : (
        <LayoutCenter
          titlePage={POLICY_JP.title_page}
          notLogin={true}
          isShowLeftIcon={false}
          className={'title-page-creator page-legal'}
          uiMobile={true}>
          <div className={`container-fluid w-100 bg-white policy-page creator`}>
            <div>{POLICY_JP?.desc || ''}</div>
            <div>
              <div>
                <div className='title-chapter'>1. 収集する利用者情報及び収集方法</div>
                <div className='text'>
                  本ポリシーにおいて、「利用者情報」とは、ユーザーの識別に係る情報、通信サービス上の行動履歴、その他ユーザーまたはユーザーの端末に関連して生成または蓄積された情報であって、本ポリシーに基づき当社が収集するものを意味するものとします。
                </div>
                <div className='text'>
                  本サービスにおいて当社が収集する利用者情報は、その収集方法に応じて、以下のようなものとなります。
                </div>
                <div>
                  <div className={`content nodot`}>
                    (1) ユーザーからご提供いただく情報
                    <div className='ms-4'>本サービスを利用するために、または本サービスの利用を通じてユーザーからご提供いただく情報は以下のとおりです。</div>
                    <div className='sub-content'>
                      氏名、生年月日、性別、職業等プロフィールに関する情報
                    </div>
                    <div className='sub-content'>
                      メールアドレス、電話番号、住所等連絡先に関する情報
                    </div>
                    <div className='sub-content'>
                      クレジットカード情報、銀行口座情報、電子マネー情報等決済手段に関する情報
                    </div>
                    <div className='sub-content'>
                      入力フォームその他当社が定める方法を通じてユーザーが入力または送信する情報
                    </div>
                  </div>
                  <div className={`content nodot`}>
                    (2) ユーザーが本サービスの利用において、他のサービスと連携を許可することにより、当該他のサービスからご提供いただく情報
                    <div className='ms-4'>ユーザーが、本サービスを利用するにあたり、ソーシャルネットワーキングサービス等の他のサービスとの連携を許可した場合には、その許可の際にご同意いただいた内容に基づき、以下の情報を当該外部サービスから収集します。</div>
                    <div className='sub-content'>当該外部サービスでユーザーが利用するID</div>
                    <div className='sub-content'>
                      その他当該外部サービスのプライバシー設定によりユーザーが連携先に開示を認めた情報
                    </div>
                  </div>
                  <div className={`content nodot`}>
                    (3) ユーザーが本サービスを利用するにあたって、当社が収集する情報
                    <div className='ms-4'>当社は、本サービスへのアクセス状況やそのご利用方法に関する情報を収集することがあります。これには以下の情報が含まれます。</div>
                    <div className='sub-content'>リファラ</div>
                    <div className='sub-content'>IPアドレス</div>
                    <div className='sub-content'>サーバーアクセスログに関する情報</div>
                    <div className='sub-content'>Cookie、ADID、IDFAその他の識別子</div>
                  </div>
                  <div className={`content nodot`}>
                    (4) ユーザーが本サービスを利用するにあたって、当社がユーザーの個別同意に基づいて収集する情報
                    <div className='ms-4'>当社は、ユーザーが3-1に定める方法により個別に同意した場合、当社は以下の情報を利用中の端末から収集します。</div>
                    <div className='sub-content'>位置情報</div>
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>2. 利用目的</div>
                <div className='text'>
                  本サービスのサービス提供にかかわる利用者情報の具体的な利用目的は以下のとおりです。
                </div>
                <div>
                  <div className={`content nodot`}>
                    (1)
                    本サービスに関する登録の受付、本人確認、ユーザー認証、ユーザー設定の記録、利用料金の決済計算等本サービスの提供、維持、保護及び改善のため
                  </div>
                  <div className={`content nodot`}>(2) 本サービスの品質向上のため</div>

                  <div className={`content nodot`}>
                    (3) ユーザーのトラフィック測定及び行動測定のため
                  </div>
                  <div className={`content nodot`}>(4) 広告の配信、表示及び効果測定のため</div>
                  <div className={`content nodot`}>
                    (5) 本サービスに関するご案内、お問い合わせ等への対応のため
                  </div>
                  <div className={`content nodot`}>
                    (6) 市場調査、分析、マーケティング及び調査研究のため
                  </div>
                  <div className={`content nodot`}>
                    (7) 個人を識別できない形式に加工した統計データを作成するため
                  </div>
                  <div className={`content nodot`}>
                    (8)
                    本サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
                  </div>
                  <div className={`content nodot`}>
                    (9) 本サービスに関する規約等の変更などを通知するため
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>
                  3. 通知・公表または同意取得の方法、利用中止要請の方法
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content nodot`}>
                    3-1
                    以下の利用者情報については、その収集が行われる前にユーザーの同意を得るものとします。
                    <div className='sub-content'>位置情報</div>
                  </div>
                  <div className={`content nodot`}>
                    3-2
                    ユーザーは、本サービスの所定の設定を行うことにより、利用者情報の全部または一部についてその収集又は利用の停止を求めることができ、この場合、当社は速やかに、当社の定めるところに従い、その利用を停止します。なお利用者情報の項目によっては、その収集または利用が本サービスの前提となるため、当社所定の方法により本サービスを退会した場合に限り、当社はその収集又は利用を停止します。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>
                  4. 外部送信、第三者提供、情報収集モジュールの有無
                </div>
                <div className='text'>
                  本サービスでは、本サービスの利用状況を把握するためにGoogle
                  Analyticsを利用しています。Google
                  Analyticsは、クッキーを利用して利用者の情報を収集します。Google
                  Analyticsの利用規約及びプライバシーポリシーに関する説明については、Google
                  Analyticsのサイトをご覧ください。なお、Google
                  Analyticsのサービス利用による損害については、当社は責任を負わないものとします
                </div>
                <div></div>
              </div>

              <div>
                <div className='title-chapter'>5. 第三者提供</div>
                <div className='text'></div>
                <div>
                  <div className={`content nodot`}>
                    5-1
                    当社は、利用者情報のうち、個人情報については、あらかじめユーザーの同意を得ないで、第三者（日本国外にある者を含みます。）に提供しません。但し、次に掲げる必要があり第三者（日本国外にある者を含みます。）に提供する場合はこの限りではありません。
                    <div className='sub-content nodot'>
                      (1)
                      当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
                    </div>
                    <div className='sub-content nodot'>
                      (2) 合併その他の事由による事業の承継に伴って個人情報が提供される場合
                    </div>
                    <div className='sub-content nodot'>
                      (3)
                      第4項の定めに従って、提携先または情報収集モジュール提供者へ個人情報が提供される場合
                    </div>
                    <div className='sub-content nodot'>
                      (4)
                      国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合
                    </div>
                    <div className='sub-content nodot'>
                      (5)
                      その他、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）その他の法令で認められる場合
                    </div>
                  </div>
                  <div className={`content nodot`}>
                    5-2
                    当社は、第1項にかかわらず、本サービスの品質向上、市場調査、分析、マーケティング及び調査研究を目的とし、ユーザー個人を特定できないよう不可逆的かつ適切な加工を行った利用者情報を、当社と取引のある第三者に対し、データ送信、磁気媒体、紙面及び口頭等の方法により提供する場合があり、ユーザーはかかる第三者提供につき予め同意するものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>6. 個人情報の開示</div>
                <div className='text'>
                  当社は、ユーザーから、個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、手数料（1件あたり1,000円）を頂戴しておりますので、あらかじめ御了承ください。
                </div>
              </div>

              <div>
                <div className='title-chapter'>7. 個人情報の訂正及び利用停止等</div>
                <div className='text'></div>
                <div>
                  <div className={`content nodot`}>
                    7-1
                    当社は、ユーザーから、(1)個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び(2)あらかじめ公表された利用目的の範囲を超えて取扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、ユーザーご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をユーザーに通知します。なお、訂正または利用停止を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします。
                  </div>
                  <div className={`content nodot`}>
                    7-2当社は、ユーザーから、ユーザーの個人情報について消去を求められた場合、当社が当該請求に応じる必要があると判断した場合は、ユーザーご本人からのご請求であることを確認の上で、個人情報の消去を行い、その旨をユーザーに通知します。
                  </div>
                  <div className={`content nodot`}>
                    7-3個人情報保護法その他の法令により、当社が訂正等または利用停止等の義務を負わない場合は、7-1および7-2の規定は適用されません。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>8. お問い合わせ窓口</div>
                <div className='content nodot'>
                  ご意見、ご質問、苦情のお申出その他利用者情報の取扱いに関するお問い合わせは、下記のURLに記載する窓口までお願いいたします。
                  URL:<br></br>URL:&nbsp;
                  {!!Storage.get(STORAGE.USER_ACCESS_TOKEN) ? (
                    <Link to={`${newPathUser}help`} target='_blank' style={{ color: "#1480FF "}}>
                      https://share-gram.com/help
                    </Link>
                  ) : (
                    <Link to={`/sign-in`} target='_blank' style={{ color: "#1480FF "}}>
                      https://share-gram.com/help
                    </Link>
                  )}
                </div>
              </div>

              <div>
                <div className='title-chapter'>9. プライバシーポリシーの変更手続</div>
                <div className='text'>
                  当社は、必要に応じて、本ポリシーを変更します。但し、法令上ユーザーの同意が必要となるような本ポリシーの変更を行う場合、変更後の本ポリシーは、当社所定の方法で変更に同意したユーザーに対してのみ適用されるものとします。なお、当社は、本ポリシーを変更する場合には、変更後の本ポリシーの施行時期及び内容を当社のウェブサイト上での表示その他の適切な方法により周知し、またはユーザーに通知します。
                </div>
              </div>
            </div>
            <div className='end-text'>[2023年06月02日制定]</div>
            <div className='end-text'>[2024年09月15日改定]</div>
          </div>
        </LayoutCenter>
      )}
    </>
  );
};

export default PrivacyScreen;

import { LayoutCenter, LayoutCreatorRight, PopupConfirmFan, PopupLoading } from 'components';
import { newPathUser } from 'constants/layout/constant';
import withCreatorRole from 'hocs/withCreatorRole';
import { ArrowLeftIcon } from 'images';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { getListCategoryFeedBack, PostFeedBack, resetStatus } from 'store/actions/feedback';
import {
  CustomOption,
  customStyles,
  isEmptyObject,
  patternEmail,
  patternFullWidthJp,
} from 'utils/utils';
import './styles.scss';
import { isMobile } from 'react-device-detect';
import { InputWrapper } from 'components/Input/InputWrapper';
import { STORAGE } from 'utils';
import Storage from 'utils/storage';
import ReCAPTCHA from "react-google-recaptcha";
import { getEnv } from 'configs/env';

const PostContractCreator = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    control,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });
  const [focusCategory, setFocusCategory] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isSearchable] = useState(false);
  const watchText = watch('question_content', '');
  const watchCategory = watch('category_content', '');
  const watchEmail = watch('email', '');
  const watchName = watch('name', '');
  const { t, i18n } = useTranslation();
  const {
    listCategoryFeedsBack = [],
    loading,
    status,
  } = useSelector((state) => state.feedback);
  const optionListContent = listCategoryFeedsBack.map((item) => ({
    ...item,
    value: item?.id,
    label: item?.name,
  }));
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);

  useEffect(() => {
    if (isAuth) {
      window.location.href = `/not-found`;
    }
  }, []);

  useEffect(() => {
    if (watchCategory) {
      clearErrors('category_content');
    }
  }, [errors, watchCategory]);

  const onSubmit = (data) => {
    const newData = {
      ...data,
      category_title: t('Creator.Post.category'),
      question_title: t('Help.inquiry'),
      language: i18n.language === 'en' ? 'en' : 'ja',
    };
    dispatch(PostFeedBack(newData, true));
  };
  const [isVerified, setVerified] = useState(false);

  const handleVerify = (response) => {
    if (response) {
      setVerified(true);
    }
  };

  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(key + 1);
  }, [i18n.language]);

  useEffect(() => {
    dispatch(getListCategoryFeedBack(i18n.language));

    return () => dispatch(resetStatus());
  }, [dispatch, i18n.language]);

  useEffect(() => {
    status === true && setIsOpenModal(true);
  }, [status]);

  const customStylesSelect = {
    ...customStyles,

    control: (base, state) => ({
      ...base,
      width: '100%',
      maxHeight: 60,
      boxShadow: 'none',

      borderColor: state.isFocused ? '#1480FF' : '#E5E6EB',
      svg: {
        color: '#666565',
        transform: state.menuIsOpen ? 'rotate(180deg)' : '',
      },
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      display: 'block',
      paddingRight: '20px',
    }),
    menu: (base, state) => ({
      ...base,
      width: `${isMobile ? '100%' : '50%'}`,
      border: 'none !important',
      borderRadius: '8px',
      overflow: 'hidden',
      wordBreak: 'break-word',
      zIndex: 16,
      position: 'absolute',
      left: '0',
      boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.06)',
    }),
    option: (base, state) => {
      return {
        ...base,
        position: 'relative',
        backgroundColor: state.isSelected ? '#FFDBDB' : 'transparent',
        color: '#1A1A1A',

        '&.content-right': {
          color: '#DC143C',
        },
        svg: {
          color: '#DC143C',
          display: state.isSelected ? 'block' : 'none',
          position: 'absolute',
          right: -12,
          alignItems: 'center',
          margin: 'auto',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          marginLeft: '10px',
        },
        fontWeight: state.isSelected ? 500 : 400,
        padding: '12px 24px 12px 16px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        display: 'block',
      };
    },
    placeholder: (base) => ({
      ...base,
      fontWeight: 400,
      fontSize: 14,
      color: '#B1b6bb',
      position: 'absolute',
      padding: '2px 2px 2px 0',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      return {
        ...provided,
        opacity,
        fontWeight: 500,
        fontSize: 14,
        position: 'absolute',
        padding: '2px 2px 2px 0',
        color: '#282727',
      };
    },
    dropdownIndicator: (base) => ({
      ...base,
      display: 'block',
    }),
  };

  return (
    <LayoutCenter
      titlePage={t('Help.contact')}
      notLogin={true}
      isShowLeftIcon={false}
      className={'title-page-creator page-legal'}
      uiMobile={true}>
      {loading && <PopupLoading className={'popup-loading'} />}
      {isOpenModal && (
        <PopupConfirmFan
          text={t('Popup.weWillContactNew')}
          colorSvg='#28c76f'
          className='popup-success-post-help'>
          <div
            className='ok btn'
            onClick={() => {
              if (status) {
                setIsOpenModal(false);
              }
            }}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}
      <div className='container-fluid w-100 bg-white policy-page creator-post-help-contract'>
        <form onSubmit={(e) => e.preventDefault()} className='fansite-form'>
          <div className='title mb-3'>
            <InputWrapper
              id='fsite-account-name'
              label={t('Common.name')}
              required={true}
              error={errors?.name ? errors?.name?.message : ''}>
              <input
                maxLength={30}
                className='fsite-input'
                {...register('name', {
                  required: t('ValidateMsg.C003_VALIDATE_NAME_ROMAJI_REQUIRED'),
                  maxLength: {
                    value: 30,
                    message: 'ValidateMsg.C002_VALIDATE_ACCOUNT_MAXSIZE',
                  },
                })}
                onBlur={(e) => {
                  setValue('name', e.target.value.trim());
                  if (!e.target.value.trim()) {
                    setError('name', {
                      type: 'required',
                      message: 'ValidateMsg.C003_VALIDATE_NAME_ROMAJI_REQUIRED',
                    });
                  }
                }}
                placeholder={t('ValidateMsg.C003_VALIDATE_NAME_ROMAJI_REQUIRED')}
              />
            </InputWrapper>
          </div>
          <InputWrapper
            id='fsite-email'
            label={t('Common.email')}
            required={true}
            error={errors?.email ? errors?.email?.message : ''}>
            <input
              className='fsite-input'
              type='text'
              placeholder={t('Footer.FooterV2.enterYourEmail')}
              maxLength={255}
              {...register('email', {
                required: 'ValidateMsg.emailRequired',
                validate: {
                  fullWidth: (value) => {
                    return (
                      (value && !patternFullWidthJp.test(value.toString().trim())) ||
                      'ValidateMsg.TEXT_HALF_WIDTH'
                    );
                  },
                  email: (value) => {
                    return (
                      (value && patternEmail.test(value.toString().trim())) ||
                      'ValidateMsg.EMAIL_FORMAT_INVALID'
                    );
                  },
                },
              })}
            />
          </InputWrapper>
          <div className='title mb-3'>
            <InputWrapper
              id='fsite-nation'
              label={t('Creator.Post.category')}
              required={true}
              error={errors?.category_content ? errors?.category_content?.message : ''}>
              <div className='category'>
                <Controller
                  name='category_content'
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      value={[...optionListContent]?.find((e) => e?.id === watchCategory?.id)}
                      options={listCategoryFeedsBack?.length > 0 ? [...optionListContent] : []}
                      onFocus={() => setFocusCategory(true)}
                      onBlur={() => {
                        setFocusCategory(false);
                        if (!field.value?.value) {
                          setError('category_content', {
                            type: 'required',
                            message: t('ValidateMsg.categoryRequired'),
                          });
                        } else {
                          clearErrors('category_content');
                        }
                      }}
                      onChange={(val) => {
                        field.onChange(val);
                        setFocusCategory(false);
                        if (val?.value) {
                          clearErrors('category_content');
                        } else {
                          setError('category_content', {
                            type: 'required',
                            message: t('ValidateMsg.categoryRequired'),
                          });
                        }
                      }}
                      noOptionsMessage={() => {
                        t('Common.listNull');
                      }}
                      placeholder={t('Creator.Post.selectCategory')}
                      isSearchable={isSearchable}
                      styles={customStylesSelect}
                      components={{
                        Option: CustomOption,
                        DropdownIndicator: () => <ArrowLeftIcon />,
                      }}
                    />
                  )}
                />
                {/* <ArrowLeftIcon className={`${focusCategory ? 'focus' : 'no-focus'}`} /> */}
              </div>
            </InputWrapper>
          </div>
          <div className='title mb-4'>
            <InputWrapper
              id='fsite-email'
              label={t('Help.inquiry')}
              required={true}
              error={errors?.question_content ? errors?.question_content?.message : ''}>
              <textarea
                {...register('question_content', {
                  required: t('ValidateMsg.inquiryRequired'),
                })}
                placeholder={t('Help.form.placholderQuestion')}
                className='question-content fsite-input'
              />
            </InputWrapper>
          </div>
          <div style={{ marginBottom: "15px"}}>
            <ReCAPTCHA
              key={key}
              sitekey={getEnv('REACT_APP_KEY_CAPTCHA')}
              onChange={handleVerify}
              size="normal"
              hl={i18n.language === 'jp' ? 'ja' : 'en'}
            />
          </div>
          <div className={`button-submit`}>
            <button
              className='button-red-new'
              type='submit'
              onClick={handleSubmit(onSubmit)}
              disabled={
                !isVerified ||
                !watchName?.trim() ||
                !watchCategory ||
                !watchText?.trim() ||
                !watchEmail ||
                !isEmptyObject(errors)
              }>
              {t('Common.send')}
            </button>
            <button className='cancel' onClick={() => navigate(`${newPathUser}help`)}>
              {t('Common.buttonCancel')}
            </button>
          </div>
        </form>
      </div>
    </LayoutCenter>
  );
};

export default withCreatorRole(PostContractCreator);

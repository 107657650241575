import useUserRole from 'hooks/useUserRole';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getUserInfoMe } from 'store/actions/users';
import { isAdmin, isAgency, setOverflowTablet } from 'utils/utils';
import useRedirect from 'hooks/useRedirect';
import AccountSidebar from 'components/Account/AccountSidebar';
import MenuSidebarCustom from 'components/MenuSidebarCustom';
import './Layout.scss';
import SidebarRecommendPost from 'components/SidebarRecommendPost';
import { HeaderListIdolV2, ImgCustom, PopupBlockUser, PopupLoading } from 'components';
import { settingMinimalMenu } from 'store/actions/myPage';
import { useTranslation } from 'react-i18next';
import { newPathUser } from 'constants/layout/constant';
import SideBarTopList from 'components/SidebarTopList';
import { useMemo } from 'react';
import { STORAGE } from 'utils';
import Storage from 'utils/storage';
import PopupBlockUserNoLogin from 'components/Popup/blockUserNoLogin';
import { isIOS, isMobile, isTablet } from 'react-device-detect';
import SidebarActionPost from 'pages/creator/layout/adminManagementPost';
import ImgRTA from 'images/RTA.png';

const NewFansiteLayout = ({ children }) => {
  const location = useLocation();
  const [isTableScreen, setIsTabletScreen] = useState(false);
  const widthScreen = window.innerWidth;
  const user = useSelector((state) => state.users.dataUser);
  const {
    error: err,
    detailPost,
    isMinimal,
    isLogUser,
  } = useSelector((state) => state.myPage);
  const { logoutProcess } = useSelector((state) => state.users);
  const { isIdol } = useUserRole();
  const error = useSelector((state) => state.handleError.error);
  const [hideFooter, setHideFooter] = useState(false);
  const [showUi, setShowUi] = useState(false);
  useRedirect(error);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const checkFanView = () => {
    if (location.pathname.includes(`${newPathUser}`)) {
      return true;
    } else {
      return false;
    }
  };
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  useEffect(() => {
    if (isAuth) {
      dispatch(getUserInfoMe());
    }
  }, []);
  // }, [dispatch, isAuth, location?.pathname]);

  const detectParams = location.pathname.split('/')[2];
  const detectParams3 = location.pathname.split('/')[3];

  useEffect(() => {
    if (isLogUser) {
      document.getElementById('root').style.overflow = 'hidden';
      document.getElementById('root').style.position = 'fixed';
      document.getElementById('root').style.width = '100%';
    } else {
      setOverflowTablet();
    }
  }, [isLogUser]);

  useEffect(() => {
    setOverflowTablet();
  }, []);

  useEffect(() => {
    if (widthScreen > 990 && widthScreen <= 1439) {
      setIsTabletScreen(true);
    }
    if (
      (detectParams === 'live' &&
        detectParams3 !== 'post' &&
        detectParams3 !== 'create' &&
        detectParams3 !== 'review' &&
        detectParams3 !== 'list-livestream') ||
      detectParams3 === 'plan-list' ||
      !isIdol ||
      location.pathname === `${newPathUser}search` ||
      location.pathname === `${newPathUser}timeline`
    ) {
      setHideFooter(true);
    } else {
      setHideFooter(false);
    }
  }, [location.pathname, widthScreen, setHideFooter]);

  const checkRedirectPageNotFound =
    // location.pathname.includes(`${newPathUser}post/`) ||
    // location.pathname.includes(`${newPathUser}post-content/`) ||
    // location.pathname.includes(`${newPathUser}post-content-mode-2/`) ||
    location.pathname.includes(`${newPathUser}post-short`) ||
    location.pathname.includes(`${newPathUser}post/update/`);
  useEffect(() => {
    if (checkRedirectPageNotFound && !location.pathname.includes('create')) {
      if (err !== null) {
        err?.messageId ===
        ('NOT_FOUND' || 'THE_SPECIFIED_URL_WAS_NOT_FOUND' || 'UNPUBLISHED_POST')
          ? setShowUi(false)
          : setShowUi(true);
        if (detailPost) {
          if (
            location.pathname.includes(`${newPathUser}post/update/`) ||
            location.pathname.includes(`${newPathUser}post-content-mode-2/`)
          ) {
            detailPost?.is_my_post === 1 ? setShowUi(true) : navigate('/*');
          }
          if (location.pathname.includes(`${newPathUser}post/`)) {
            detailPost?.type === 1 || detailPost?.type === 3 || detailPost?.type === 4 || detailPost?.type === 2
              ? setShowUi(true)
              : navigate('/*');
          } else if (location.pathname.includes(`${newPathUser}post-content/`)) {
            detailPost?.type === 2 ? setShowUi(true) : navigate('/*');
          } else if (location.pathname.includes(`${newPathUser}post-short/`)) {
            detailPost?.type === 3 || detailPost?.type === 4
              ? setShowUi(true)
              : navigate('/*');
          }
        }
      }
    } else {
      setShowUi(true);
    }
  }, [checkRedirectPageNotFound, err, err?.messageId, location.pathname, detailPost]);

  const [customWidthLayout, setCustomWidthLayout] = useState(false);
  const layoutRightRef = useRef();
  useEffect(() => {
    location?.pathname?.includes('creator/live/list-livestream') && setCustomWidthLayout(true);
  }, [location.pathname]);

  const handleMinimal = () => {
    dispatch(settingMinimalMenu());
  };

  const listRouteOtherLive = ['create', 'list-livestream', 'review'];
  const isLayoutLiveStreams = useMemo(() => {
    const isHave = listRouteOtherLive.find((e) => location.pathname.includes(e));
    return location.pathname.includes(`${newPathUser}live/`) && !isHave;
  }, [location.pathname]);

  const isRoutePostNormal = location.pathname.includes(`${newPathUser}post/`);
  const isRoutePostSale = location.pathname.includes(`${newPathUser}post-sale/`);

  return (
    <>
      {isLogUser && <PopupBlockUserNoLogin />}
      {[2].includes(user?.status) && <PopupBlockUser />}
      <div className='hide-less-than-1024'>
        <HeaderListIdolV2 />
      </div>
      {logoutProcess && <PopupLoading />}
      {!location.pathname.includes(`${newPathUser}short-video-detail/`) &&
      !isLayoutLiveStreams ? (
        <div
          className={`layout-with-menu-sidebar justify-content-center flex-nowrap creator ${
            isIdol && 'layout-desktop-position'
          } ${customWidthLayout && 'layout-list-livestream'}`}
          style={{ visibility: showUi ? 'visible' : 'hidden' }}>
          {isIdol && (
            <>
              <div
                className={`hide-less-than-1024 layoutSider-wrapper d-sm-block ${isMinimal ? 'is_minimal' : ''}`}
                style={{ width: `${isMinimal ? '5%' : ''}` }}>
                <div
                  className={`layoutSider layoutSider_affiliator ${isIdol && 'creator'} ${
                    isMinimal && 'is_minimal'
                  }`}>
                  {/* <span onClick={handleMinimal}>
                  {isMinimal ? (
                    <IconArrowRight className='btn-minimal' />
                  ) : (
                    <IconArrowLeft className='btn-minimal' />
                  )}
                </span> */}
                  <div style={{ padding: '16px 16px 0px 16px' }}>
                    <AccountSidebar isAdmin={isAdmin} isAgency={isAgency} user={user} />
                  </div>
                  <MenuSidebarCustom />
                </div>
                {!isMinimal && (
                  <>
                    <div className='list-link 1'>
                      <Link to='/terms-of-use' target='_blank' className='link_route'>
                        {t('Common.terms')}
                      </Link>
                      <Link to='/privacy-policy' target='_blank' className='link_route'>
                        {t('Common.privacyPolicy')}
                      </Link>
                      {/* <Link className='link_route' to='/usc2257' target='_blank'>
                        {t('Footer.FooterV2.usc2257')}
                      </Link> */}
                      <Link to='#' className='link_route' onClick={(e) => e.preventDefault()}>
                        {t('Common.cookiePolicy')}
                      </Link>
                      <Link to='#' className='link_route' onClick={(e) => e.preventDefault()}>
                        {t('Common.accessibility')}
                      </Link>
                      <Link to={`${!isAuth ? '#' : '/help'}`} className='link_route'>
                        {t('Common.help')}
                      </Link>
                      <Link to='/usc2257' target='_blank' className='link_route'>
                        {t('Footer.FooterV2.usc2257')}
                      </Link>
                    </div>
                    <div className='list-link 1'>
                      <Link to='/marketing-policy' target='_blank' className='link_route'>
                        {t('Footer.FooterV2.marketting')}
                      </Link>
                    </div>
                    <div className='right-copy'>{t('Common.rightCopy')}</div>
                    <img
                      style={{ cursor: 'pointer' }}
                      onClick={() => window.open('https://www.rtalabel.org', '_blank')}
                      width='88px'
                      height='31px'
                      src={ImgRTA}
                      alt=''
                    />
                  </>
                )}
              </div>
            </>
          )}
          <div
            style={{
              height: 'calc(100vh - 60px)',
              width: `${!isMinimal ? '302px' : '100px'}`,
            }}></div>
          <div
            id='layout-centent-creator'
            className={`layout-content layout-content-creator flex-column layout-desktop ${
              !hideFooter ? 'has-footer' : ''
            }`}
            style={{ width: `${isMinimal ? '67%' : ''}` }}>
            {children}
          </div>
          {!isLayoutLiveStreams && (
            <div className='hide-less-than-1024 recommend-block' ref={layoutRightRef}>
              {!isAdmin && !isAgency && (
                <div>
                  {!location.pathname.includes(`${newPathUser}lives/list/live`) ? (
                    <SidebarRecommendPost
                      style={{
                        width: !isTableScreen
                          ? `${layoutRightRef?.current?.offsetWidth}px`
                          : '22vw',
                        position: 'fixed',
                        height: 'calc(100vh - 60px - 40px)',
                      }}
                    />
                  ) : (
                    <SideBarTopList
                      style={{
                        width: !isTableScreen
                          ? `${layoutRightRef?.current?.offsetWidth}px`
                          : '22vw',
                        position: 'fixed',
                        height: 'calc(100vh - 60px - 40px)',
                      }}
                    />
                  )}
                </div>
              )}

              {(isAdmin || isAgency) && (isRoutePostNormal || isRoutePostSale) && <SidebarActionPost />}
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '0',
            position: 'relative',
            // padding: '23px 30px',
            width: '100%',
            height: 'calc(100% - 90px)',
          }}>
          <div
            id='layout-centent-creator'
            className={`layout-content layout-content-creator flex-column layout-desktop ${
              !hideFooter ? 'has-footer' : ''
            }`}
            style={{ width: `100%`, maxWidth: '1440px', margin: '0 auto' }}>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default NewFansiteLayout;
